import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { useLanguage } from '../Helpers/LanguageContext'

function HowToSave() {
    const { languageData } = useLanguage()
    return (
        <body class=" lang-en niche-women" data-is-mobile="False">
            <div class="main-wrapper">
                <Header />
                <div class="body-content">
                    <div class="container mt-10">
                        <div>
                            <h1>{languageData?.HowToMAinTitle}</h1>
                            <p>&nbsp;</p>
                            <h2>{languageData?.HowToTitle1}</h2>
                            <p>{languageData?.HowToSubTitle1}<a href="review/imlive">ImLive</a>{languageData?.HowToSubTitle1Span}</p>
                            <p>&nbsp;</p>
                            <h2>{languageData?.HowToTitle2}</h2>
                            <p>{languageData?.HowToSubTitle2}</p>
                            <p>&nbsp;</p>
                            <h2>{languageData?.HowToTitle3}</h2>
                            <p>{languageData?.HowToSubTitle3}</p>
                            <p>&nbsp;</p>
                            <h2>{languageData?.HowToTitle4}</h2>
                            <p>{languageData?.HowToSubTitle4}</p>
                            <p>&nbsp;</p>
                            <h2>{languageData?.HowToTitle5}</h2>
                            <p>{languageData?.HowToSubTitle5}</p>
                            <p>&nbsp;</p>
                            <h2>{languageData?.HowToTitle6}</h2>
                            <p>{languageData?.HowToSubTitle6}</p>
                            <p>&nbsp;</p>
                            <h2>{languageData?.HowToTitle7}</h2>
                            <p>{languageData?.HowToSubTitle7}<a href="review/cams">Cams.com</a>{languageData?.HowToSubTitle7Span}</p>
                            <p>&nbsp;</p>
                            <h2>{languageData?.HowToTitle8}</h2>
                            <p>{languageData?.HowToSubTitle8}<a href="review/imlive">ImLive.com</a>{languageData?.HowToSubTitle8Span}</p>
                            <p>&nbsp;</p>
                            <h2>{languageData?.HowToTitle9}</h2>
                            <p>{languageData?.HowToSubTitle9}</p>
                            <p>&nbsp;</p>
                            <h2>{languageData?.HowToTitle10}</h2>
                            <p>{languageData?.HowToSubTitle10}</p>
                            <p>&nbsp;</p>
                            <h2>{languageData?.HowToTitle11}</h2>
                            <p>{languageData?.HowToSubTitle11}</p>
                            <p>&nbsp;</p>

                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </body>
    )
}

export default HowToSave
