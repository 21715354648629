import React, { useEffect, useState } from 'react'
import logoMain from '../images/logo.png'
import { Link } from 'react-router-dom'
import { useNavigate, useLocation } from 'react-router-dom'
import cn from 'classnames'
import { useLanguage } from '../Helpers/LanguageContext'
import mixpanel from 'mixpanel-browser'
import '../styles/Main2.css'

function Header() {

    const navigate = useNavigate()
    const { languageData } = useLanguage()
    console.log(languageData)
    const [subMenu, setSubMenu] = useState(false)
    const [menu, setMenu] = useState(false)

    const location = useLocation()

    useEffect(() => {
        console.log(location.pathname)

        mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN)

        mixpanel.track('Page View', {
            distinct_id: window.localStorage.getItem('userID'),
            'Page Path': location.pathname,
            'Page URL': window.location.href
        })


    }, [location])

    return (
        <div className="header-content">

            <div id="header">
                <div className="header-panel">
                    <div onClick={e => setMenu(!menu)} id="menu-button" >

                    </div>

                    <div className="header-logo">
                        <Link to="/">
                            <img src={logoMain} className="logo" alt="LiveCamDeals" />
                        </Link>
                    </div>
                    <div id="menu" style={{ display: menu === true ? 'block' : 'none' }}>
                        <ul>
                            <li className="about-menu dd-menu real-dd-menu" id="about-dd-menu" title="About Webcams" onClick={e => setSubMenu(!subMenu)}>
                                {languageData.aboutWebcams}
                                <ul className="sub-menu" id="about-sub-menu" style={{ display: subMenu ? 'block' : 'none' }}>
                                    <Link to="/prices-payment-methods" >
                                        <li onClick={e => navigate('/prices-payment-methods')} className="about-item" data-about="prices-payment-methods"
                                            title="Prices/payment methods">{languageData.pricesAndPaymentMethods}</li>
                                    </Link>
                                    <Link to="/how-to-save" >
                                        <li onClick={e => navigate('/how-to-save')} className="about-item" data-about="how-to-save" title="How to save">{languageData.howToSave}</li>
                                    </Link>
                                    <Link to="/faq" >
                                        <li onClick={e => navigate('/faq')} className="about-item" data-about="faq" title="FAQ">{languageData.faq}</li>
                                    </Link>
                                    <Link to="/safe-and-security" >
                                        <li onClick={e => navigate('/safe-and-security')} className="about-item" data-about="safe-and-security" title="Safe and security">
                                            {languageData.safetyAndSecurityTips}</li>
                                    </Link>
                                    <Link to="/general-adult-cam-features" >
                                        <li onClick={e => navigate('/general-adult-cam-features')} className="about-item" data-about="general-adult-cam-features"
                                            title="General adult cam features">{languageData.adultCamFeatures}</li>
                                    </Link>
                                </ul>
                            </li>

                            <li className={cn(`red-item li-mike dd-menu`, {
                                active: location.pathname === '/'
                            })}><a href="/"
                                title="Home">{languageData.home}</a></li>
                            <li className={cn(`red-item li-mike dd-menu`, {
                                active: location.pathname === '/about-us'
                            })}><a href="/about-us"
                                title="Mike Says">{languageData.MikeSays}</a></li>

                        </ul>
                    </div>
                </div>


            </div>

        </div>
    )
}

export default Header
