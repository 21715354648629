import React, { useEffect, useRef, useState } from 'react';
import { sendImLiveEmail, sendAllEmail } from '../../Requests/API';
import { useLanguage } from '../../Helpers/LanguageContext';

function PromoModal({ open, setOpen, selectedSite }) {
    const { languageData } = useLanguage();
    const ref = useRef();

    const [email, setEmail] = useState('');
    const [isSended, setIsSended] = useState(false);

    const handleClickOutside = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleEnterEmail = async (e) => {
        e.preventDefault();
        let hostAdress = window.location.host;
        let protocol = window.location.protocol;
        let host = `${protocol}//${hostAdress}`;
        window.localStorage.setItem('email', email);

        const response = selectedSite === 'imlive' ? await sendImLiveEmail(email, window.localStorage.getItem('userID'), host) : await sendAllEmail(email, window.localStorage.getItem('userID'), selectedSite, host)
        console.log(response)
        if (response.status !== 200) {
            alert('Error sending email');
        } else {
            setIsSended(true);
        }
    }


    return (
        <div className='fixed left-0 top-0 w-screen h-screen bg-black/50 z-10 flex justify-center items-center'>
            <div ref={ref} className='max-w-[400px] w-[90%] p-6 bg-[#25294A] rounded-[24px]'>
                {isSended ? (
                    <div>
                        <p className='text-[22px] text-white text-center uppercase font-bold'>{languageData.checkEmailTitleModal}</p>
                        <p className='text-white text-center'>{languageData.checkEmailMessageModal}</p>
                        <button onClick={() => {
                            window.open('https://mail.google.com/mail/u/0/#inbox', '_blank');
                        }} className='d-inline-block bold underline mr-2 visitSite orange-background-gradient w-full'>
                            {languageData.openGmail}
                        </button>
                    </div>
                ) : (
                    <form onSubmit={e => handleEnterEmail(e)}>
                        <p className='text-[22px] text-white text-center uppercase font-bold'>{languageData.enterEmailTitleModal.replace('[site]', selectedSite)}</p>
                        <input
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            className='w-full p-2 px-4 rounded-full my-4 h-[50px]'
                            type="email"
                            name="email"
                            id="email"
                            placeholder={languageData.emailPlaceholderModal}
                        />
                        <button type='submit' className='d-inline-block bold underline mr-2 visitSite orange-background-gradient w-full'>
                            {languageData.sendLinkButtonTextModal}
                        </button>
                    </form>
                )}
            </div>
        </div>
    );
}

export default PromoModal;
