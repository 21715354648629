import React, { useEffect } from 'react'
import mixpanel from 'mixpanel-browser';

function Redirect() {

    useEffect(() => {
        let params = new URLSearchParams(window.location.search);
        let redirect_url = params.get('redirect_url');

        mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
        mixpanel.track('click_email_verification', {
            distinct_id: window.localStorage.getItem('userID'),
            email: window.localStorage.getItem('email'),
            redirect_url: redirect_url,
        })
        window.location.replace(redirect_url);
    }, []);
    return (
        <></>
    )
}

export default Redirect