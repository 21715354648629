import React, {useEffect} from 'react'
import redirectingImg from '../images/redirecting.gif'

function RedirectingModal({  logoRedirect, link, setRedirecting  }) {
    const [redirecting] = React.useState(true)

    useEffect(() => {
        if (redirecting) {
            const time = setTimeout(() => {
                handleSendUser();
            }, 3000);

            return () => clearTimeout(time);
        }
    }, [redirecting]);

    const handleSendUser = () => {
        window.location.replace(link);
    };
    return (
        <div className="redirect-pop">
            <div className="redirect-pop-inner">
                <div className="close" onClick={e => setRedirecting(false)}></div>
                <img className="animated-gif" src={redirectingImg} />
                <div className="pop-up-logo">
                    <img className="poplogo" src={logoRedirect} />
                </div>
            </div>
        </div>
    )
}

export default RedirectingModal
