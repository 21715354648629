import React, { useEffect } from 'react'
import styles from '../../styles/Review.js'
import Header from '../../components/Header.jsx'
import Footer from '../../components/Footer.jsx'
import thumbsUp from '../../images/thumbs-up.png'
import thumbsDown from '../../images/thumbs-down.png'
import logo15 from "../../images/logos/225LiveCamGuru.png"
import RecommendedSites from '../../components/RecommendedSites.jsx'
import RedirectingModal from '../../components/RedirectingModal.jsx'
import ReviewSpoiler from '../../components/ReviewSpoiler.jsx'
import { useLanguage } from '../../Helpers/LanguageContext.jsx'

function LiveCamGuru() {
     const [redirecting, setRedirecting] = React.useState(true)
    const link = `https://www.livecamguru.com/?track=RONMIKE`
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const { languageData } = useLanguage()
    return (
        <body className="lang-en niche-women" data-is-mobile="False">
            <div className="main-wrapper">
                <Header />
                <div class="body-content">
                    {
                        redirecting === true && <RedirectingModal logoRedirect={logo15} link={link} setRedirecting={setRedirecting} />
                    }
                    <div class="container mt-10">
                        <div class="review-main-sticky">

                            <div class="review-main">
                                <div>
                                    <a class="" href={link}
                                        target="_blank" rel="noopener" title="Hourly top cam money wins">
                                        <img class="siteimg" src={logo15}
                                            alt="LiveCamGuru.com" title="LiveCamGuru.com" />
                                    </a>
                                </div>
                                <div class="score-div not-scored" data-site-id="29">
                                    <span class="mikes-score star-icon-3">3.0</span>

                                </div>
                                <div>
                                    <a onClick={e => setRedirecting(true)} style={{cursor: "pointer"}} class=" d-inline-block text-center  no-underline visitSite orange-background-gradient"
                                         target="_blank"
                                        rel="noopener" title="Hourly top cam money wins">
                                        <span>Hourly top cam money wins</span>
                                        <span>visit site</span>
                                    </a>

                                </div>
                            </div>

                        </div>

                        <div class="review-body-and-banner">
                            <RecommendedSites />


                            <div class="review-body">
                                <section id="pros_cons" className='pros-and-cons'>
                                    <div className="pros">
                                        <h1>Pros</h1>
                                        <li>Very easy to use, good UX concept and design.</li>
                                        <li>Customer support is easily reached by email, live chat, phone, or WhatsApp too.</li>
                                        <li>The site offers lots of free public live shows.</li>
                                        <img className='thumbs' src={thumbsUp} alt="thumbs" />
                                    </div>
                                    <div className="cons">
                                        <h1>{languageData.consTitle}</h1>
                                        <li>A limited number of features and extras.</li>
                                        <li>A limited number of main categories: only girls and guys.</li>
                                        <li>The stream quality fluctuates between HD and pixelated. Both audio and video can lag sometimes.</li>
                                        <img className='thumbs' src={thumbsDown} alt="thumbs" />
                                    </div>
                                </section>
                                <ReviewSpoiler title={'Number of Web Cam Hosts'}>
                                    <section id="hosts">

                                        <p>On LiveCamGuru.com you can find at least 200 chat rooms available online, even if you log
                                            on at not-so-busy times. Peak hours will feature even 300 or more hosts, which is a nice
                                            number to select from. The quality of shows here varies quite a lot, but there are
                                            enough live webcams to ensure that you will find the right one.</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Cost'}>
                                    <section id="cost">


                                        <p>All public shows are free and you can watch for as long as you like even before you sign
                                            up. Tipping is of course advised. You can sit back and hope that other users will tip,
                                            but then you will have no saying whatsoever in what will happen on the show. There are
                                            also private chats that obviously cost a bit, but I they start at 6 tokens per minute
                                            and can go up to 90. The site also allows users to earn tokens by broadcasting their own
                                            web camera feeds.</p>
                                        <p><strong>Credits on LiveCamGuru.com</strong></p>
                                        <p>As mentioned above, LiveCamGuru is totally free, but if you're in the mood for some
                                            private chatting, you will need to get some credits which are called here Tokens. The
                                            following packages are currently on offer (notice that the bigger packages are extremely
                                            worth purchasing on this site):</p>
                                        <ul>
                                            <li>100 tokens for $10.99 USD</li>
                                            <li>200 tokens for $20.99 USD (5% Bonus)</li>
                                            <li>400 tokens for $39.99 USD (10% Bonus)</li>
                                            <li>550 tokens for $49.99 USD (21% Bonus)</li>
                                            <li>750 tokens for $62.99 USD (32% Bonus)</li>
                                            <li>1000 tokens for $79.99 USD (37% Bonus)</li>
                                            <li>1255 tokens for $99.99 USD (38% Bonus)</li>
                                            <li>2025 tokens for $159.99 USD (39% Bonus)</li>
                                            <li>4050 tokens for $319.98 USD (39% Bonus)</li>
                                        </ul>

                                        <p><strong>Payment Methods</strong></p>
                                        <p>The following payment methods are accepted on LiveCamGuru.com:</p>
                                        <ul>
                                            <li>Credit/Debit Card (Visa/Mastercard/Discover)</li>
                                            <li>Paysafecard</li>
                                            <li>Wire Transfer</li>
                                            <li>Cryptocurrency</li>
                                            <li>You can bring (refer) a Friend and earn tokens</li>
                                        </ul>

                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Top Promotions'}>

                                    <section id="promotions">


                                        <p>Earn tokens by broadcasting:</p>
                                        <ul>
                                            <li>Get lots of tokens for every friend you bring to the site (even more if they
                                                broadcast)</li>
                                            <li>Totally free chats with no limitations</li>
                                        </ul>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Types of Memberships and Benefits'}>
                                    <section id="member_info">


                                        <p>As a visitor AKA unregistered guest, you can participate in all of the public chats on
                                            LiveCamGuru.com but you won't be able to tip and a s a result, you won’t get much
                                            attention. If you do choose to join the site there are 2 types of memberships and I will
                                            list their details below.</p>
                                        <p><strong>Standard Membership</strong></p>
                                        <p>This free membership is actually pretty awesome and I think that for most of my reade4rs
                                            it will be quite sufficient with no need for an upgrade.</p>
                                        <strong>Cost to Join:</strong> Free.<br />
                                        <p><strong>Information Required: </strong> User name, email, Password.<br /></p>
                                        <p><strong>Benefits: </strong></p>
                                        <ul>
                                            <li>Unlimited Access to free public shows</li>
                                            <li>Text chat</li>
                                            <li>Browse members’ galleries when available</li>
                                            <li>Use the basic videochat features</li>
                                            <li>Broadcast your own feed, if you want, and get paid</li>
                                            <li>Use the member message boards</li>
                                        </ul>
                                        <p><strong>Supporting member</strong></p>
                                        <p>Yes, I know, it's a bit of a unusual name for an upgraded membership, but LiveCamGuru
                                            apparently doesn’t want to imply that it has any paid membership so it calls this option
                                            "becoming a supporter". Nevertheless, an upgrade is exactly what it is and it buys you
                                            access to advanced features and benefits.</p>
                                        <p><strong>Cost to join:</strong> $19.95 per month</p>
                                        <p><strong>Information required:</strong> CC number, but only if you&#39;ve never purchased
                                            tokens before.</p>

                                        <p><strong>Benefits:</strong></p>
                                        <p>All those given to standard members PLUS:</p>
                                        <ul>
                                            <li>Receive 200 tokens for FREE ($20.99 value)</li>
                                            <li>Remove all ads</li>
                                            <li>Watch shows on full screen</li>
                                            <li>Send private messages</li>
                                            <li>Customizable chat interface</li>
                                            <li>Anonymous tipping </li>
                                        </ul>

                                        <p><strong>Fan Club</strong></p>
                                        <p>Most of the webcam hosts allow users to join their fan club with include some nice
                                            benefits. As a fan you can send private messages to the host, even if you are not a
                                            supporter, you can access any locked videos and photos and get highlighted in the public
                                            chat room, so everyone knows you're a fan. Cost is between $5.99 and $$20.99 per month
                                            and that's quite a difference. </p>

                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Special Features'}>
                                    <section id="features">

                                        <p>LiveCamGuru.com isn’t big on special features, but you will find all the basic ones ready
                                            to use. Nothing here is out of the ordinary, but nothing is missing either and you can
                                            enjoy Cam2Cam, audio, etc. I will mention a few features that stood out but these are
                                            mostly user features for general enjoyment on the site and not video chat tools.</p>
                                        <p><strong>User Features</strong></p>
                                        <ul>
                                            <li>Message Board – If you like to socialize, feel free to join the conversation</li>
                                        </ul>

                                        <p><strong>VideoChat Features</strong></p>
                                        <ul>
                                            <li>Watch several chat rooms simultaneously</li>
                                            <li>See how many people are in the same live chat room</li>
                                            <li>Find out who else is watching the same show
                                            </li>
                                        </ul>

                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Host Profiles'}>

                                    <section id="profiles">

                                        <p>The profile pages are created by each host, which does give them a nice amateur-like
                                            appeal, but the problem is that too many of them are not just half empty. Very often you
                                            will find no useful information, but they do have links to everything you need such as
                                            the host's fan club, bio and some other basic details, like their schedules plus, this
                                            is where you will find all the content you can unlock for certain amounts or by becoming
                                            a member of their fan club. </p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Navigation'}>
                                    <section id="navigation">

                                        <p>Everything you need on LiveCamGuru.com is either at the top or at the bottom. The top has
                                            the major live webcam categories and the bottom allows you to find hosts in more
                                            specific niches or choose according to price per minute, which is very useful.</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Customer Support'}>
                                    <section id="customer_support">

                                        <p>If you click on Support, it immediately launches your mail client, meaning you can send
                                            them an email. </p>

                                        <h2>FAQ</h2>
                                        <p>You can certainly gather some information when you click on My profile or Get more
                                            tokens, but there is no dedicated FAQ page. </p>

                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Languages'}>

                                    <section id="languages">

                                        <p>LiveCamGuru.com is available in English.</p>
                                    </section>
                                </ReviewSpoiler>


                                <ReviewSpoiler title={'Bottom Line'}>
                                    <section id="bottom-line">

                                        <p>When all is said and done LiveCamGuru is one of the very few webcam chat arenas that let
                                            users watch shows for free and if you don't mind sharing attention, you are in for a
                                            real treat. Even if you do decide to join a private show, the price is on the low side
                                            and all in all I think that for what you pay, this site gives you a lot.</p>


                                    </section>
                                </ReviewSpoiler>


                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
            <style>
                {styles}
            </style>


        </body >
    )
}

export default LiveCamGuru
