import React, { useEffect } from 'react'
import styles from '../../styles/Review.js'
import Header from '../../components/Header.jsx'
import Footer from '../../components/Footer.jsx'
import thumbsUp from '../../images/thumbs-up.png'
import thumbsDown from '../../images/thumbs-down.png'
import logo12 from "../../images/logos/740exposedwebcams.png"
import RecommendedSites from '../../components/RecommendedSites.jsx'
import RedirectingModal from '../../components/RedirectingModal.jsx'
import ReviewSpoiler from '../../components/ReviewSpoiler.jsx'
import { useLanguage } from '../../Helpers/LanguageContext.jsx'

function ExposedWebcams() {
     const [redirecting, setRedirecting] = React.useState(true)
    const link = `https://t.antj.link/3995/235/30375?bo=2779,2778,2777,2776,2775&source=RonMike&po=6533&aff_sub5=SF_006OG000004lmDN`
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const { languageData } = useLanguage()
    return (
        <body className="lang-en niche-women" data-is-mobile="False">
            <div className="main-wrapper">
                <Header />
                <div class="body-content">
                    {
                        redirecting === true && <RedirectingModal link={link} setRedirecting={setRedirecting} logoRedirect={logo12} />
                    }
                    <div class="container mt-10">
                        <div class="review-main-sticky">
                            <div class="review-main">
                                <div>
                                    <a class=""
                                        href={link}
                                        target="_blank" rel="noopener" title="Free chat">
                                        <img class="siteimg" src={logo12}
                                            alt="ExposedWebcams.com" title="ExposedWebcams.com" />
                                    </a>
                                </div>
                                <div class="score-div not-scored" data-site-id="40">
                                    <span class="mikes-score star-icon-4">3.8</span>

                                </div>
                                <div>
                                    <a onClick={e => setRedirecting(true)} style={{cursor: "pointer"}} class=" d-inline-block text-center  no-underline visitSite orange-background-gradient"
                                        
                                        target="_blank" rel="noopener" title="Free chat">
                                        <span>Free chat</span>
                                        <span>visit site</span>
                                    </a>

                                </div>
                            </div>

                        </div>

                        <div class="review-body-and-banner">
                            <RecommendedSites />


                            <div class="review-body">
                                <section id="pros_cons" className='pros-and-cons'>
                                    <div className="pros">
                                        <h1>Pros</h1>
                                        <li>A unique approach to webcam sites, ushering users to sign up as soon as possible with only hints at any future content in the form of 6 cams total.</li>
                                        <li>Risky but can also build on people’s inherent curiosity to sign up only to unlock the ’hidden, secret’ content.</li>
                                        <li>The design is professional, and the site is infomative enough to maintain users’ interest.</li>
                                        <img className='thumbs' src={thumbsUp} alt="thumbs" />
                                    </div>
                                    <div className="cons">
                                        <h1>{languageData.consTitle}</h1>
                                        <li>Can’t access all the cams just by visiting the main page.</li>
                                        <li>Users who are used to seeing hundreds, if not thousands, of models on the main page might not be too enthusiastic about proceeding.</li>
                                        <img className='thumbs' src={thumbsDown} alt="thumbs" />
                                    </div>
                                </section>
                                <ReviewSpoiler title={'Number of Webcam Hosts on ExposedWebcams.com'}>
                                    <section id="hosts">

                                        <p>As of now, there are more than 10,000 performers on Exposedwebcams, making it a force to
                                            be reckoned with. They host hundreds of simultaneous adult live webcam shows as well.
                                        </p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Cost and Billing'}>
                                    <section id="cost">

                                        <p>Exposed web cams offer a reasonable plan when it comes to cost and billing. The cost of
                                            adult live webcams would range somewhere in between 1 and 6 gold – gold is the site
                                            currency and equals $1. This should be subjected to additional promotions if available.
                                            You will need to spend $25 in order to purchase 25 gold from Exposed Cams and take part
                                            in live webcam adult shows. You can pay with Visa, MasterCard, Discover and PayPal.</p>
                                        <p>You can set and upgrade your daily spending limit from $25 to $400. </p>
                                        <p>You need to submit CC data or PayPal information in order to upgrade to a Premium
                                            membership (although no immediate purchase is necessary). </p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Special Features'}>
                                    <section id="promotions">

                                        <p>Starting off with a very unique approach, you arrive at the main page and you are
                                            immediately prompted to sign up. There is little room for exploration at first: you can
                                            access 6 selected host profiles and read extensive information about the different cam
                                            categories, but in the end you are urged to sign up to ’unlock’ the site. Seasoned users
                                            may be more eager to sign up immediately, but for first-time or simply unexperienced
                                            adult cam chat site users it might be a bit discouraging – or maybe fuel their curiosity
                                            even more. Only time will tell!</p>
                                        <p>Once you sign up, you can access free chat areas, you can go to Exclusive chat (just you
                                            and the performer present) and Premium chat (open for other members in spy mode). Gold
                                            shows are their group shows, where multiple members add Gold and participate in the
                                            show. Discounted Block Sessions are also interesting, where by paying upfront, you get a
                                            certain amount of discount for the entire show, namely the following:</p>
                                        <ul>
                                            <li>15 minutes: 5% off</li>
                                            <li>30 minutes: 10% off</li>
                                            <li>45 minutes: 15% off</li>
                                            <li>60 minutes: 20% off</li>
                                        </ul>
                                        <p>You can have Cam2Cam sessions, give gold (tip), get notified when performers come online,
                                            customize and even save your chats with the models. You can become a member of the
                                            models’ fan clubs, which means by sponsoring them, they will provide you extras.</p>
                                        <p>The video chats offer a high quality chat experience to you in any screen size. The
                                            connections are fast and you will not have to experience any lagging. Videos from adult
                                            webcam chat rooms are optimized for desktop and mobile devices as well. </p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Types of Memberships'}>
                                    <section id="member_info">

                                        <p>Exposed Webcams offers Basic and Premium membership several membership types for the
                                            people who are interested in adult webcam girls. If you choose the free (Basic)
                                            membership in Exposed Webcams, you will be able to enjoy unlimited adult webcam chat.
                                            Free adult webcams package also offers messaging and the ability to watch hosts in the
                                            free chat area or add them in your favorites list.</p>
                                        <p>With the Premium membership, you will be able to chat at lower prices and access fully
                                            explicit videos and images.
                                        </p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Navigation and Search Options'}>
                                    <section id="navigation">

                                        <p>Exposed Cams offer plenty of navigation features to you along with a clean interface. You
                                            will be able to browse through the website with minimum hassle. The key search
                                            categories include host videos, live cams, member page and top host arena; and you can
                                            filter the models according to tags, region, willingness, age, features and more.</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Customer Support'}>
                                    <section id="navigation">

                                        <p>The site obviously cares about their members, as you can contact them in live chat, via
                                            email, you can call them and even message them WhatsApp.</p>
                                    </section>
                                </ReviewSpoiler>

                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
            <style>
                {styles}
            </style>


        </body >
    )
}

export default ExposedWebcams
