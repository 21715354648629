import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useLanguage } from '../../Helpers/LanguageContext'

function Cookie() {
    const { languageData } = useLanguage()
    return (
        <div className="lang-en niche-women" data-is-mobile="False">
            <Header />
            <div class="main-wrapper">
                <div className="body-content">
                    <div class="container mt-10">

                        <div>
                            <h1>{languageData.cookiePolicyTitle}</h1>
                            <p>{languageData.revisedOn}</p>
                            <p><strong>{languageData.introduction}</strong></p>
                            <p>{languageData.trackingTechnologiesExplanation}</p>
                            <p>{languageData.thirdPartyCollection}</p>
                            <p><strong>{languageData.whatAreCookies}</strong></p>
                            <p>{languageData.cookiesExplanation} <a href="http://www.allaboutcookies.org">www.allaboutcookies.org</a>.</p>
                            <p>{languageData.storingTrackingTechnologies}</p>
                            <p>{languageData.typesOfTrackingTechnologies}</p>
                            <ul>
                                <li><strong>{languageData.strictlyNecessary}</strong></li>
                                <p>{languageData.strictlyNecessaryExplanation}</p>
                                <li><strong>{languageData.functionally}</strong></li>
                                <p>{languageData.functionallyExplanation}</p>
                                <li><strong>{languageData.performance}</strong></li>
                                <p>{languageData.performanceExplanation}</p>
                                <li><strong>{languageData.marketingAdvertising}</strong></li>
                                <p>{languageData.tailoredOffers}</p>
                                <ul>
                                    <li><strong>{languageData.socialMedia}</strong></li>
                                    <p>{languageData.socialMediaExplanation}</p>
                                </ul>
                                <table width="100%" border="1">
                                    <tbody>
                                        <tr>
                                            <td>{languageData.trackingTechnologiesTable}</td>
                                            <td>{languageData.trackingTechnologiesType}</td>
                                            <td>{languageData.trackingTechnologiesPurpose}</td>
                                        </tr>
                                        <tr>
                                            <td>{languageData.analyticsCookies}</td>
                                            <td>{languageData.firstPartyTracking}</td>
                                            <td>{languageData.performanceTrackingExplanation}</td>
                                        </tr>
                                        <tr>
                                            <td>{languageData.googleAnalytics}</td>
                                            <td>{languageData.firstPartyTracking}</td>
                                            <td>{languageData.googleAnalyticsExplanation}</td>
                                        </tr>
                                        <tr>
                                            <td>{languageData.otherCookies}</td>
                                            <td>{languageData.thirdPartyTracking}</td>
                                            <td>{languageData.strictlyNecessaryExplanation}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </ul>
                            <p><strong>{languageData.manageCookieSettings}</strong></p>
                            <p>{languageData.browserSignalsNote}</p>
                            <ul>
                                <li><strong>{languageData.turnOffCookies}</strong></li>
                                <li>{languageData.browserSettingsNote}</li>
                                <li>{languageData.browserHelpSectionNote}</li>
                            </ul>
                            <p>{languageData.browserLinksNote}</p>
                            <ul>
                                <li>{languageData.chrome}</li>
                                <li>{languageData.chromeMobile}</li>
                                <li>{languageData.internetExplorer}</li>
                                <li>{languageData.mozillaFirefox}</li>
                                <li>{languageData.safari}</li>
                            </ul>
                            <ul>
                                <li><strong>{languageData.turnOffThirdPartyCookies}</strong></li>
                                <p>{languageData.thirdPartyCookieLink}</p>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div >
    );
}

export default Cookie;
