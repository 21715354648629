import React, { useEffect } from 'react'
import styles from '../../styles/Review.js'
import Header from '../../components/Header.jsx'
import Footer from '../../components/Footer.jsx'
import thumbsUp from '../../images/thumbs-up.png'
import thumbsDown from '../../images/thumbs-down.png'
import logo16 from "../../images/logos/stritchat.png"
import RecommendedSites from '../../components/RecommendedSites.jsx'
import RedirectingModal from '../../components/RedirectingModal.jsx'
import ReviewSpoiler from '../../components/ReviewSpoiler.jsx'
import bestModels from '../../images/best-variety-of-models.png'
import { useLanguage } from '../../Helpers/LanguageContext.jsx'

function StripChat() {
     const [redirecting, setRedirecting] = React.useState(true)
    const link = `https://go.rmhfrtnd.com?campaignId=MIKECAMS&action=showTokensGiveawayModalDirectLink&userId=990ece471dd3e6472baaf7b7156d911029ea98c855cdbaa52ae0883495452ee6`

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const { languageData } = useLanguage()
    return (
        <body className="lang-en niche-women" data-is-mobile="False">
            <div className="main-wrapper">
                <Header />
                <div class="body-content">
                    {
                        redirecting === true && <RedirectingModal link={link} setRedirecting={setRedirecting} logoRedirect={logo16} />
                    }
                    <div class="container mt-10">
                        <div class="review-main-sticky">
                            <div class="review-main">
                                <img class="tag" title="Best Variety of Models"
                                    src={bestModels} />
                                <div>
                                    <a class=""
                                        href={link}
                                        target="_blank" rel="noopener" title="Free membership">
                                        <img class="siteimg" src={logo16} alt="XLoveCam.com"
                                            title="XLoveCam.com" />
                                    </a>
                                </div>
                                <div class="score-div not-scored" data-site-id="14">
                                    <span class="mikes-score star-icon-4">4.6</span>

                                </div>
                                <div>
                                    <a onClick={e => setRedirecting(true)} style={{ cursor: "pointer" }} class=" d-inline-block text-center  no-underline visitSite orange-background-gradient"

                                        target="_blank" rel="noopener" title="Free membership">
                                        <span>Free membership</span>
                                        <span>visit site</span>
                                    </a>

                                </div>
                            </div>

                        </div>

                        <div class="review-body-and-banner">
                            <RecommendedSites />


                            <div class="review-body">

                                <section id="pros_cons" className='pros-and-cons'>
                                    <div className="pros">
                                        <h1>Pros</h1>
                                        <li>A modern and user-friendly platform.</li>
                                        <li>The free basic account comes with a chance to win 50 tokens every hour</li>
                                        <li>Huge selection of hosts with lots of filters to choose from</li>
                                        <img className='thumbs' src={thumbsUp} alt="thumbs" />
                                    </div>
                                    <div className="cons">
                                        <h1>{languageData.consTitle}</h1>
                                        <li>Customer support buttons are located inconveniently at the bottom of the page.</li>
                                        <li>No customer loyalty program.</li>
                                        <img className='thumbs' src={thumbsDown} alt="thumbs" />
                                    </div>
                                </section>
                                <ReviewSpoiler title={'Summary'}>
                                    <section id="summary">
                                        <p>The website stripchat.com is so unbelievably easy to use. This should be the free livecam site of choice. The layout is user friendly and the first thing you see are the lovely ladies, ready to entertain you on their cams. If ladies are not to your taste, there are plenty of other hosts who would be happy to let you join them. I am impressed by the range they offer - from men to couples to Trans and under each section, you can further filter out to fit your likes. Hover over the thumbnails of the cameras that takes your fancy and you can watch a short clip of the action.</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Number of Stripchat Hosts'}>
                                    <section id="hosts">
                                        <p>Stripchat does an incredible job at offering its members an abundance of stunning hosts on their site. On a typical weekday you can find over 6000 hosts online, so just imagine what the weekend there might look like. If you want to check out that number for yourself, you can always find it in the top left corner, right next to the Stripchat logo.When you sign up for a free account you will be surprised with a pop-up in which you get to select your preference. This is an impressive feature for a freemium site. It goes to show that Stripchat is willing to go the extra mile for you to have an unforgettable experience.
                                        </p>

                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Cost'}>
                                    <section id="Cost">

                                        <p>The beauty of stripchat.com is that it is free to access. As it is a freemium site, you will find models engaging in explicit activities already on the main page, giving you a taste of what you can expect in the private shows, group shows, ticket shows, and Cam2Cam.When you create a basic account you get access to chat privileges and full-screen shows. You can also then save your favorite models so that you don&rsquo;t have to go looking for them when you feel like enjoying their company.Speaking of which, it is advised you buy tokens for the full experience. You can tip, have private shows with your favorite models, or interact on an even more intimate level with Cam2Cam. Tokens are sold in packages starting at $9.99 for 90 tokens and ranging up to $49.99 for 520 tokens. It is up to you to decide which package suits you best. There is a wide range of payment methods including credit cards. The charge will appear in your statement as EPOCH. If you prefer not to use your card directly, Stripchat also accepts payment via PayPal, Paysafecard, and cryptocurrencies such as Bitcoins and Altcoins.While the site doesn&rsquo;t offer any rewards program, there are many levels of memberships that come with great perks. Overall, what makes Stripchat stand out from other freemium sites is that their paid options that won&rsquo;t set you back a whole load.</p>

                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Top Promotions'}>
                                    <section id="promotions">

                                        <p>There is a permanent mega token giveaway where new members get a chance to win 50 tokens for free! That&rsquo;s a value of $5.55. When new users join they can visit a room and click on the button to participate in the giveaway. 50 tokens are given away every hour, which you won&rsquo;t miss if you pay attention to the countdown that can be found in every chat room.</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Types of Memberships'}>
                                    <section id="memberships">

                                        <p>All visitors have basic access to the cams as anonymous users without the need for an account. Anonymous users can watch as long as they like but are encouraged to join by signing up for the basic free account. A pop-up or chat message will show up which can be dismissed with a simple click if you&rsquo;re not interested. With the free account, you get to be a Grey League user.By purchasing and spending tokens you are automatically upgraded to the Bronze League, then Silver, Gold, Diamond, Royal, Legend, all based on levels and coming with different privileges. There is also the Ultimate Membership with the best benefits, of course.You can subscribe to the Ultimate Membership for $19.99 a month, or $199.99 for a whole year meaning you get two months for free. This comes with many extra privileges and can be canceled at any time, should you change your mind. Ultimate Members also get to enjoy better prices for tokens.</p>

                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Special Features'}>
                                    <section id="features">
                                        <h4>Top models</h4>
                                        <p>Curious to see who the top models are, or what spicy newbies have joined the site recently? Easy. Stripchat has made it really easy to filter through endless categories to make sure that every single visitor is satisfied - no matter their tastes and preferences. Top models can be found in the top tab, while newbies, pornstars, and more can all be found on the left side.Every 3 hours, Stripchat announces their Top 5 performers. The results are decided by counting StripPoints which are given to the performers based on their token earnings and the number of unique paying users. The Top 5 performers will also be rewarded with cash prizes. You can also find the Top Girls of the Month and Top Rated Girls of All Times in the Stripchat Hall of Fame. Browsing through it is a great way to find out who are the ones best at their craft.The level of personalization on Stripochat is top-notch. Users are given recommendations based on their favorite models (once they are &lsquo;hearted&lsquo;) and even their history - so you can be sure that you will never run out of options.If you want some private time with your favorite models, private chat is the way to go. Most models tend to do a lot for a tip and charge just 16 - 24 tokens (around $1.42 - $2.13) a minute but can rise up to 120 depending on the model&rsquo;s request. There are even many who charge just 8 tokens (around $0.71) a minute. The chat can be terminated anytime by either the guest or the model. And while they may be spies present in a private chat, they cannot interact with the host.</p>
                                        <h4>Cam2Cam</h4>
                                        <p>
                                        If you want face-to-face interaction, request a Cam2Cam. The price for this is typically higher, but it depends on the models. Cam2Cam interaction cannot be spied on, so your secret is safe between you and the model.
                                        </p>
                                        <h4>Host Profile</h4>
                                        <p>Each host can tailor their profile, where they can include sexy pictures, a short introduction and include a special message to their fans. You can join each model&rsquo;s Fan Club which grants you unlimited chat and a special badge in the model&rsquo;s room to indicate your status.</p>
                                    </section>
                                </ReviewSpoiler>
                            
                                <ReviewSpoiler title={'Navigation'}>
                                    <section id="Navigation">
<h4>Navigation and Search</h4>
                                        <p>The site is very easy to navigate. The top tab is straightforward, letting you search or choose between categories, while the sidebar on the left-hand side lets you filter through all your preferences. If you are browsing on the main page, you can sort out the thumbnails according to strip score, rating, or activity. At the bottom of the page, you will find links to socials, support, and more. There is a handy and very informative FAQ section if you would like to learn more.</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Customer Support'}>
                                    <section id="support">

                                        <p>If you need support or face any issues, there is a link to Contact Support at the bottom of the page. The site also has links to their social media if you would like to ask for help via these channels.</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Languages'}>
                                    <section id="Languages">

                                        <p>The site can be used in 19 different languages, from English to Portuguese to Turkish. You can switch the language preference at the bottom of the page. The hosts themselves come from a wide range of ethnicity and some of them even have flags on their thumbnails to show where they are from or what languages they speak besides English.</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Bottom Line'}>
                                    <section id="BottomLine">

                                        <p>A modern and user-friendly platform.</p>
                                        <p>The free basic account comes with a chance to win 50 tokens every hour.</p>
                                        <p>Huge selection of hosts with lots of filters to choose from.</p>
                                    </section>
                                </ReviewSpoiler>



                            </div>
                        </div>

                    </div>
                    <Footer />
                </div>
            </div>
            <style>
                {styles}
            </style>


        </body >
    )
}

export default StripChat
