import axios from 'axios';

async function sendImLiveEmail(email, distinct_id, host) {
  const res = await axios.post(process.env.REACT_APP_API_URL + '/api/emails/imlive', { email, distinct_id, host });
  return res;
}

async function sendAllEmail(email, distinct_id, selected_site, host) {
  const res = await axios.post(process.env.REACT_APP_API_URL + '/api/emails/all', { email, distinct_id, selected_site, host });
  return res;
}

export { sendImLiveEmail, sendAllEmail };
