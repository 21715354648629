import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import FAQItem from "../components/FAQItem";
import { useLanguage } from "../Helpers/LanguageContext";

function FAQ() {

  const { languageData } = useLanguage();

  return (
    <body class=" lang-en niche-women" data-is-mobile="False">
      <div class="main-wrapper">
        <Header />
        <div class="body-content">
          <script src="dist/faq.bundle.js?v=1"></script>
          <div class="container mt-10">
            <div>
              <div class="faq-wrapper">
                <h1>{languageData.faqTitle}</h1>
                <p>{languageData.faqIntro}</p>
                <p>{languageData.faqNote}<a href="/contact"> {languageData.faqNoteLink} </a>{languageData.faqNoteEnd}</p>

                <div class="faqs">
                  {languageData.mainFAQ && languageData.mainFAQ.map((faq, index) => (
                    <FAQItem
                      question={faq.question}
                      answer={faq.answer}
                      key={index}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </body>
  );
}

export default FAQ;
