import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import '../styles/Main2.css'
import { useLanguage } from '../Helpers/LanguageContext'

function Payment() {
    const { languageData } = useLanguage()
    return (
        <body className="lang-en niche-women" data-is-mobile="False">
            <div className="main-wrapper">
                <Header />
                <div className="body-content">
                    <div className="container mt-10">

                        <div><h1>{languageData?.PricePaymentTitle}</h1>
                            <p>&nbsp;</p>
                            <h2>{languageData?.PaymentOptionsTitle}</h2>
                            <ul className=' list-disc'>
                                <li>{languageData?.CreditCard}</li>
                                <li>{languageData?.DebitCard}</li>
                                <li>{languageData?.OnlineWallet}</li>
                                <li>{languageData?.ECheque}</li>
                                <li>{languageData?.MoneyTransfer}</li>
                                <li>{languageData?.PayPalSimilar}</li>
                                <li>{languageData?.CashierCheque}</li>
                                <li>{languageData?.PayByPhone}</li>
                            </ul>
                            <p>&nbsp;</p>
                            <h2>{languageData?.BillingInformationTitle}</h2>
                            <p>{languageData?.BillingInformationContent}</p>
                            <p>&nbsp;</p>
                            <h2>{languageData?.SafestPaymentOptionTitle}</h2>
                            <p>{languageData?.SafestPaymentOptionContent}</p>
                            <p>&nbsp;</p>
                            <h2>{languageData?.SafetyFirstTitle}</h2>
                            <p>{languageData?.SafetyFirstContent}</p>
                        </div>

                    </div>

                </div>

                <Footer />

            </div>




        </body>
    )
}

export default Payment
