import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { useLanguage } from '../Helpers/LanguageContext'

function Safety() {
    const { languageData } = useLanguage()
    return (
        <body className="lang-en niche-women" data-is-mobile="False">
            <div className="main-wrapper">
                <Header />
                <div className="body-content">
                    <div className="container mt-10">

                        <div><h1>{languageData?.SafetySecurityTitle}</h1>
                            <h1>&nbsp;</h1>
                            <h2>{languageData?.ReliableProcessorTitle}</h2>
                            <p>{languageData?.ReliableProcessorContent}</p>
                            <p>&nbsp;</p>
                            <h2>{languageData?.ContactWebsiteTitle}</h2>
                            <p>{languageData?.ContactWebsiteContent}</p>
                            <p>&nbsp;</p>
                            <h2>{languageData?.BiggerSitesSaferTitle}</h2>
                            <p>{languageData?.BiggerSitesSaferContent}</p>
                            <p>&nbsp;</p>
                            <h2>{languageData?.SuspiciousNewSitesTitle}</h2>
                            <p>{languageData?.SuspiciousNewSitesContent}</p>
                            <p>&nbsp;</p>
                            <h2>{languageData?.FollowUpPurchaseTitle}</h2>
                            <p>{languageData?.FollowUpPurchaseContent}</p>
                            <p>&nbsp;</p>
                            <h2>{languageData?.NoRecurringChargesTitle}</h2>
                            <p>{languageData?.NoRecurringChargesContent}</p>
                            <p>&nbsp;</p>
                            <h2>{languageData?.OtherPaymentMethodsTitle}</h2>
                            <p>{languageData?.OtherPaymentMethodsContent}</p>
                            <p>&nbsp;</p>
                            <h2>{languageData?.PrivacyBillingTitle}</h2>
                            <p>{languageData?.PrivacyBillingContent}</p>
                            <p>&nbsp;</p>
                            <h2>{languageData?.DontBeNaiveTitle}</h2>
                            <p>{languageData?.DontBeNaiveContent}</p>
                            <p>&nbsp;</p>
                            <h2>{languageData?.LimitedTimeMembershipsTitle}</h2>
                            <p>{languageData?.LimitedTimeMembershipsContent}</p>
                            <p>&nbsp;</p>
                            <h2>{languageData?.GuardPersonalInfoTitle}</h2>
                            <p>{languageData?.GuardPersonalInfoContent}</p>
                            <p>&nbsp;</p>
                            <h2>{languageData?.ReadReviewsTitle}</h2>
                            <p>{languageData?.ReadReviewsContent}</p>
                            <p>&nbsp;</p>
                            <h2>{languageData?.ReadFinePrintTitle}</h2>
                            <p>{languageData?.ReadFinePrintContent}</p>
                            <p>&nbsp;</p>
                        </div>

                    </div>

                </div>

                <Footer />

            </div>

        </body>
    )
}

export default Safety
