import React, { useEffect } from 'react'
import styles from '../../styles/Review.js'
import Header from '../../components/Header.jsx'
import Footer from '../../components/Footer.jsx'
import thumbsUp from '../../images/thumbs-up.png'
import thumbsDown from '../../images/thumbs-down.png'
import logo13 from "../../images/logos/096cam4.png"
import RecommendedSites from '../../components/RecommendedSites.jsx'
import RedirectingModal from '../../components/RedirectingModal.jsx'
import ReviewSpoiler from '../../components/ReviewSpoiler.jsx'
import { useLanguage } from '../../Helpers/LanguageContext'

function Cam4() {
     const [redirecting, setRedirecting] = React.useState(true)
    const link = `https://cam4com.go2cloud.org/aff_c?offer_id=31&aff_id=1226&source=RONMIKE`
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const { languageData } = useLanguage()


    return (
        <body className="lang-en niche-women" data-is-mobile="False">
            <div className="main-wrapper">
                <Header />
                <div class="body-content">
                    {
                        redirecting === true && <RedirectingModal logoRedirect={logo13} link={link} setRedirecting={setRedirecting} />
                    }
                    <div class="container mt-10">
                        <div class="review-main-sticky">

                            <div class="review-main">
                                <div>
                                    <a class=""
                                        href={link}
                                        target="_blank" rel="noopener" title="Free 2 day VIP access">
                                        <img class="siteimg" src={logo13} alt="Cam4.com"
                                            title="Cam4.com" />
                                    </a>
                                </div>
                                <div class="score-div not-scored" data-site-id="3">
                                    <span class="mikes-score star-icon-4">3.8</span>

                                </div>
                                <div>
                                    <a onClick={e => setRedirecting(true)} style={{cursor: "pointer"}} class=" d-inline-block text-center  no-underline visitSite orange-background-gradient"
                                        
                                        target="_blank" rel="noopener" title="Free 2 day VIP access">
                                        <span>Free 2 day VIP access</span>
                                        <span>visit site</span>
                                    </a>

                                </div>
                            </div>

                        </div>

                        <div class="review-body-and-banner">
                            <RecommendedSites />


                            <div class="review-body">
                                <section id="pros_cons" className='pros-and-cons'>
                                    <div className="pros">
                                        <h1>Pros</h1>
                                        <li>Lots of personalized search tags on the site related to your location and language choice.</li>
                                        <li>Sites makes you feel welcomed, which is comforting for newcomers.</li>
                                        <li>A regularly updated blog informs the users about all the promotions and events.</li>
                                        <li>Each performer’s page has a fun Sex Questionnaire that provides more information about them.</li>
                                        <img className='thumbs' src={thumbsUp} alt="thumbs" />
                                    </div>
                                    <div className="cons">
                                        <h1>{languageData.consTitle}</h1>
                                        <li>In private rooms, third-party non-related ads take over the screen, pushing users to sign up for their premium service.</li>
                                        <li>The main page features thumbnails, gif banners and static banners, making it too busy and confusing.</li>
                                        <img className='thumbs' src={thumbsDown} alt="thumbs" />
                                    </div>
                                </section>
                                <ReviewSpoiler title={'Summary'}>
                                    <section id="summary">

                                        <p>CAM4 is a popular adult webcam site with a massive number of models and performers. These
                                            models are professional and cater to every request. CAM4 has won some prestigious awards
                                            too: the best adult site under cam category in the year 2017 from XBIZ, for example.
                                            CAM4 is Ireland based website and is doing a lot better than most of its competitors.
                                        </p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Number of WebCam Hosts'}>
                                    <section id="hosts">

                                        <p>CAM4 started way back in 2007, and since then the number of hosts has been steadily
                                            growing. There are over 500 live hosts available all the time. There are more than 7000
                                            hosts registered on CAM4 and almost more than 500 live cam shows. There are different
                                            types of webcam chats available on CAM4:</p>
                                        <p>Free video chat: Free video chat shows are open to all. Even if you don’t register on
                                            their site, you will still get to enjoy the free video chat option – although without
                                            tipping and gitfing you will not be the most cherished member – so go and invest in some
                                            tokens!</p>
                                        <p>Private chat: Along with free video chat, you will also get an option to go for a private
                                            chat. Most of the private chat rooms will cost you some tokens. </p>
                                        <p>Group Shows: you know the drill – buy your ticket to reserve your seat for the main
                                            event.</p>
                                        <p>Cam2Cam: Switch on your cam and join in the fun!</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Cost'}>
                                    <section id="Cost">

                                        <p>The chat costs on CAM4 varies according to the respective performer. The price will be
                                            selected by the model, and you will get to choose from a pre-set rate. Here’s an
                                            overview of the chat cost:</p>
                                        <ul>
                                            <li>If you opt for a private chat, it will cost you somewhere in between 0 to 31 tokens
                                                per minute. However, on average, you will be charged with 12 tokens a minute.</li>
                                            <li>For group shows, you will get to enjoy the show at a considerable discount. You can
                                                check the length of the show and the token cost beforehand. The price will be 10,
                                                25, 50 and even 100 tokens. Now, these tokens will not be charged per minute, it is
                                                for the entire show, and you will have to pay for it in advance.</li>
                                            <li>You can spy on others’ private shows for 6 to 12 tokens only per minute.</li>
                                            <li>
                                                You can buy different token packages:<br />
                                                <ul>
                                                    <li>1000 Tokens: €159.99 (Save €40.00)</li>
                                                    <li>500 Tokens: €84.95 (Save €15.00)</li>
                                                    <li>250 Tokens: €44.95 (Save €5.00)</li>
                                                    <li>100 Tokens: €18.99</li>
                                                    <li>50 Tokens: €9.95</li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <p><b>Payment methods</b></p>
                                        <p>They accept credit cards, Segpay, iDEAL, Bankcontact, GiroPay, Sofort/Klarna & Dankort,
                                            credit card payments to CuroPayments and PayPal in certain countries, Commdoo and gift
                                            cards.</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Top Promotions on CAM4'}>
                                    <section id="promotions">

                                        <p>The top promotion on CAM4 is their 50 tokens reward for free registration and the first
                                            purchase of any token package. </p>
                                        <p><b>Types of membership and benefits</b></p>
                                        <p>You can opt for a basic account where you don’t have to pay anything. You can simply
                                            visit CAM4 and sign up with your details like a username, a password, and an email
                                            address. </p>
                                        <p>
                                            Or you can upgrade your account to a premium membership, called Gold membership. And
                                            this is a paid membership account but comes with a lot of benefits. You get extra tokens
                                            if you purchase a higher number of tokens. Plus a one-time 100 tokens bonus as well. You
                                            can send a personal message to a performer that you like, give anonymous tips, you will
                                            also be able to hide your username to maintain privacy, switch to full-screen viewing,
                                            you can watch a lot of videos and pictures, and you won’t be bothered by the banner and
                                            video ads either. You will have to pay $9.99 per month to become a gold member.
                                        </p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Special Features'}>
                                    <section id="feature">

                                        <ul>
                                            <li>This site is easy to navigate</li>
                                            <li>CAM4 has an extensive FAQ page, which I highly recommend to visit to know more about
                                                CAM4.</li>
                                            <li>You can easily add a performer to your friend list with just a click. From then on
                                                you will know when she is online and can go ahead and chat with her.</li>
                                            <li>You can also subscribe to your favorite models and performers to receive their
                                                latest updates.</li>
                                            <li>They have a lot of HD and SD live cam shows available, and you can view it in full
                                                screen.</li>
                                            <li>You can send private messages to your favorite performers.</li>
                                            <li>Once registered, you can chat for free with the performers.</li>
                                            <li>Guest viewers can click on an online performer to watch them in the free area (not
                                                entire private chats though), read live chats and get to know about the performer on
                                                her profile page as well.</li>
                                            <li>You can chat with any model privately and bargain with her about her price too.</li>
                                            <li>Group shows are available also at a really affordable token rate. Many people can
                                                join in and share the live cam show to save tokens and money.</li>
                                            <li>You can spy on private shows.</li>
                                            <li>They also have a fully mobile-optimised site, which means you can browse CAM4 on
                                                your smartphone easily.</li>
                                            <li>Gold members can tip models anonymously.</li>
                                            <li>You can read about the model’s profile on her page. Know about her age, ethnicity,
                                                body type, relationship status and more.</li>
                                            <li>You can purchase recorded videos too.</li>
                                            <li>You can tip, gift, spin the wheel (an option to make the performer show you
                                                something extra in exchange for a few tokens) and see the leaderboard (top member
                                                list) on each performer’s page (although some do not have the Spin the wheel
                                                option).
                                            </li>
                                        </ul>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Hosts Profile'}>
                                    <section id="profile">

                                        <p>You can see her live on the site and read the comments left by online users, the highest
                                            tip giver; once you scroll below, you will see her CAM4 profile picture. This page will
                                            provide detailed information about the host. You will know the host’s gender, last
                                            broadcast’s details, ethnicity, orientation, relationship status, age, location,
                                            languages she speaks, height, occupation, whether she drinks or smokes, body type, body
                                            decoration if any, hair colour, hair length, body hair, and her eye colour. You can send
                                            the host a gift, a tip and check out her chat wall too.</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Navigation'}>
                                    <section id="Navigation">

                                        <p>Navigating through CAM4 is easy! The moment I landed on their home page, I could see all
                                            the trending cams available on their site. On the left, you get an option to filter your
                                            options quickly. You can filter by gender, orientation, body type, hair colour,
                                            ethnicity, and region. You can also search for a performer if you know the performer’s
                                            name on their search engine. The CAM4 blog features many industry-related news and
                                            updates, interviews with hosts, CAM4’s awards, and more. </p>
                                        <p>
                                            The interface of CAM4 is quite straightforward. The site is free from bugs and lets you
                                            browse through all the pages easily.
                                        </p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Customer Support'}>
                                    <section id="Support">

                                        <p>If you have a general query, then most of your questions are already answered on CAM4 FAQ
                                            page. You can scroll down the page and click on the FAQ section to get all the answers.
                                            However, if you face a billing issue, you get in touch with the Epoch credit card
                                            support. Here are the details.</p>
                                        <p>
                                            Website: https://epoch.com</p>
                                        <p>
                                            Phone Number: 1-800-893-8871 Int. Tel.: 1-310-664-5810
                                        </p>
                                        <p>
                                            Email: Billing@billingsupport.com
                                        </p>
                                        <p>
                                            Skype: Toll Free: 1-800-893-8871
                                        </p>
                                        <p>
                                            To cancel your Gold membership, you don’t need to call anyone, visit
                                            https://epoch.com/find_purchase?ref=bl.
                                        </p>
                                        <p>
                                            For SegPay:</p>

                                        <p>Website: https://cs.segpay.com/</p>
                                        <p>Phone Number: US and Canadian Residents: 1-888-376-0948 or 1-866-450-4000 Direct Dial
                                            Number: +1-954-414-1610 or +1-514-788-5368</p>
                                        <p>Email: help@segpay.com</p>
                                        <p>Live Chat: https://chat.segpay.com/</p>
                                        <p>For DialXS</p>
                                        <p>Website: https://www.dialxs.nl/cam4-support/</p>
                                        <p>Mobile: +31 020-321.9000</p>
                                        <p>Email: support@dialxs.com</p>
                                        <p>Skype: dialxs</p>
                                        <p>
                                            For queries which were not answered on their FAQ page, you can click on the CAM4 contact
                                            page and leave a message to them, someone from the team will get in touch with you
                                            shortly.
                                        </p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Languages'}>
                                    <section id="Languages">

                                        <p>Since CAM4 is one of the most popular adult cam chat sites, the site is loaded with
                                            language options. However, the most popular languages are German, Spanish, Portuguese,
                                            Italian, and French, other than that, you will also get to see Danish, Dutch, Greek,
                                            Polish, Russian, Thai, Vietnamese, Indonesian, and many more. The list goes on and on
                                            and on. Just scroll down to the footer of the page and you eill find there the dropdown
                                            menu of languages.</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Bottom Line'}>
                                    <section id="BottomLine">

                                        <p>My CAM4 experience was, and I will highly recommend this excellent adult webcam site to
                                            all. If you love talking to beautiful girls, then this platform is simply the best. You
                                            will get to meet an array of gorgeous girls and boys who are willing to chat and
                                            entertain. Try CAM4 and let me know if my review has helped you.</p>
                                    </section>
                                </ReviewSpoiler>


                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
            <style>
                {styles}
            </style>


        </body >
    )
}

export default Cam4
