const styles = `.review-header {
    max-width: 670px;
    border-bottom: 2px solid #ececec;
    padding-bottom: 20px;
    flex-wrap: wrap;
}

.review-body {
    /*max-width: 670px;*/
}

.review-body img {
    max-width: 100%;
    margin-left: 0;
}

.creditBtn{
    text-decoration:none;
}

.review-header > div:nth-of-type(2) {
    margin-left: auto;
}

.rateBtnMobile {
    display: none;
    color: #f34858;
    font-weight: bold;
    text-align: left;
    text-decoration:underline;
}

.flex{
    display:flex;
}

.flex-flow-column {
    flex-flow: column;
}

#disqus_thread {
    max-width: 670px;
}

.review-main {
    height: 136px;
    background: #25294A;
    border-radius: 15px;
    position: relative;
}

.tag {
    top: -5px;
    left: -5px;
}

.mikes-score {
    font-size: 55px;
}

.visitSite {
    width: 100%;
    max-width: 375px;
    padding: 0 30px;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    margin: auto;
}

.review-main > div {
    width: auto;
    flex-shrink: 0;
}

.review-main > div:last-of-type {
    margin-right: 30px;
}

.score-div {
    width: 100% !important;
    flex-shrink: 1 !important;
}

h2 {
    font-weight: bold;
    font-size: 36px;
    line-height: 43px;
    background: #8793d2;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

p {
    font-weight: 300;
    color: #fff;
}

.review-body {
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;
    color: #fff;
    width: calc(100% - 360px);
}

.please-vote {
    text-align: center;
}

.review-body a {
    color: #F26C36;
}

/*li::before {
    content: url(/../content/images/marker.png);
    position: relative;
    top: -3px;
    margin-right: 14px;
}*/

ul {
    list-style-image: url(./images/marker.png);
    padding: 0px;
}

ol {
    list-style: none;
    counter-reset: item;
    padding: 0 0 0 22px;
}

ol>li::before {
    content: counter(li) ". "; 
    color: #F15152;
    display: inline-block; 
    width: 1em;
    margin-left: -1em
}

ol li {
    counter-increment: li
}

section {
    border-bottom: 2px solid #5C6593;
}

section:last-of-type {
    border-bottom: none;
    padding: 0 0 1em 0;
}

.togglable {
    padding: 0 0 1em 0;
}

.toggler {
    cursor: pointer;
    margin: 22px 0;
    position: relative;
    padding: 0 25px 0 0;
}

.review-main-sticky {
    margin-bottom: 115px;
    z-index: 3;
}

.pros-and-cons {
    display: flex;
    flex-wrap: nowrap;
    border: 0;
    margin-bottom: 50px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
}

#pros_cons {
    display: flex;
    justify-content: space-between;
}

.pros-and-cons h1 {
    font-weight: 600;
    font-size: 36px;
    line-height: 23px;
    margin: 21px 0;
    text-align: center;
}
.pros-and-cons li {
    margin: 0 0 10px 0;

}
.pros, .cons {
    width: 50%;
    flex-shrink: 0 ;
    box-sizing: border-box;
    padding: 0px 30px 20px 30px;
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: cover;
    position: relative;
    overflow: hidden;
}

.pros-and-cons p:before {
    content: '';
    width: 5px;
    height: 5px;
    margin-right: 5px;
    vertical-align: middle;
    display: inline-block;
    border-radius: 10px;
    background-color: #fff;
}

.thumbs {
    position: absolute;
    right: 25px;
    bottom: 80px;
}

.pros {
    background-image: url(../images/pros-bg.png), linear-gradient(179.87deg, #319F7C 0.11%, rgba(0, 0, 0, 0) 99.89%);
    border-top-left-radius: 15px;
}

.cons {
    background-image: url(../images/cons-bg.png), linear-gradient(164.87deg, #CF4239 10.64%, rgba(0, 0, 0, 0) 89.36%);
    background-position: right bottom;
    border-top-right-radius: 15px;
}

.visitSite {
    position: relative;
}

.visitSite span:nth-of-type(2) {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    line-height: 65px;
}

.footer-content {
    padding-bottom: 140px;
}

.review-body-and-banner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-flow:row-reverse;
    flex-wrap: nowrap;
}

@media only screen and (min-width: 1001px) {
    .review-body-and-banner.stick-to-bottom {
        display: initial;
    }

    .review-main-sticky.stick-to-bottom {
        position: fixed;
        width: calc(100% - 60px);
        max-width: 1410px;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 0;
        filter: drop-shadow(0px -8px 10px rgba(23, 26, 50, 0.6));
    }

    .right-block.stick-to-bottom {
        top: 30px;
        right: 0;
        position: absolute;
    }

    .stick-to-bottom .sites-recomended {
        position: fixed;
    }
}

#scroll-top {
    bottom: 25px;
}

@media only screen and (max-width: 1650px) {
    #scroll-top {
        bottom: 150px;
    }
}

@media only screen and (max-width: 1000px) {
    .container {
        margin: auto !important;
    }
    #pros_cons {
        display: block;
    }
    .mt-10 {
        padding-top: 70px;
        width: 100%;
    }

    .review-body {
        width: calc(100% - 30px);
        margin: auto;
    }

    h2 {
        font-size: 24px;
        line-height: 30px;
    }

    .review-main-sticky {
        margin: auto auto 45px auto;
    }

    .review-main {
        border-radius: 0;
        display: block;
        height: auto;
    }

    .review-body {
        font-size: 16px;
        line-height: 20px;
    }

    .toggler {
        margin: 12px 0;
    }

        .toggler:after {
            background-size: contain !important;
            width: 16px;
        }

    .review-main > div:nth-of-type(1) {
        border-bottom: 2px solid rgba(121, 132, 190, 0.5);
        padding: 10px 0 16px 0;
        width: calc(100% - 30px);
        margin: auto;
    }

    .review-main > div:nth-of-type(2) {
        display: flex !important;
        max-width: 330px;
        height: 117px;
        margin: auto !important;
    }

    .review-main > div:last-of-type {
        margin: auto;
        background: linear-gradient(180deg, #2D325A 0%, #3B4064 96.72%), #2D325A;
        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
        height: 81px;
        display: flex;
    }

    .stick-to-bottom .review-main > div:last-of-type {
        flex-shrink: 1;
        width: 157px;
        height: auto;
        background: none;
        margin: 0;
    }

    .visitSite {
        max-width: calc(100% - 30px);
        height: 51px;
        font-size: 16px;
        line-height: 20px;
    }

    .mikes-score {
        font-size: 44.321px;
        line-height: 53px;
        background-size: 20px;
    }

    .pros-and-cons {
        display: block;
        margin-bottom: 33px;
    }

    .pros, .cons {
        width: 100%;
        border-radius: 15px;
        padding: 0 15px 12px 15px;
        margin-bottom: 20px;
        background-size: 100%;
    }

    .thumbs {
        width: 88px;
        bottom: 60px;
        right: 8px;
    }

    .pros {
        background-image: url(../images/pros-bg-m.png), linear-gradient(179.87deg, #319F7C 0.11%, rgba(0, 0, 0, 0) 99.89%);
    }

    .cons {
        background-image: url(../images/cons-bg-m.png), linear-gradient(164.87deg, #CF4239 10.64%, rgba(0, 0, 0, 0) 89.36%);
    }

    .pc-bg {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }

    .pros-and-cons h1 {
        font-size: 20px;
        line-height: 24px;
        margin: 15px 0;
    }

    .review-main-sticky.stick-to-bottom {
        position: fixed;
        width: 100%;
        bottom: 0;
        margin: 0;
    }

    .review-main-sticky.stick-to-bottom .score-div,
    .review-main-sticky.stick-to-bottom .tag{
        display: none !important;
    }

    .stick-to-bottom .review-main > div:nth-of-type(1) {
        border: none;
        width: auto;
        margin: 0;
    }

    .stick-to-bottom .visitSite {
        height: 39px;
        margin: 0;
        width: 100%;
        max-width: none;
    }

    .stick-to-bottom .visitSite span:nth-of-type(2) {
        line-height: 39px;
    }

    .stick-to-bottom .review-main {
        display: flex;
        flex-wrap: nowrap;
        padding: 0 15px;
        height: 70px;
        justify-content: space-between;
        min-height: initial;
    }

    #scroll-top {
        bottom: 100px;
    }
}

select::-ms-expand {
    display: none;
}


.chapters-menu:focus{
    outline: none;
}

.chapters-menu option {
    line-height: 42px;
    height: 42px;
    border-radius: 10px;
}


.chapters-menu-wrapper.fixed {
    position: fixed;
    top: 72px;
    left: calc((100% - 1280px)/2 + 45px);
    padding: 20px 0;
    background: #fff;
    width: 100%;
    max-width: 670px;
    transition: all 0.5s;
    border-bottom: 2px solid #ececec;
}

.fixed .chapters-menu {
    margin-top: 0;
    width: auto;
    min-width: 350px;
}

.chapters-menu-wrapper.scrollUp {
    transform: translateY(-164px);
}

.img_center {
    display: block;
}

section img {
    margin: 10px auto;
    max-width: 100%;
}

.review-header br {
    display: none;
}

.chapters-menu-wrapper {
    min-width: 350px;
}

.chapters-menu-wrapper select {
    width: 100%;
}


    
@media only screen and (max-width: 1200px) {
    .chapters-menu-wrapper.fixed {
        left: 15px;
    }

}

@media only screen and (max-width: 450px) {
    .chapters-menu,
    .chapters-menu-wrapper {
        width: 100%;
        margin: 0;
        min-width: 0;
    }

    .fixed .chapters-menu {
        min-width: 0;
    }

    .chapters-menu-wrapper {
        margin-top: 10px;
    }

    .chapters-menu-wrapper.fixed {
        width: calc(100% - 30px);
        margin: 0;
    }
    .chapters-menu-wrapper.fixed select {
        width: 100%;
    }

    .review-header > div:nth-of-type(2) {
        margin-left: inherit;
    }
}`;

export default styles;
