import React, { useEffect } from 'react'
import styles from '../../styles/Review.js'
import Header from '../../components/Header.jsx'
import Footer from '../../components/Footer.jsx'
import thumbsUp from '../../images/thumbs-up.png'
import thumbsDown from '../../images/thumbs-down.png'
import logo14 from "../../images/logos/663Hdcamchats.png"
import RecommendedSites from '../../components/RecommendedSites.jsx'
import RedirectingModal from '../../components/RedirectingModal.jsx'
import ReviewSpoiler from '../../components/ReviewSpoiler.jsx'
import { useLanguage } from '../../Helpers/LanguageContext.jsx'

function HdCamChats() {
     const [redirecting, setRedirecting] = React.useState(true)
    const link = `https://hdcamchats.com/landing/click/?AFNO=1-787`
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const { languageData } = useLanguage()
    return (
        <body className="lang-en niche-women" data-is-mobile="False">
            <div className="main-wrapper">
                <Header />
                <div class="body-content">
                    {
                        redirecting === true && <RedirectingModal link={link} setRedirecting={setRedirecting} logoRedirect={logo14} />
                    }
                    <div class="container mt-10">
                        <div class="review-main-sticky">

                            <div class="review-main">



                                <div>
                                    <a class="" href="https://hdcamchats.com/landing/click/?AFNO=1-787"
                                        target="_blank" rel="noopener" title="Group party chat">
                                        <img class="siteimg" src={logo14}
                                            alt="HDCamChats.com" title="HDCamChats.com" />
                                    </a>
                                </div>
                                <div class="score-div not-scored" data-site-id="39">
                                    <span class="mikes-score star-icon-3">3.2</span>

                                </div>
                                <div>
                                    <a onClick={e => setRedirecting(true)} style={{cursor: "pointer"}} class=" d-inline-block text-center  no-underline visitSite orange-background-gradient"
                                        
                                        target="_blank" rel="noopener" title="Group party chat">
                                        <span>Group party chat</span>
                                        <span>visit site</span>
                                    </a>

                                </div>
                            </div>

                        </div>

                        <div class="review-body-and-banner">
                            <RecommendedSites />


                            <div class="review-body">
                                <section id="pros_cons" className='pros-and-cons'>
                                    <div className="pros">
                                        <h1>Pros</h1>
                                        <li>A great variety of features, excellent discounts and live shows.</li>
                                        <li>The site offers a free show schedule which demonstrates that it pays attention to its users’ needs.</li>
                                        <img className='thumbs' src={thumbsUp} alt="thumbs" />
                                    </div>
                                    <div className="cons">
                                        <h1>{languageData.consTitle}</h1>
                                        <li>Lack of UX friendly perspective.</li>
                                        <li>The home page lists an incredible number of models on tiny thumbnails which is hard to read.</li>
                                        <li>Choice of tiny fonts may work well on desktop devices, but not so much on mobile display.</li>
                                        <img className='thumbs' src={thumbsDown} alt="thumbs" />
                                    </div>
                                </section>
                                <ReviewSpoiler title={'Number of WebCam Hosts'}>
                                    <section id="hosts">
                                        
                                        <p>With more than 2400 models online at all times, you will surely find some quality eye
                                            candy here. Even on the main page there are more than 80 models, and on the left panel,
                                            there is a list of the different cam categories, complete with the number of hosts that
                                            are currently online in the respective categories. </p>
                                        <p>&nbsp;</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Cost'}>
                                    <section id="cost">
                                        
                                        <p>Private chats range between $1.99 and $9.99 per minute with average being $3.99 per
                                            minute</p>
                                        <p>Videos begin at $2.99 per minute or $14.99 for unlimited monthly access.</p>
                                        <p>Once you sign up, you are immediately prompted to upgrade to a Lifetime Membership,
                                            simply by submitting your credit card data, with no immediate charges and no recurring
                                            fees. </p>
                                        <p><strong>Payment Methods</strong></p>
                                        <ul>
                                            <li>Card: Visa, Master Card, Maestro, JCB, Discover, Diners Club</li>
                                            <li>PayPal</li>
                                            <li>PaySafeCard</li>
                                        </ul>
                                        <p><strong>CC Billing Options</strong></p>
                                        <ul>
                                            <li>Epoch</li>
                                        </ul>





                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Chat types'}>
                                    <section id="promotions">
                                        
                                        <ul>
                                            <li><b>Party Chat</b> is free chat. It allows all users to see the list of members in
                                                the same room and see each other’s messages.</li>
                                            <li><b>Private Chat:</b> for this, you need to sign up, submit CC data and also buy
                                                GOLD, which is this site’s currency. When you find the button give GOLD on any
                                                performer’s page, it simply means tipping.</li>
                                            <li><b>Premium/Exclusive chats</b> are the ones you basically reserve with the performer
                                                for a discount, hence the name discounted block sessions: book the model for 15, 30,
                                                45 or 60 minutes straight and you can have a serious discount. </li>
                                            <li><b>GOLD Shows:</b> group shows, set to begin when a certain amount of GOLD is
                                                accumulated by the members who wish to participate.</li>
                                        </ul>
                                    </section>

                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Types of Memberships and Benefits'}>
                                    <section id="member_info">
                                        
                                        <p><strong>Visitor/Unregistered User Benefits:</strong></p>
                                        <ul>
                                            <li>Access Free chat with clothed models. The thumbnails on the main page may be
                                                explicit but without signing up and actually paying, you will rarely bump into full
                                                nudity here.s</li>
                                            <li>Access models profile pages with selected pics</li>
                                        </ul>
                                        <p><strong>Free Registered Member Benefits:</strong></p>
                                        <ul>
                                            <li>Unlimited Free Live Webcam Chat with classic and celebrity hosts</li>
                                            <li>Free text chat with hosts</li>
                                            <li>Access hoss’t profile pages, videos and photos</li>
                                            <li>You can email hosts</li>
                                            <li>You get alerts when specific hosts come online </li>
                                            <li>You can add hosts to your favorites list</li>
                                            <li>You can rate the models&rsquo; performance</li>
                                        </ul>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Hosts Profile'}>
                                    <section id="profiles">
                                        
                                        <p>HDcamChats sex cam girls have standard profiles with some images and sometimes free
                                            videos. If you want to see more image and view more videos, you’ll have to register. The
                                            profile includes appearance, turn on/turn off, schedules, features, tags, their last
                                            login date, expertise, language(s) they speak, features and a short summary of what you
                                            can expect during their shows. It’s pretty informative and after checking out a few
                                            profiles, you will know what you can expect during private chats and gold shows as well.
                                        </p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Navigation'}>
                                    <section id="navigation">
                                       
                                        <p>You can simply navigate through the website with ease using the main site categories:</p>
                                        <ul>
                                            <li>Home</li>
                                            <li>Search</li>
                                            <li>New Hosts</li>
                                            <li>Support</li>
                                            <li>Member Login</li>
                                            <li>Show Calendar</li>
                                        </ul>
                                        <p><strong>Search Options</strong></p>
                                        <p>Finding a specific model or looking for a certain type of performer is fast and easy with
                                            the search options on the left panel on the main site. You can search under different
                                            types of categories to find exactly what you are looking for. You can also filter the
                                            performers by region, chat language and features.</p>
                                        <p>A lot of cammers also want to chatting with new performers, assuming that those models
                                            will be more enthusiastic and will have more enjoyable as well as less expensive live
                                            shows. You can find them filed under New Models in the top left corner on the main page.
                                        </p>

                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Customer Support'}>
                                    <section id="customer_support">
                                        
                                        <p>For immediate assistance you can contact LiveSupport. If you have additional questions
                                            you may also call the following numbers 24 hours a day, 7 days a week:</p>
                                        <ul>
                                            <li>United States Of America: (866) 941-3982</li>
                                            <li>Australia: 1800 972 031</li>
                                            <li>Canada: (866) 941-3982</li>
                                            <li>France: 0 800 90 32 92</li>
                                            <li>Germany: 0800 1810993</li>
                                            <li>Italy: 800 875 206</li>
                                            <li>Switzerland: 0800 896 441</li>
                                            <li>United Kingdom (Great Britain): 0800 098 8202</li>
                                            <li>Other countries: +1 206-438-0241</li>

                                        </ul>

                                        <p>You can also email our customer support team by using the form below. If you have trouble
                                            with this form you can also reach them at supportteam@webcamassist.com.</p>
                                        <p>Please add support@webcamassist.com and supportteam@webcamassist.com to your address book
                                            to prevent being marked as spam.</p>

                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Languages'}>
                                    <section id="languages">
                                        
                                        <p>The webcam chat site is available the next languages below:</p>
                                        <ul>
                                            <li>English</li>
                                            <li>Italian</li>
                                            <li>French</li>
                                            <li>Spanish</li>
                                            <li>German</li>
                                            <li>Swedish</li>
                                            <li>Norwegian</li>
                                            <li>Portuguese</li>
                                            <li>Dutch</li>
                                        </ul>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Bottom Line'}>
                                    <section id="bottom-line">
                                       
                                        <p>HDCamChats.com has a very wide selection of hosts in numerous categories, which are easy
                                            to search for thanks to simple and easy-to-read navigating tools on the site. Models are
                                            friendly and energetic and always give top quality shows. Plus the high quality cams
                                            make for a very pleasurable experience.</p>
                                    </section>
                                </ReviewSpoiler>

                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
            <style>
                {styles}
            </style>


        </body >
    )
}

export default HdCamChats
