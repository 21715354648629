import React, { useEffect } from 'react'
import styles from '../../styles/Review.js'
import Header from '../../components/Header.jsx'
import Footer from '../../components/Footer.jsx'
import thumbsUp from '../../images/thumbs-up.png'
import thumbsDown from '../../images/thumbs-down.png'
import logo7 from "../../images/logos/6354camscom.png"
import RecommendedSites from '../../components/RecommendedSites.jsx'
import RedirectingModal from '../../components/RedirectingModal.jsx'
import ReviewSpoiler from '../../components/ReviewSpoiler.jsx'
import { useLanguage } from '../../Helpers/LanguageContext.jsx'

function Cams() {

     const [redirecting, setRedirecting] = React.useState(true)
    const link = `https://cams.com/go/?pid=g1083227-.subRonMike`

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const { languageData } = useLanguage()

    return (
        <body className="lang-en niche-women" data-is-mobile="False">
            <div className="main-wrapper">
                <Header />
                <div class="body-content">
                    {
                        redirecting === true && <RedirectingModal logoRedirect={logo7} link={link} setRedirecting={setRedirecting} />
                    }
                    <div class="container mt-10">
                        <div class="review-main-sticky">
                            <div class="review-main">
                                <div>
                                    <a class="" href={link}
                                        target="_blank" rel="noopener" title="10 Free credits">
                                        <img class="siteimg" src={logo7} alt="Cams.com"
                                            title="Cams.com" />
                                    </a>
                                </div>
                                <div class="score-div not-scored" data-site-id="9">
                                    <span class="mikes-score star-icon-4">3.9</span>

                                </div>
                                <div>
                                    <a onClick={e => setRedirecting(true)} style={{cursor: "pointer"}} class=" d-inline-block text-center  no-underline visitSite orange-background-gradient"
                                         target="_blank"
                                        rel="noopener" title="10 Free credits">
                                        <span>10 Free credits</span>
                                        <span>visit site</span>
                                    </a>

                                </div>
                            </div>

                        </div>

                        <div class="review-body-and-banner">
                            <RecommendedSites />
                            <div class="review-body">
                                <section id="pros_cons" className='pros-and-cons'>
                                    <div className="pros">
                                        <h1>Pros</h1>
                                        <li>A professional webcam site that features all the live shows you could want.</li>
                                        <li>Tons of basic features such as galleries, recorded shows and HD webcams.</li>
                                        <img className='thumbs' src={thumbsUp} alt="thumbs" />
                                    </div>
                                    <div className="cons">
                                        <h1>{languageData.consTitle}</h1>
                                        <li>Lacks the basic necessities of a cam site: thorough search options and feature lists.</li>
                                        <li>At first, feels like the only performers you can watch are those listed right on the home page.</li>
                                        <li>Takes some digging around to figure out all the options.</li>
                                        <li>Private room streams can take over a minute to load.</li>
                                        <img className='thumbs' src={thumbsDown} alt="thumbs" />
                                    </div>
                                </section>
                                <ReviewSpoiler title={'Number of Models'}>
                                    <section id="hosts">

                                        <p>There are more than 14,000 registered models on Cams.com, so you can always expect
                                            hundreds to be online whenever you visit.</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Cost'}>
                                    <section id="cost">

                                        <p>Both Guest and Basic membership options are completely free. Guests can see photos from
                                            live chats and check out bios as well as view public galleries. Basic members can do the
                                            same, plus interact in non-nude chats, view models’ public galleries and check out fan
                                            clubs. Basic members can also add funds to join nude and private chats.</p>
                                        <p>Live chat costs approximately $2.99 per minute, but the price varies according to the
                                            credit package you purchase and the webcam model you choose. There are usually excellent
                                            discounts, but you can enjoy relatively inexpensive chat shows even if you don’t like to
                                            watch the same model over and over again. As always, the price of a live chat depends on
                                            many factors, this will give you a general perspective:</p>
                                        <ul>
                                            <li>Private 1 on 1 video chatting costs approximately $3.99 per minute on average, but
                                                there are chat rooms which cost less</li>
                                            <li>Public Video Chat – this is the same idea as a private video chat, only with
                                                additional members sharing the experience with you. This costs between $0.99 to
                                                $5.99 or a little more, depending on the model.</li>
                                            <li>Spy Cam Mode, where you can see another user’s show, costs $2.99 per minute</li>
                                            <li>Group Live Shows are offered for a special fixed rate of $9.90 for the first 10
                                                minutes. If the show continues after this time and you feel like staying, you will
                                                be billed $0.99/minute.</li>
                                            <li>Party Chat rates are available between 3:00 and 6:00pm and they are only $0.99 per
                                                minute
                                            </li>
                                        </ul>
                                        <p><b>Credits</b></p>
                                        <p>Usually on adult webcam sites the more you buy the less you pay. On Cams.com this is not
                                            true, credit goes for a standard rate of $1, no matter how many you purchase. These are
                                            your options:</p>
                                        <ul>
                                            <li> Standard Packages
                                                <ul>
                                                    <li>250 + 100 free tokens: $26</li>
                                                    <li>500 + 100 free tokens: $50</li>
                                                    <li>1000 + + 100 free tokens: $99</li>
                                                    <li>1500 + 100 free tokens: $150</li>
                                                </ul>
                                            </li>
                                            <li> High-roller Packages
                                                <ul>
                                                    <li>2000 + 100 free tokens: $198</li>
                                                    <li>3000 + 100 free tokens: $297</li>
                                                    <li>4000 + 100 free tokens: $396</li>
                                                    <li>5000 + 100 free tokens: $495</li>
                                                    <li>10000 + 100 free tokens: $990</li>
                                                </ul>
                                            </li>

                                            <p>There is also a limited offer for first-time signups: you can get the lowest price
                                                package (100 = 100 free tokens) only for $12.</p>
                                            <p>Cams.com offers members all kinds of shows. You can watch 7 types of live
                                                performances: Cam 2 Cam, Free Chats, Private Chats, Nude Chats, Tipping Show (you
                                                can spy on others’ private shows with the host for a set amount). Many webcams
                                                streams are HD and of course, all of them have audio. There’s a mobile site and you
                                                can find recorded shows as well.</p>
                                            <p><b>Paying for Videos</b></p>
                                            <p>"Videos" are prerecorded live chats and shows. The quality of these depends on the
                                                models, but you can usually find nice clips that are worth your time and money.
                                                Watching a recorded live chat session is not cheap starting at $4.99, which is
                                                pretty pricy for a service that is really no match for an actual 1on1 live show. You
                                                can also purchase photos and albums too. </p>
                                            <p><b>Top Admirer and Fan Club Cost</b></p>
                                            <p>It&rsquo;s always nice to join a model&rsquo;s fan club, this too comes with a price.
                                                Joining a performer&rsquo;s Fan Club is $14.99 for one month only or $10.99 per
                                                month for 3 months (billed at approximately $32.97). This will give you access to
                                                10% off the model’s live shows and free access to her recorded videos. </p>
                                            <p>
                                                As for becoming a Top Admirer, the model must have a minimum of 200 tokens in Tips
                                                and Virtual Gifts before the benefits (free access to the model’s pre-recorded shows
                                                and premium content) are available to Admirers.
                                            </p>
                                            <p><b>Payment options:</b></p>

                                            <ul>
                                                <li>Credit Cards: Visa and MasterCard</li>
                                            </ul>
                                        </ul>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Top Promotions'}>

                                    <section id="promotions">

                                        <ul>
                                            <li>Free Chat Rooms</li>
                                            <li>Buzzmode shows at $0.10 per click</li>
                                            <li>$15 in Free Credits for New Members</li>
                                            <li>10% off all live shows, 5 free recorded shows per month for Premiere Members</li>
                                            <li>Receive 5 recorded shows FREE when you buy $100 worth of credit</li>
                                            <li>Receive 1 recorded show when you sign up for a Basic membership</li>
                                            <li>Private chat with a model for just 30 minutes and receive a FREE fan club membership
                                            </li>
                                            <li>Party Chat rates only $0.99</li>

                                        </ul>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Types of Memberships and Benefits'}>
                                    <section id="member_info">

                                        <p>There are 3 basic types of membership on Cams.com which range in price and benefits. Here
                                            is a quick list of the various options you have when signing up:</p>
                                        <p><b>Free Membership</b></p>
                                        <p>You won&rsquo;t be surprised to discover that a basic membership offers the fewest
                                            advantages, but it is still fun. As a free user you can still watch private shows and
                                            access basic features, and for those of you who only use chat rooms occasionally, this
                                            membership is really all you need. </p>
                                        <p><strong>Cost:</strong>&nbsp;Free</p>
                                        <p><strong>Information Required:</strong>&nbsp;Username, valid email.</p>
                                        <p><strong>Benefits:</strong></p>
                                        <ul>
                                            <li>Enjoy unlimited free chat room access</li>
                                            <li>Watch all live shows, for payment</li>
                                            <li>Join fan clubs, for payment</li>
                                        </ul>
                                        <p><b>Premiere Membership</b></p>
                                        <p>This type of membership is the easiest way to save money on Cams.com. It may cost a bit
                                            per month, but if you&rsquo;re frequently using the site, it is worth signing up:</p>
                                        <p><strong>Cost to Join:</strong></p>
                                        <ul>
                                            <li>1 Month for $19.99</li>
                                            <li>3 Month for $41.97, which is only $13.99 per month</li>
                                        </ul>
                                        <p><strong>Information Required:</strong>&nbsp;Valid email, password, credit card info.</p>
                                        <p><strong>Benefits:</strong></p>
                                        <ul>
                                            <li>10% off all videos</li>
                                            <li>Special attention from the models</li>
                                            <li>Free gallery access</li>
                                            <li>Contact models directly
                                            </li>
                                        </ul>
                                        <p><b>Special Features</b></p>
                                        <p>Cams.com is a veteran video web cam website, but surprisingly, there are few exclusive
                                            features. Sure, you still get all the standard options, but there is nothing
                                            that&rsquo;s really unforgettable or a "must-have".</p>

                                        <p><b>User Features</b></p>
                                        <ul>
                                            <li>Photo Galleries – Featuring the webcam models doing all sorts of interesting things.
                                            </li>
                                            <li>Instant Notifications – Which tell you when a specific cam host is online.</li>
                                            <li>Videos – Basically the same as recorded shows that you can watch on your own free
                                                time, for a price.</li>
                                            <li>Members&rsquo; Bio Pages – Where members are free to upload their personal
                                                information.</li>
                                            <li>Favorite List and Recently Viewed – That includes webcam models you like and want to
                                                watch again.</li>
                                            <li>Fan Club – See above. Gives you special discounts for specific hosts and their
                                                online content.</li>

                                        </ul>
                                        <p><b>Video Chat Features</b></p>
                                        <ul>
                                            <li>Cam 2 Cam – View a webcam performer while you too are visible on your webcam. You
                                                MUST have a webcam for this option.</li>
                                            <li>HD web Cams
                                            </li>
                                        </ul>
                                        <p><b>Cams.com Mobile</b></p>
                                        <p>Users who find the desktop site cumbersome and want to continue chatting with their
                                            favorite webcam models “on the go”, can use the Cams.com mobile version. It&rsquo;s
                                            pretty professional and can be accessed from any smart phone.</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Models Profiles'}>
                                    <section id="profiles">

                                        <p>The Cams.com models’ profile pages could possibly be more organized. They are full of
                                            useful facts, but they look as unorganized and the information isn&rsquo;t really listed
                                            in any clear fashion. The data you can find on their personal pages include:</p>
                                        <ul>
                                            <li>About the Host – Some general information added by the model about herself.</li>
                                            <li>Awards the Webcam Host Received</li>
                                            <li>Number of Fans</li>
                                            <li>Turn-Ons and Turn-Offs</li>
                                            <li>Physical attributes - physical features, ethnicity, etc.</li>
                                            <li>Kinks and Fetishes</li>
                                            <li>Unique Physical Traits – Tattoos, for example.</li>
                                            <li>Live Show Previews, these are snapshots taken from private shows. Not especially
                                                wild, but they give a good indication of what the model really looks like.</li>
                                            <li>Links to Private Chat, Fan Club, Recorded Live Shows and Cam Show Pictures. As the
                                                profiles are not very organized, these are sometime hard to find, so to save you the
                                                trouble of looking, we will tell you that they are located on the left side, right
                                                above the "My Schedule" section.</li>
                                            <li>Superbuzz – a special token boost you can send to the model, to make sure she pays
                                                special attention to you</li>
                                            <li>You can spin the Wheel in certain performers’ rooms, and the wheel can land on
                                                special performances like ass shaking.</li>
                                            <li>You can buzz the performer’s toy provided she has one at hand – or somewhere else.
                                            </li>
                                            <li>What surprised me is that they make it really hard to leave a model’s room – you
                                                have to confirm with a click that you want to leave, and even after that, the site
                                                can loop you back into the performer’s room. Which is fortunate if you really liked
                                                the model in the first place. If not, keep clicking.</li>
                                        </ul>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Navigation'}>
                                    <section id="navigation">

                                        <p>It&rsquo;s not hard to find your way around on Cams.com, and all the important locations
                                            are clearly marked. The top bar lists:</p>
                                        <ul>
                                            <li>Online Models</li>
                                            <li>Join Now</li>
                                            <li>Member Login</li>
                                            <li>Search</li>
                                            <li>Various Model Categories</li>
                                        </ul>
                                        <p>And really, that&rsquo;s all you need to get started.</p>
                                        <p><b>Search Options</b></p>
                                        <p>Models can be searched for according to specific categories, features or via the free
                                            search option. Just about any criteria can be searched for, either through the list of
                                            popular categories or through the more specialized search tool that can easily be
                                            reached through the "search" box.</p>
                                        <p>You can also use the Filter tool, which is a quick way of locating models of a specific
                                            type. You’ll find it to the left of the live chat room main page and can simply click on
                                            whatever category that interests you, to narrow down the results.</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Customer Service'}>
                                    <section id="customer_support">

                                        <p>This is where the site gets pretty laconic and after all the convenient ’Contact us!’
                                            buttons on other sites, I had to dig deep to find any kind of contact option. Which is
                                            as follows: „Contact us at 888 575 8383 (US toll free), 0800 098 8311 (UK toll-free),
                                            1800 954 608 (AU toll-free) or 1 (669)208-0370 (worldwide).” That’s all.</p>
                                        <p><b>FAQ</b></p>
                                        <p>FAQ pages do not exist anymore. Fortunately, it’s highly unlikely that you will get lost
                                            on the site. </p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Languages'}>

                                    <section id="languages">

                                        <p>Cams.com is only in English, Spanish and Chinese, but the models speak many other
                                            languages. Models speaking the following languages can be found, but it doesn’t
                                            guarantee that the model of your choice will speak your preferred language:</p>
                                        <ul>
                                            <li>English</li>
                                            <li>German</li>
                                            <li>Russian</li>
                                            <li>Spanish</li>
                                            <li>Portuguese</li>
                                            <li>Hindi</li>
                                            <li>Italian</li>
                                            <li>Polish</li>
                                            <li>Arabic</li>
                                            <li>Czech</li>
                                            <li>French</li>
                                            <li>Italian</li>
                                            <li>Japanese</li>
                                            <li>Dutch</li>
                                            <li>Korean</li>
                                            <li>Chinese (Mandarin)</li>
                                            <li>Chinese (Cantonese)</li>
                                            <li>Hebrew</li>
                                        </ul>
                                    </section>

                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Bottom Line'}>
                                    <section id="bottom_line">

                                        <p>I like to visit Cams.com for the same reason I get my coffee at Starbucks. Sure, it&#39;s
                                            slightly pricier, but I know exactly what I&#39;m getting, there are no unpleasant
                                            surprises and I can be sure that it will always be excellent.</p>
                                    </section>
                                </ReviewSpoiler>

                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
            <style>
                {styles}
            </style>


        </body >
    )
}

export default Cams
