import React from "react";
import cn from "classnames";

function FAQItem(props) {
  const [open, setOpen] = React.useState(props.opened || false);

  return (
    <div
      className={cn("faq", {
        expanded: open,
      })}
      onClick={() => setOpen(!open)}
    >
      <div className="faq-header">{props.question}</div>
      <div
        className="faq-content"
        style={open ? { display: "block" } : { display: "none" }}
      >
        {props.answer}
      </div>
    </div>
  );
}

export default FAQItem;
