import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useLanguage } from '../Helpers/LanguageContext';

function GeneralAdult() {
    const { languageData } = useLanguage();
    return (
        <body className="lang-en niche-women" data-is-mobile="False">
            <div className="main-wrapper">
                <Header />
                <div className="body-content">
                    <div className="container mt-10">
                        <div>
                            <h1>{languageData?.GeneralAdultCamFeaturesTitle}</h1>
                            <h1>&nbsp;</h1>
                            <h2>{languageData?.Cam2CamTitle}</h2>
                            <p>{languageData?.Cam2CamContent}</p>
                            <p>{languageData?.ImLiveReference}<a href="review/imlive" target="_blank" rel="noopener">ImLive</a>{languageData?.ImLiveReferenceEnd}</p>
                            <p>&nbsp;</p>
                            <h2>{languageData?.CelebrityChannelTitle}</h2>
                            <p>{languageData?.CelebrityChannelContent}</p>
                            <p>{languageData?.CelebrityChannelPricing}</p>
                            <p>&nbsp;</p>
                            <h2>{languageData?.KeyholeFeatureTitle}</h2>
                            <p>{languageData?.KeyholeFeatureContent}</p>
                            <p>&nbsp;</p>
                            <h2>{languageData?.SpecialEventsAndThemePartiesTitle}</h2>
                            <p>{languageData?.SpecialEventsAndThemePartiesContent}</p>
                            <p>&nbsp;</p>
                            <h2>{languageData?.HappyHourTitle}</h2>
                            <p>{languageData?.HappyHourContent}</p>
                            <p>&nbsp;</p>
                            <h2>{languageData?.FanClubOptionTitle}</h2>
                            <p>{languageData?.FanClubOptionContent}</p>
                            <p>{languageData?.FanClubMembershipInfo}</p>
                            <p>&nbsp;</p>
                            <h2>{languageData?.GroupCamsTitle}</h2>
                            <p>{languageData?.GroupCamsContent}</p>
                            <p>&nbsp;</p>
                            <h2>{languageData?.VideoCompetitionsTitle}</h2>
                            <p>{languageData?.VideoCompetitionsContent}</p>
                            <p>&nbsp;</p>
                            <h2>{languageData?.DownloadForOffHoursFunTitle}</h2>
                            <p>{languageData?.DownloadForOffHoursFunContent}</p>
                            <p>&nbsp;</p>
                            <h2>{languageData?.LiveViewerTitle}</h2>
                            <p>{languageData?.LiveViewerContent}</p>
                            <p>&nbsp;</p>
                            <h2>{languageData?.FavoritesTitle}</h2>
                            <p>{languageData?.FavoritesContent}</p>
                            <p>&nbsp;</p>
                            <h2>{languageData?.WishListTitle}</h2>
                            <p>{languageData?.WishListContent}</p>
                            <p>&nbsp;</p>
                            <h2>{languageData?.MakeADateTitle}</h2>
                            <p>{languageData?.MakeADateContent} <a href="/">iFriends</a>{languageData?.MakeADateContentEnd}</p>
                            <p>&nbsp;</p>
                            <h2>{languageData?.AlertsTitle}</h2>
                            <p>{languageData?.AlertsContent}</p>
                            <p>&nbsp;</p>
                            <h2>{languageData?.ChatRouletteTitle}</h2>
                            <p>{languageData?.ChatRouletteContent}</p>
                            <p>&nbsp;</p>
                            <h2>{languageData?.OneClickPurchaseTitle}</h2>
                            <p>{languageData?.OneClickPurchaseContent}</p>
                            <p>&nbsp;</p>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </body>
    );
}

export default GeneralAdult;
