import React, { useEffect } from 'react'
import styles from '../../styles/Review.js'
import Header from '../../components/Header.jsx'
import Footer from '../../components/Footer.jsx'
import thumbsUp from '../../images/thumbs-up.png'
import thumbsDown from '../../images/thumbs-down.png'
import topModels from "../../images/top-models.png"
import logo4 from "../../images/logos/735camsoda-logo.png"
import RecommendedSites from '../../components/RecommendedSites.jsx'
import ReviewSpoiler from '../../components/ReviewSpoiler.jsx'
import redirectingImg from '../../images/redirecting.gif'
import RedirectingModal from '../../components/RedirectingModal.jsx'
import { useLanguage } from '../../Helpers/LanguageContext.jsx'

function CamSoda() {

     const [redirecting, setRedirecting] = React.useState(true)
    const link = `https://www.camsoda.com/?id=camdeals2&type=REV&promo_code=mikecamreviewsfree200`

    const { languageData } = useLanguage()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <body className="lang-en niche-women" data-is-mobile="False">
            <div className="main-wrapper">
                <Header />
                <div class="body-content">

                    {
                        redirecting === true && <RedirectingModal link={link} setRedirecting={setRedirecting} logoRedirect={logo4} />
                    }

                    <div class="container mt-10">
                        <div class="review-main-sticky">

                            <div class="review-main">
                                <img class="tag" title="Top Models" src={topModels} />
                                <div>
                                    <a class=""
                                        href={link}
                                        target="_blank" rel="noopener" title="Special offer - Get 200 Free Tokens">
                                        <img class="siteimg" src={logo4}
                                            alt="CamSoda.com" title="CamSoda.com" />
                                    </a>
                                </div>
                                <div class="score-div not-scored" data-site-id="32">
                                    <span class="mikes-score star-icon-4">4.3</span>

                                </div>
                                <div>
                                    <a onClick={e => setRedirecting(true)} style={{cursor: "pointer"}} class=" d-inline-block text-center  no-underline visitSite orange-background-gradient"
                                        
                                        target="_blank" rel="noopener" title="Special offer - Get 200 Free Tokens">
                                        <span>{languageData.specialOfferText}</span>
                                        <span>{languageData.visitSiteText}</span>
                                    </a>
                                </div>
                            </div>

                        </div>

                        <div class="review-body-and-banner">
                            <RecommendedSites />


                            <div class="review-body">

                                <section id={languageData.sodaProsConsSectionId} className={languageData.sodaProsConsSectionClass}>
                                    <div className="pros">
                                        <h1>{languageData.sodaProsHeader}</h1>
                                        <li>{languageData.sodaProsItem1}</li>
                                        <li>{languageData.sodaProsItem2}</li>
                                        <li>{languageData.sodaProsItem3}</li>
                                        <li>{languageData.sodaProsItem4}</li>
                                        <li>{languageData.sodaProsItem5}</li>
                                        <img className='thumbs' src={thumbsUp} alt={languageData.sodaThumbsUpAlt} />
                                    </div>
                                    <div className="cons">
                                        <h1>{languageData.sodaConsHeader}</h1>
                                        <li>{languageData.sodaConsItem1}</li>
                                        <li>{languageData.sodaConsItem2}</li>
                                        <img className='thumbs' src={thumbsDown} alt={languageData.sodaThumbsDownAlt} />
                                    </div>
                                </section>
                                <ReviewSpoiler title={languageData.sodaHostsSectionTitle}>
                                    <section id={languageData.sodaHostsSectionId}>

                                        <p>{languageData.sodaHostsParagraph}</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={languageData.sodaCostSectionTitle}>
                                    <section id={languageData.sodaCostSectionId}>

                                        <p>{languageData.sodaCostParagraph1}</p>
                                        <p><strong>{languageData.sodaCostAverageCostsHeader}</strong></p>
                                        <ul>
                                            <li>{languageData.sodaCostPrivateChat}</li>
                                            <li>{languageData.sodaCostPhotos}</li>
                                            <li>{languageData.sodaCostVideos}</li>
                                            <li>{languageData.sodaCostTips}</li>
                                        </ul>
                                        <p><strong>{languageData.sodaCostAverageTokenPackagesHeader}</strong></p>
                                        <ul>
                                            <li>{languageData.sodaCostTokenPackage1}</li>
                                            <li>{languageData.sodaCostTokenPackage2}</li>
                                            <li>{languageData.sodaCostTokenPackage3}</li>
                                            <li>{languageData.sodaCostTokenPackage4}</li>
                                            <li>{languageData.sodaCostTokenPackage5}</li>
                                            <li>{languageData.sodaCostTokenPackage6}</li>
                                            <li>{languageData.sodaCostTokenLimit}</li>
                                        </ul>
                                        <p>{languageData.sodaCostAdditionalPackagesInfo}</p>
                                        <ul>
                                            <li>{languageData.sodaCostTokenPackage7}</li>
                                            <li>{languageData.sodaCostTokenPackage8}</li>
                                            <li>{languageData.sodaCostTokenPackage9}</li>
                                        </ul>
                                        <p><strong>{languageData.sodaCostRegisteredUsersHeader}</strong></p>
                                        <ul>
                                            <li>{languageData.sodaCostRegisteredUserOption1}</li>
                                            <li>{languageData.sodaCostRegisteredUserOption2}</li>
                                        </ul>
                                        <p><strong>{languageData.sodaCostMembershipOptionHeader}</strong></p>
                                        <p>{languageData.sodaCostMembershipOption}</p>
                                        <h2>{languageData.sodaPaymentMethodsHeader}</h2>
                                        <ul>
                                            <li>{languageData.sodaPaymentMethod1}</li>
                                        </ul>
                                        <p><strong>{languageData.sodaCCBillingOptionsHeader}</strong></p>
                                        <ul>
                                            <li>{languageData.sodaCCBillingOption1}</li>
                                            <li>{languageData.sodaCCBillingOption2}</li>
                                            <li>{languageData.sodaCCBillingOption3}</li>
                                            <li>{languageData.sodaCCBillingOption4}</li>
                                        </ul>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={languageData.sodaPromotionsSectionTitle}>
                                    <section id={languageData.sodaPromotionsSectionId}>
                                        <ul>
                                            <li><strong>{languageData.sodaPromotion1}</strong></li>
                                            <li>{languageData.sodaPromotion2}</li>
                                            <li>{languageData.sodaPromotion3}</li>
                                            <li>{languageData.sodaPromotion4}</li>
                                            <li>{languageData.sodaPromotion5}</li>
                                        </ul>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={languageData.sodaMemberInfoSectionTitle}>
                                    <section id={languageData.sodaMemberInfoSectionId}>
                                        <ul>
                                            <li>
                                                <p>{languageData.sodaMembershipParagraph}</p>
                                                <p><strong>{languageData.sodaBasicUsersHeader}</strong></p>
                                                <ul>
                                                    <li>{languageData.sodaBasicUserOption1}</li>
                                                    <li>{languageData.sodaBasicUserOption2}</li>
                                                </ul>
                                                <p><strong>{languageData.sodaPremiumUsersHeader}</strong></p>
                                                <ul>
                                                    <li>{languageData.sodaPremiumUserOption1}</li>
                                                    <li>{languageData.sodaPremiumUserOption2}</li>
                                                    <li>{languageData.sodaPremiumUserOption3}</li>
                                                    <li>{languageData.sodaPremiumUserOption4}</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={languageData.sodaFeaturesSectionTitle}>
                                    <section id={languageData.sodaFeaturesSectionId}>
                                        <ul>
                                            <li>{languageData.sodaFeature1}</li>
                                            <li>{languageData.sodaFeature2}</li>
                                            <li>{languageData.sodaFeature3}</li>
                                            <li>{languageData.sodaFeature4}</li>
                                            <li>{languageData.sodaFeature5}</li>
                                            <li>{languageData.sodaFeature6}</li>
                                            <li>{languageData.sodaFeature7}</li>
                                            <li>{languageData.sodaFeature8}</li>
                                            <li>{languageData.sodaFeature9}</li>
                                            <li>{languageData.sodaFeature10}</li>
                                            <li>{languageData.sodaFeature11}</li>
                                            <li>{languageData.sodaFeature12}</li>
                                            <li>{languageData.sodaFeature13}</li>
                                            <li>{languageData.sodaFeature14}</li>
                                            <li>{languageData.sodaFeature15}</li>
                                            <li>{languageData.sodaFeature16}</li>
                                            <li>{languageData.sodaFeature17}</li>
                                            <li>{languageData.sodaFeature18}</li>
                                            <li>{languageData.sodaFeature19}</li>
                                        </ul>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={languageData.sodaProfilesSectionTitle}>
                                    <section id={languageData.sodaProfilesSectionId}>
                                        <p>{languageData.sodaProfilesParagraph1}</p>
                                        <p>{languageData.sodaProfilesParagraph2}</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={languageData.sodaNavigationSectionTitle}>
                                    <section id={languageData.sodaNavigationSectionId}>
                                        <p>{languageData.sodaNavigationParagraph1}</p>
                                        <p>{languageData.sodaNavigationParagraph2}</p>
                                        <p>{languageData.sodaNavigationParagraph3}</p>
                                    </section>
                                </ReviewSpoiler>

                                <ReviewSpoiler title={languageData.sodaCustomerSupportSectionTitle}>
                                    <section id={languageData.sodaCustomerSupportSectionId}>
                                        <p>{languageData.sodaCustomerSupportParagraph}</p>
                                    </section>
                                </ReviewSpoiler>

                                <ReviewSpoiler title={languageData.sodaLanguagesSectionTitle}>
                                    <section id={languageData.sodaLanguagesSectionId}>
                                        <p>{languageData.sodaLanguagesParagraph}</p>
                                    </section>
                                </ReviewSpoiler>



                            </div>
                        </div>

                    </div>
                    <Footer />
                </div>
            </div>
            <style>
                {styles}
            </style>


        </body>
    )
}

export default CamSoda
