import React, { useState } from "react";
import "../styles/Main2.css";
import "../styles/Main.css";
import "../styles/Main3.css";
import "../styles/Faq.css";
import "../styles/Posts.css";
import bestDeal from "../images/best-deal.png";
import visitGradient from "../images/visit-site-gradient.png";
import logo1 from "../images/logos/975imlive_logo.png";
import bestChat from "../images/best-free-chat.png";
import logo2 from "../images/logos/746Chaturbate_logo.png";
import bestModels from "../images/best-variety-of-models.png";
import logo3 from "../images/logos/855myfreecams.png";
import topModels from "../images/top-models.png";
import logo4 from "../images/logos/735camsoda-logo.png";
import logo5 from "../images/logos/843Xlovecam.png";
import logo6 from "../images/logos/221Flirt4Free.png";
import logo7 from "../images/logos/6354camscom.png";
import logo8 from "../images/logos/096mydirtyhobby.png";
import logo9 from "../images/logos/882CamContacts.png";
import logo10 from "../images/logos/533BongaCams.png";
import logo11 from "../images/logos/745allcamsex.png";
import logo12 from "../images/logos/740exposedwebcams.png";
import logo13 from "../images/logos/096cam4.png";
import logo14 from "../images/logos/663Hdcamchats.png";
import logo15 from "../images/logos/225LiveCamGuru.png";
import logo16 from "../images/logos/stritchat.png";
import bgMain from "../images/main_banner-women.jpg";
import bgMainMobile from "../images/main_banner-women__m.jpg";
import Header from "../components/Header";
import Footer from "../components/Footer";
import FaqHome from "../components/FaqHome";
import cn from "classnames";
import moment from 'moment';
import PromoModal from "../components/Modals/PromoModal";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../Helpers/LanguageContext";
import mixpanel from "mixpanel-browser";


function Main() {

    const currentDate = moment().format('MMMM, YYYY');

    const navigate = useNavigate();
    const { languageData } = useLanguage()
    const params = new URLSearchParams(window.location.search);
    const isEmail = params.get('email') == 1;

    const [openedBlock, setOpenedBlock] = useState(1);
    const [showMore, setShowMore] = useState(false);

    const [promoModal, setPromoModal] = useState(false);
    const [selectedSite, setSelectedSite] = useState(null);

    return (
        <div>
            <div className="homepage lang-en niche-women" data-is-mobile="False">
                <div className="main-wrapper">
                    <Header />
                    <div className="body-content">
                        <div
                            className="hideOnMobile header-image statick-bg"
                            style={{ backgroundImage: `url(${bgMain})` }}
                        ></div>

                        <div className="main-background">
                            <div className="hideOnMobile header-image"></div>
                            <div
                                className="showOnMobile header-image"
                                style={{ backgroundImage: `url(${bgMainMobile})` }}
                            ></div>

                            <div className="header-content-wrap">
                                <div className="header-inner-wrap">
                                    <div className="header-month">{currentDate}</div>
                                    <h1>{languageData.MikesHandyWebcamEssentials}</h1>
                                    <h2>{languageData.topCamSitesAndDealsShortlistedByMike}</h2>
                                </div>
                            </div>
                        </div>

                        <div className="container">
                            <div className="top-container">
                                <div className="reviews-list">
                                    <div className="reviews-list-header hideOnMobile">
                                        <div>{languageData.camSites}</div>
                                        <span></span>
                                        <div>{languageData.scoreAndReview}</div>
                                        <span></span>
                                        <div>{languageData.theDeal}</div>
                                        <span></span>
                                        <div></div>
                                    </div>

                                    <div className={cn(`review-container`, {
                                        "expanded": openedBlock === 1,
                                    })}
                                        onClick={() => setOpenedBlock(openedBlock === 1 ? null : 1)}
                                        data-site-id="4">
                                        <img
                                            className="tag hideOnMobile"
                                            title="Best Deal"
                                            src={bestDeal}
                                            loading="lazy"
                                        />
                                        <div className="review-arrow-wrapper hideOnMobile">
                                            <div className="review-arrow"></div>
                                            <div className="review-arrow">
                                                <div className="review-arrow-btn"></div>
                                            </div>
                                            <div className="review-arrow"></div>
                                        </div>
                                        <div className="review-wrapper">
                                            <div className="imlive-hp-bg"></div>

                                            <span className="review-number hideOnMobile review-number-0">
                                                1
                                            </span>
                                            <div className="review-main">
                                                <img
                                                    className="visit-site-gradient hideOnMobile"
                                                    src={visitGradient}
                                                    loading="lazy"
                                                />

                                                <div>
                                                    <a
                                                        className=""
                                                        target="_blank"
                                                        rel="noopener"
                                                        href="review/imlive"
                                                        title="Visit Site"
                                                    >
                                                        <img
                                                            className="siteimg"
                                                            src={logo1}
                                                            alt="imLive.com"
                                                            title="imLive.com"
                                                            loading="lazy"
                                                        />
                                                    </a>
                                                </div>
                                                <div className="score-div not-scored" data-site-id="4">
                                                    <span className="mikes-score star-icon-5">4.7</span>
                                                    <div className="score-wrap">
                                                        <a className="read-more underline" href="review/imlive" title={languageData.readReviewText}>
                                                            {languageData.readReviewText}
                                                        </a>
                                                    </div>
                                                </div>
                                                <div>
                                                    <a className="btn-default text-center  no-underline" href="/review/imlive" target="_blank" rel="noopener" title={languageData.getCreditsText}>
                                                        {languageData.getCreditsText}
                                                    </a>
                                                </div>
                                                <div>
                                                    <a className="d-inline-block bold underline mr-2 visitSite orange-background-gradient" target="_blank" rel="noopener" href="/review/imlive" title={
                                                            isEmail ? languageData.getOffer : languageData.visitSiteText
                                                        } onClick={(e) => {
                                                        e.preventDefault();
                                                        mixpanel.track('Visit Site', {
                                                            distinct_id: window.localStorage.getItem('userID'),
                                                            'Site': 'ImLive'
                                                        });
                                                        if (isEmail) {
                                                            setSelectedSite('imlive');
                                                            setPromoModal(true);
                                                        } else {
                                                            navigate(`/review/imlive`)
                                                        }
                                                    }}>
                                                        {
                                                            isEmail ? languageData.getOffer : languageData.visitSiteText
                                                        }
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="review-expansion grid hideOnMobile grid-item-2" style={{ display: openedBlock !== 1 ? 'none' : 'grid' }}>
                                                <div className="short-description">
                                                    {languageData.shortDescriptionText}
                                                </div>
                                                <div className="review-bullets grid ">
                                                    <div className="review-bullet">
                                                        {languageData.reviewBullet1Text}
                                                    </div>
                                                    <div className="review-bullet">
                                                        {languageData.reviewBullet2Text}
                                                    </div>
                                                    <div className="review-bullet">
                                                        {languageData.reviewBullet3Text}
                                                    </div>
                                                    <div className="review-bullet">
                                                        {languageData.reviewBullet4Text}
                                                    </div>
                                                    <div className="review-bullet">
                                                        {languageData.reviewBullet5Text}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={cn(`review-container`, {
                                        "expanded": openedBlock === 2,
                                    })}
                                        onClick={() => setOpenedBlock(openedBlock === 2 ? null : 2)}
                                        data-site-id="4">
                                        <div className="review-arrow-wrapper hideOnMobile">
                                            <div className="review-arrow"></div>
                                            <div className="review-arrow">
                                                <div className="review-arrow-btn"></div>
                                            </div>
                                            <div className="review-arrow"></div>
                                        </div>
                                        <img
                                            className="tag hideOnMobile"
                                            title={languageData.bestVarietyModelsText}
                                            src={bestModels}
                                            loading="lazy"
                                        />
                                        <div className="review-wrapper">

                                            <span className="review-number hideOnMobile review-number-4">
                                                2
                                            </span>
                                            <div className="review-main">

                                                <img
                                                    className="visit-site-gradient hideOnMobile"
                                                    src={visitGradient}
                                                    loading="lazy"
                                                />

                                                <div>
                                                    <a
                                                        className=""
                                                        target="_blank"
                                                        rel="noopener"
                                                        href="/review/stripchat"
                                                        title={
                                                            isEmail ? languageData.getOffer : languageData.visitSiteText
                                                        }
                                                    >
                                                        <img
                                                            className="siteimg"
                                                            src={logo16}
                                                            alt="XLoveCam.com"
                                                            title="XLoveCam.com"
                                                            loading="lazy"
                                                        />
                                                    </a>
                                                </div>
                                                <div className="score-div not-scored" data-site-id="14">
                                                    <span className="mikes-score star-icon-4">4.6</span>
                                                    <div className="score-wrap">
                                                        <a
                                                            className="read-more underline"
                                                            href="/review/stripchat"
                                                            title={languageData.readReviewText}
                                                        >
                                                            {languageData.readReviewText}
                                                        </a>
                                                    </div>
                                                </div>
                                                <div>
                                                    <a
                                                        className="btn-default text-center  no-underline"
                                                        href="/review/stripchat"
                                                        target="_blank"
                                                        rel="noopener"
                                                        title={languageData.freeMembershipText}
                                                    >
                                                        No Cost To Sign Up
                                                    </a>
                                                </div>
                                                <div>
                                                    <a
                                                        className="d-inline-block bold underline mr-2 visitSite orange-background-gradient"
                                                        target="_blank"
                                                        rel="noopener"
                                                        href="/review/stripchat"
                                                        title={
                                                            isEmail ? languageData.getOffer : languageData.visitSiteText
                                                        }
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            mixpanel.track('Visit Site', {
                                                                distinct_id: window.localStorage.getItem('userID'),
                                                                'Site': 'StripChat'

                                                            });
                                                            setSelectedSite('stripchat');
                                                            if (isEmail) {
                                                                setPromoModal(true);
                                                            } else {
                                                                navigate(`/review/stripchat`)
                                                            }
                                                        }}
                                                    >
                                                        {
                                                            isEmail ? languageData.getOffer : languageData.visitSiteText
                                                        }
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="review-expansion grid hideOnMobile grid-item-2" style={{ display: openedBlock !== 2 ? 'none' : 'block' }}>
                                                <div className="review-bullets grid grid-item-3">
                                                    <div className="review-bullet">
                                                        Bonus new member giveaways, plus 50 tokens giveaway every hour
                                                    </div>
                                                    <div className="review-bullet">
                                                        Anonymous & secure billing for ultimate peace of mind and privacy
                                                    </div>
                                                    <div className="review-bullet">
                                                        Join models’ fan clubs for exclusive media and access
                                                    </div>
                                                    <div className="review-bullet">
                                                        Hot Spy Cams: come to a party uninvited, enjoy it to the fullest
                                                    </div>
                                                    <div className="review-bullet">
                                                        Discover the future of Live Cam Streams with VR Headset & VR Cams
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className={cn(`review-container`, {
                                        "expanded": openedBlock === 3,
                                    })}
                                        onClick={() => setOpenedBlock(openedBlock === 3 ? null : 3)}
                                        data-site-id="4">
                                        <img
                                            className="tag hideOnMobile"
                                            title="Best Free Chat"
                                            src={bestChat}
                                            loading="lazy"
                                        />
                                        <div className="review-arrow-wrapper hideOnMobile">
                                            <div className="review-arrow"></div>
                                            <div className="review-arrow">
                                                <div className="review-arrow-btn"></div>
                                            </div>
                                            <div className="review-arrow"></div>
                                        </div>
                                        <div className="review-wrapper">
                                            <span className="review-number hideOnMobile review-number-1">
                                                3
                                            </span>
                                            <div className="review-main">
                                                <img
                                                    className="visit-site-gradient hideOnMobile"
                                                    src={visitGradient}
                                                    loading="lazy"
                                                />

                                                <div>
                                                    <a
                                                        className=""
                                                        target="_blank"
                                                        rel="noopener"
                                                        href="review/chaturbate"
                                                        title="Visit Site"
                                                    >
                                                        <img
                                                            className="siteimg"
                                                            src={logo2}
                                                            alt="Chaturbate.com "
                                                            title="Chaturbate.com "
                                                            loading="lazy"
                                                        />
                                                    </a>
                                                </div>
                                                <div className="score-div not-scored" data-site-id="1">
                                                    <span className="mikes-score star-icon-5">4.6</span>
                                                    <div className="score-wrap">
                                                        <a
                                                            className="read-more underline"
                                                            href="review/chaturbate"
                                                            title={languageData.readReviewText}
                                                        >
                                                            {languageData.readReviewText}
                                                        </a>
                                                    </div>
                                                </div>
                                                <div>
                                                    <a
                                                        className="btn-default text-center  no-underline"
                                                        href="review/chaturbate"
                                                        target="_blank"
                                                        rel="noopener"
                                                        title={languageData.referFriendsText}
                                                    >
                                                        {languageData.referFriendsText}
                                                    </a>
                                                </div>
                                                <div>
                                                    <a
                                                        className="d-inline-block bold underline mr-2 visitSite orange-background-gradient"
                                                        target="_blank"
                                                        rel="noopener"
                                                        href="review/chaturbate"
                                                        title={
                                                            isEmail ? languageData.getOffer : languageData.visitSiteText
                                                        }
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            mixpanel.track('Visit Site', {
                                                                distinct_id: window.localStorage.getItem('userID'),
                                                                'Site': 'Chaturbate'
                                                            });
                                                            setSelectedSite('chaturbate');
                                                            if (isEmail) {
                                                                setPromoModal(true);
                                                            } else {
                                                                navigate(`/review/chaturbate`)
                                                            }
                                                        }}
                                                    >
                                                        {
                                                            isEmail ? languageData.getOffer : languageData.visitSiteText
                                                        }
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="review-expansion grid hideOnMobile grid-item-2" style={{ display: openedBlock !== 3 ? 'none' : 'block' }}>
                                                <div className="review-bullets grid grid-item-3">
                                                    <div className="review-bullet">
                                                        {languageData.exploreChatText}
                                                    </div>
                                                    <div className="review-bullet">
                                                        {languageData.browseNichesText}
                                                    </div>
                                                    <div className="review-bullet">
                                                        {languageData.fanClubBenefitsText}
                                                    </div>
                                                    <div className="review-bullet">
                                                        {languageData.camsSortedByText}
                                                    </div>
                                                    <div className="review-bullet">
                                                        {languageData.officialMerchandiseText}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={cn(`review-container`, {
                                        "expanded": openedBlock === 4,
                                    })}
                                        onClick={() => setOpenedBlock(openedBlock === 4 ? null : 4)}
                                        data-site-id="4">

                                        <div className="review-arrow-wrapper hideOnMobile">
                                            <div className="review-arrow"></div>
                                            <div className="review-arrow">
                                                <div className="review-arrow-btn"></div>
                                            </div>
                                            <div className="review-arrow"></div>
                                        </div>
                                        <div className="review-wrapper">
                                            <span className="review-number hideOnMobile review-number-2">
                                                4
                                            </span>
                                            <div className="review-main">
                                                <img
                                                    className="visit-site-gradient hideOnMobile"
                                                    src={visitGradient}
                                                    loading="lazy"
                                                />

                                                <div>
                                                    <a
                                                        className=""
                                                        target="_blank"
                                                        rel="noopener"
                                                        href="review/myfreecams"
                                                        title={
                                                            isEmail ? languageData.getOffer : languageData.visitSiteText
                                                        }
                                                    >
                                                        <img
                                                            className="siteimg"
                                                            src={logo3}
                                                            alt="MyFreeCams.com"
                                                            title="MyFreeCams.com"
                                                            loading="lazy"
                                                        />
                                                    </a>
                                                </div>
                                                <div className="score-div not-scored" data-site-id="6">
                                                    <span className="mikes-score star-icon-4">4.5</span>
                                                    <div className="score-wrap">
                                                        <a
                                                            className="read-more underline"
                                                            href="review/myfreecams"
                                                            title={languageData.readReviewText}
                                                        >
                                                            {languageData.readReviewText}
                                                        </a>
                                                    </div>
                                                </div>
                                                <div>
                                                    <a
                                                        className="btn-default text-center  no-underline"
                                                        href="review/myfreecams"
                                                        target="_blank"
                                                        rel="noopener"
                                                        title={languageData.getRewardPointsText}
                                                    >
                                                        {languageData.getRewardPointsText}
                                                    </a>
                                                </div>
                                                <div>
                                                    <a
                                                        className="d-inline-block bold underline mr-2 visitSite orange-background-gradient"
                                                        target="_blank"
                                                        rel="noopener"
                                                        href="review/myfreecams"
                                                        title={
                                                            isEmail ? languageData.getOffer : languageData.visitSiteText
                                                        }
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            mixpanel.track('Visit Site', {
                                                                distinct_id: window.localStorage.getItem('userID'),
                                                                'Site': 'MyFreeCams'
                                                            });

                                                            setSelectedSite('myfreecams');
                                                            if (isEmail) {
                                                                setPromoModal(true);
                                                            } else {
                                                                navigate(`/review/myfreecams`)
                                                            }
                                                        }}
                                                    >
                                                        {
                                                            isEmail ? languageData.getOffer : languageData.visitSiteText
                                                        }
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="review-expansion grid hideOnMobile grid-item-2" style={{ display: openedBlock !== 4 ? 'none' : 'block' }}>
                                                <div className="review-bullets grid grid-item-3">
                                                    <div className="review-bullet">
                                                        {languageData.enjoyAccessText}
                                                    </div>
                                                    <div className="review-bullet">
                                                        {languageData.watchShowsText}
                                                    </div>
                                                    <div className="review-bullet">
                                                        {languageData.multiplyPleasureText}
                                                    </div>
                                                    <div className="review-bullet">
                                                        {languageData.customizeInterfaceText}
                                                    </div>
                                                    <div className="review-bullet">
                                                        {languageData.saveMemoriesText}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={cn(`review-container`, {
                                        "expanded": openedBlock === 5,
                                    })}
                                        onClick={() => setOpenedBlock(openedBlock === 5 ? null : 5)}
                                        data-site-id="4">
                                        <div className="review-arrow-wrapper hideOnMobile">
                                            <div className="review-arrow"></div>
                                            <div className="review-arrow">
                                                <div className="review-arrow-btn"></div>
                                            </div>
                                            <div className="review-arrow"></div>
                                        </div>
                                        <div className="review-wrapper">
                                            <span className="review-number hideOnMobile review-number-4">
                                                5
                                            </span>
                                            <div className="review-main">
                                                <img
                                                    className="visit-site-gradient hideOnMobile"
                                                    src={visitGradient}
                                                    loading="lazy"
                                                />

                                                <div>
                                                    <a
                                                        className=""
                                                        target="_blank"
                                                        rel="noopener"
                                                        href="review/xlovecam"
                                                        title={
                                                            isEmail ? languageData.getOffer : languageData.visitSiteText
                                                        }
                                                    >
                                                        <img
                                                            className="siteimg"
                                                            src={logo5}
                                                            alt="XLoveCam.com"
                                                            title="XLoveCam.com"
                                                            loading="lazy"
                                                        />
                                                    </a>
                                                </div>
                                                <div className="score-div not-scored" data-site-id="14">
                                                    <span className="mikes-score star-icon-4">4.3</span>
                                                    <div className="score-wrap">
                                                        <a
                                                            className="read-more underline"
                                                            href="review/xlovecam"
                                                            title={languageData.readReviewText}
                                                        >
                                                            {languageData.readReviewText}
                                                        </a>
                                                    </div>
                                                </div>
                                                <div>
                                                    <a
                                                        className="btn-default text-center  no-underline"
                                                        href="review/xlovecam"
                                                        target="_blank"
                                                        rel="noopener"
                                                        title={languageData.freeMembershipText}
                                                    >
                                                        {languageData.freeMembershipText}
                                                    </a>
                                                </div>
                                                <div>
                                                    <a
                                                        className="d-inline-block bold underline mr-2 visitSite orange-background-gradient"
                                                        target="_blank"
                                                        rel="noopener"
                                                        href="review/xlovecam"
                                                        title={
                                                            isEmail ? languageData.getOffer : languageData.visitSiteText
                                                        }
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            mixpanel.track('Visit Site', {
                                                                distinct_id: window.localStorage.getItem('userID'),
                                                                'Site': 'XLoveCam'

                                                            });
                                                            setSelectedSite('xlovecam');
                                                            if (isEmail) {
                                                                setPromoModal(true);
                                                            } else {
                                                                navigate(`/review/xlovecam`)
                                                            }
                                                        }}
                                                    >
                                                        {
                                                            isEmail ? languageData.getOffer : languageData.visitSiteText
                                                        }
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="review-expansion grid hideOnMobile grid-item-2" style={{ display: openedBlock !== 5 ? 'none' : 'block' }}>
                                                <div className="review-bullets grid grid-item-3">
                                                    <div className="review-bullet">
                                                        {languageData.watchVideosText}
                                                    </div>
                                                    <div className="review-bullet">
                                                        {languageData.enjoyShowsText}
                                                    </div>
                                                    <div className="review-bullet">
                                                        {languageData.gainAccessText}
                                                    </div>
                                                    <div className="review-bullet">
                                                        {languageData.accessStatisticsText}
                                                    </div>
                                                    <div className="review-bullet">
                                                        {languageData.revisitModelsText}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>




                                    <div className={cn(`review-container`, {
                                        "expanded": openedBlock === 6,
                                    })}
                                        onClick={() => setOpenedBlock(openedBlock === 6 ? null : 6)}
                                        data-site-id="4">
                                        <div className="review-arrow-wrapper hideOnMobile">
                                            <div className="review-arrow"></div>
                                            <div className="review-arrow">
                                                <div className="review-arrow-btn"></div>
                                            </div>
                                            <div className="review-arrow"></div>
                                        </div>
                                        <div className="review-wrapper">
                                            <span className="review-number hideOnMobile review-number-5">
                                                6
                                            </span>
                                            <div className="review-main">
                                                <img
                                                    className="visit-site-gradient hideOnMobile"
                                                    src={visitGradient}
                                                    loading="lazy"
                                                />

                                                <div>
                                                    <a
                                                        className=""
                                                        target="_blank"
                                                        rel="noopener"
                                                        href="review/flirt4free"
                                                        title={
                                                            isEmail ? languageData.getOffer : languageData.visitSiteText
                                                        }
                                                        data-key-id="1"
                                                    >
                                                        <img
                                                            className="siteimg"
                                                            src={logo6}
                                                            alt="Flirt4Free.com"
                                                            title="Flirt4Free.com"
                                                            loading="lazy"
                                                        />
                                                    </a>
                                                </div>
                                                <div className="score-div not-scored" data-site-id="12">
                                                    <span className="mikes-score star-icon-4">4.1</span>
                                                    <div className="score-wrap">
                                                        <a
                                                            className="read-more underline"
                                                            href="review/flirt4free"
                                                            title={languageData.readReviewText}
                                                            data-key-id="2"
                                                        >
                                                            {languageData.readReviewText}
                                                        </a>
                                                    </div>
                                                </div>
                                                <div>
                                                    <a
                                                        className="btn-default text-center  no-underline"
                                                        href="review/flirt4free"
                                                        target="_blank"
                                                        rel="noopener"
                                                        title={languageData.bonusCreditsText}
                                                        data-key-id="3"
                                                    >
                                                        {languageData.bonusCreditsText}
                                                    </a>
                                                </div>
                                                <div>
                                                    <a
                                                        className="d-inline-block bold underline mr-2 visitSite orange-background-gradient"
                                                        target="_blank"
                                                        rel="noopener"
                                                        href="review/flirt4free"
                                                        title={
                                                            isEmail ? languageData.getOffer : languageData.visitSiteText
                                                        }
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            mixpanel.track('Visit Site', {
                                                                distinct_id: window.localStorage.getItem('userID'),
                                                                'Site': 'Flirt4Free'

                                                            });
                                                            setSelectedSite('flirt4free');
                                                            if (isEmail) {
                                                                setPromoModal(true);
                                                            } else {
                                                                navigate(`/review/flirt4free`)
                                                            }
                                                        }}
                                                        data-key-id="4"
                                                    >
                                                        {
                                                            isEmail ? languageData.getOffer : languageData.visitSiteText
                                                        }
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="review-expansion grid hideOnMobile grid-item-2" style={{ display: openedBlock !== 6 ? 'none' : 'block' }}>
                                                <div className="review-bullets grid grid-item-3">
                                                    <div className="review-bullet">
                                                        {languageData.interactiveModelsText}
                                                    </div>
                                                    <div className="review-bullet">
                                                        {languageData.accessVIPMembershipText}
                                                    </div>
                                                    <div className="review-bullet">
                                                        {languageData.browseTopFlirtsText}
                                                    </div>
                                                    <div className="review-bullet">
                                                        {languageData.watchPreRecordedVideosText}
                                                    </div>
                                                    <div className="review-bullet">
                                                        {languageData.trackModelsScheduleText}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    {
                                        showMore === false && <div className="show-more-reviews" onClick={e => setShowMore(true)}>Show More camsites</div>
                                    }

                                    {
                                        showMore === true && <div>
                                            <div className={cn(`review-container`, {
                                                "expanded": openedBlock === 7,
                                            })}
                                                onClick={() => setOpenedBlock(openedBlock === 7 ? null : 7)}
                                                data-site-id="4">
                                                <div className="review-arrow-wrapper hideOnMobile">
                                                    <div className="review-arrow"></div>
                                                    <div className="review-arrow">
                                                        <div className="review-arrow-btn"></div>
                                                    </div>
                                                    <div className="review-arrow"></div>
                                                </div>
                                                <div className="review-wrapper">
                                                    <span className="review-number hideOnMobile review-number-6">
                                                        7
                                                    </span>
                                                    <div className="review-main">
                                                        <img
                                                            className="visit-site-gradient hideOnMobile"
                                                            src={visitGradient}
                                                            loading="lazy"
                                                        />

                                                        <div>
                                                            <a
                                                                className=""
                                                                target="_blank"
                                                                rel="noopener"
                                                                href="review/cams"
                                                                title={
                                                            isEmail ? languageData.getOffer : languageData.visitSiteText
                                                        }
                                                            >
                                                                <img
                                                                    className="siteimg"
                                                                    src={logo7}
                                                                    alt="Cams.com"
                                                                    title="Cams.com"
                                                                    loading="lazy"
                                                                />
                                                            </a>
                                                        </div>
                                                        <div className="score-div not-scored" data-site-id="9">
                                                            <span className="mikes-score star-icon-4">3.9</span>
                                                            <div className="score-wrap">
                                                                <a
                                                                    className="read-more underline"
                                                                    href="review/cams"
                                                                    title={languageData.readReviewText}
                                                                >
                                                                    {languageData.readReviewText}
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <a
                                                                className="btn-default text-center  no-underline"
                                                                href="review/cams"
                                                                target="_blank"
                                                                rel="noopener"
                                                                title={languageData.freeCreditsText}
                                                            >
                                                                {languageData.freeCreditsText}
                                                            </a>
                                                        </div>
                                                        <div>
                                                            <a
                                                                className="d-inline-block bold underline mr-2 visitSite orange-background-gradient"
                                                                target="_blank"
                                                                rel="noopener"
                                                                href="review/cams"
                                                                title={
                                                            isEmail ? languageData.getOffer : languageData.visitSiteText
                                                        }
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    mixpanel.track('Visit Site', {
                                                                        distinct_id: window.localStorage.getItem('userID'),
                                                                        'Site': 'Cams'

                                                                    });
                                                                    setSelectedSite('cams');
                                                                    if (isEmail) {
                                                                        setPromoModal(true);
                                                                    } else {
                                                                        navigate(`/review/cams`)
                                                                    }
                                                                }}
                                                            >
                                                                {
                                                            isEmail ? languageData.getOffer : languageData.visitSiteText
                                                        }
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="review-expansion grid hideOnMobile grid-item-2" style={{ display: openedBlock !== 7 ? 'none' : 'block' }}>
                                                        <div className="review-bullets grid grid-item-3">
                                                            <div className="review-bullet">
                                                                {languageData.premiumModelsText}
                                                            </div>
                                                            <div className="review-bullet">
                                                                {languageData.cam2camText}
                                                            </div>
                                                            <div className="review-bullet">
                                                                {languageData.topFlirtsText}
                                                            </div>
                                                            <div className="review-bullet">
                                                                {languageData.highQualityShowsText}
                                                            </div>
                                                            <div className="review-bullet">
                                                                {languageData.trackSchedulesText}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>






                                            <div className={cn(`review-container`, {
                                                "expanded": openedBlock === 8,
                                            })}
                                                onClick={() => setOpenedBlock(openedBlock === 8 ? null : 8)}
                                                data-site-id="4">
                                                <div className="review-arrow-wrapper hideOnMobile">
                                                    <div className="review-arrow"></div>
                                                    <div className="review-arrow">
                                                        <div className="review-arrow-btn"></div>
                                                    </div>
                                                    <div className="review-arrow"></div>
                                                </div>
                                                <div className="review-wrapper">
                                                    <span className="review-number hideOnMobile review-number-7">
                                                        8
                                                    </span>
                                                    <div className="review-main">
                                                        <img
                                                            className="visit-site-gradient hideOnMobile"
                                                            src={visitGradient}
                                                            loading="lazy"
                                                        />

                                                        <div>
                                                            <a
                                                                className=""
                                                                target="_blank"
                                                                rel="noopener"
                                                                href="review/mydirtyhobby"
                                                                title={
                                                            isEmail ? languageData.getOffer : languageData.visitSiteText
                                                        }
                                                            >
                                                                <img
                                                                    className="siteimg"
                                                                    src={logo8}
                                                                    alt="MyDirtyHobby.com"
                                                                    title="MyDirtyHobby.com"
                                                                    loading="lazy"
                                                                />
                                                            </a>
                                                        </div>
                                                        <div className="score-div not-scored" data-site-id="34">
                                                            <span className="mikes-score star-icon-4">3.9</span>
                                                            <div className="score-wrap">
                                                                <a
                                                                    className="read-more underline"
                                                                    href="review/mydirtyhobby"
                                                                    title={languageData.readReviewText}
                                                                >
                                                                    {languageData.readReviewText}
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <a
                                                                className="btn-default text-center  no-underline"
                                                                href="review/mydirtyhobby"
                                                                target="_blank"
                                                                rel="noopener"
                                                                title={languageData.freeRegistrationText}
                                                            >
                                                                {languageData.freeRegistrationText}
                                                            </a>
                                                        </div>
                                                        <div>
                                                            <a
                                                                className="d-inline-block bold underline mr-2 visitSite orange-background-gradient"
                                                                target="_blank"
                                                                rel="noopener"
                                                                href="review/mydirtyhobby"
                                                                title={
                                                            isEmail ? languageData.getOffer : languageData.visitSiteText
                                                        }
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    mixpanel.track('Visit Site', {
                                                                        distinct_id: window.localStorage.getItem('userID'),
                                                                        'Site': 'MyDirtyHobby'

                                                                    });
                                                                    setSelectedSite('mydirtyhobby');
                                                                    if (isEmail) {
                                                                        setPromoModal(true);
                                                                    } else {
                                                                        navigate(`/review/mydirtyhobby`)
                                                                    }
                                                                }}
                                                            >
                                                                {
                                                            isEmail ? languageData.getOffer : languageData.visitSiteText
                                                        }
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="review-expansion grid hideOnMobile grid-item-2" style={{ display: openedBlock !== 8 ? 'none' : 'block' }}>
                                                        <div className="review-bullets grid grid-item-3">
                                                            <div className="review-bullet">
                                                                {languageData.freeCreditsText}
                                                            </div>
                                                            <div className="review-bullet">
                                                                {languageData.tonsOfContentText}
                                                            </div>
                                                            <div className="review-bullet">
                                                                {languageData.largestSocialNetworkText}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>







                                            <div className={cn(`review-container`, {
                                                "expanded": openedBlock === 9,
                                            })}
                                                onClick={() => setOpenedBlock(openedBlock === 9 ? null : 9)}
                                                data-site-id="4">
                                                <div className="review-arrow-wrapper hideOnMobile">
                                                    <div className="review-arrow"></div>
                                                    <div className="review-arrow">
                                                        <div className="review-arrow-btn"></div>
                                                    </div>
                                                    <div className="review-arrow"></div>
                                                </div>
                                                <div className="review-wrapper">
                                                    <span className="review-number hideOnMobile review-number-8">
                                                        9
                                                    </span>
                                                    <div className="review-main">
                                                        <img
                                                            className="visit-site-gradient hideOnMobile"
                                                            src={visitGradient}
                                                            loading="lazy"
                                                        />

                                                        <div>
                                                            <a
                                                                className=""
                                                                target="_blank"
                                                                rel="noopener"
                                                                href="review/camcontacts"
                                                                title={
                                                            isEmail ? languageData.getOffer : languageData.visitSiteText
                                                        }
                                                            >
                                                                <img
                                                                    className="siteimg"
                                                                    src={logo9}
                                                                    alt="CamContacts.com"
                                                                    title="CamContacts.com"
                                                                    loading="lazy"
                                                                />
                                                            </a>
                                                        </div>
                                                        <div className="score-div not-scored" data-site-id="18">
                                                            <span className="mikes-score star-icon-4">3.8</span>
                                                            <div className="score-wrap">
                                                                <a
                                                                    className="read-more underline"
                                                                    href="review/camcontacts"
                                                                    title={languageData.readReviewText}
                                                                >
                                                                    {languageData.readReviewText}
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <a
                                                                className="btn-default text-center  no-underline"
                                                                href="review/camcontacts"
                                                                target="_blank"
                                                                rel="noopener"
                                                                title={languageData.freeRegistrationText}
                                                            >
                                                                {languageData.freeRegistrationText}
                                                            </a>
                                                        </div>
                                                        <div>
                                                            <a
                                                                className="d-inline-block bold underline mr-2 visitSite orange-background-gradient"
                                                                target="_blank"
                                                                rel="noopener"
                                                                href="review/camcontacts"
                                                                title={
                                                            isEmail ? languageData.getOffer : languageData.visitSiteText
                                                        }
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    mixpanel.track('Visit Site', {
                                                                        distinct_id: window.localStorage.getItem('userID'),
                                                                        'Site': 'CamContacts'

                                                                    });
                                                                    setSelectedSite('camcontacts');
                                                                    if (isEmail) {
                                                                        setPromoModal(true);
                                                                    } else {
                                                                        navigate(`/review/camcontacts`);
                                                                    }
                                                                }}
                                                            >
                                                                {
                                                            isEmail ? languageData.getOffer : languageData.visitSiteText
                                                        }
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="review-expansion grid hideOnMobile grid-item-2" style={{ display: openedBlock !== 9 ? 'none' : 'block' }}>
                                                        <div className="review-bullets grid grid-item-3">
                                                            <div className="review-bullet">
                                                                {languageData.pickModelsText}
                                                            </div>
                                                            <div className="review-bullet">
                                                                {languageData.browseShowsText}
                                                            </div>
                                                            <div className="review-bullet">
                                                                {languageData.exploreSectionsText}
                                                            </div>
                                                            <div className="review-bullet">
                                                                {languageData.mingleChatText}
                                                            </div>
                                                            <div className="review-bullet">
                                                                {languageData.specialEventsText}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>







                                            <div className={cn(`review-container`, {
                                                "expanded": openedBlock === 10,
                                            })}
                                                onClick={() => setOpenedBlock(openedBlock === 10 ? null : 10)}
                                                data-site-id="4">
                                                <div className="review-arrow-wrapper hideOnMobile">
                                                    <div className="review-arrow"></div>
                                                    <div className="review-arrow">
                                                        <div className="review-arrow-btn"></div>
                                                    </div>
                                                    <div className="review-arrow"></div>
                                                </div>
                                                <div className="review-wrapper">
                                                    <span className="review-number hideOnMobile review-number-9">
                                                        10
                                                    </span>
                                                    <div className="review-main">
                                                        <img
                                                            className="visit-site-gradient hideOnMobile"
                                                            src={visitGradient}
                                                            loading="lazy"
                                                        />

                                                        <div>
                                                            <a
                                                                className=""
                                                                target="_blank"
                                                                rel="noopener"
                                                                href="review/bongacams"
                                                                title={
                                                            isEmail ? languageData.getOffer : languageData.visitSiteText
                                                        }
                                                            >
                                                                <img
                                                                    className="siteimg"
                                                                    src={logo10}
                                                                    alt="BongaCams.com"
                                                                    title="BongaCams.com"
                                                                    loading="lazy"
                                                                />
                                                            </a>
                                                        </div>
                                                        <div className="score-div not-scored" data-site-id="26">
                                                            <span className="mikes-score star-icon-4">3.9</span>
                                                            <div className="score-wrap">
                                                                <a
                                                                    className="read-more underline"
                                                                    href="review/bongacams"
                                                                    title={languageData.readReviewText}
                                                                >
                                                                    {languageData.readReviewText}
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <a
                                                                className="btn-default text-center  no-underline"
                                                                href="review/bongacams"
                                                                target="_blank"
                                                                rel="noopener"
                                                                title={languageData.bonusTokensText}
                                                            >
                                                                {languageData.bonusTokensText}
                                                            </a>
                                                        </div>
                                                        <div>
                                                            <a
                                                                className="d-inline-block bold underline mr-2 visitSite orange-background-gradient"
                                                                target="_blank"
                                                                rel="noopener"
                                                                href="review/bongacams"
                                                                title={
                                                            isEmail ? languageData.getOffer : languageData.visitSiteText
                                                        }
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    mixpanel.track('Visit Site', {
                                                                        distinct_id: window.localStorage.getItem('userID'),
                                                                        'Site': 'BongaCams'

                                                                    });
                                                                    setSelectedSite('bongacams');
                                                                    if (isEmail) {
                                                                        setPromoModal(true);
                                                                    } else {
                                                                        navigate(`/review/bongacams`);
                                                                    }
                                                                }}
                                                            >
                                                                {
                                                            isEmail ? languageData.getOffer : languageData.visitSiteText
                                                        }
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="review-expansion grid hideOnMobile grid-item-2" style={{ display: openedBlock !== 10 ? 'none' : 'block' }}>
                                                        <div className="review-bullets grid grid-item-3">
                                                            <div className="review-bullet">
                                                                {languageData.findModelsText}
                                                            </div>
                                                            <div className="review-bullet">
                                                                {languageData.getTokensText}
                                                            </div>
                                                            <div className="review-bullet">
                                                                {languageData.spendTokensText}
                                                            </div>
                                                            <div className="review-bullet">
                                                                {languageData.spyModeText}
                                                            </div>
                                                            <div className="review-bullet">
                                                                {languageData.giftCardsText}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>





                                            <div className={cn("review-container", {
                                                "expanded": openedBlock === 11,
                                            })}
                                                onClick={() => setOpenedBlock(openedBlock === 11 ? null : 11)}
                                                data-site-id="4">
                                                <img
                                                    className="tag hideOnMobile"
                                                    title={languageData.tagTitleReview}
                                                    src={bestModels}
                                                    loading="lazy"
                                                />
                                                <div className="review-arrow-wrapper hideOnMobile">
                                                    <div className="review-arrow"></div>
                                                    <div className="review-arrow">
                                                        <div className="review-arrow-btn"></div>
                                                    </div>
                                                    <div className="review-arrow"></div>
                                                </div>
                                                <div className="review-wrapper">
                                                    <span className="review-number hideOnMobile review-number-10">
                                                        11
                                                    </span>
                                                    <div className="review-main">
                                                        <img
                                                            className="visit-site-gradient hideOnMobile"
                                                            src={visitGradient}
                                                            loading="lazy"
                                                        />

                                                        <div>
                                                            <a
                                                                className=""
                                                                target="_blank"
                                                                rel="noopener"
                                                                href="review/allcamsex"
                                                                title={languageData.visitSiteLinkTitleReview}
                                                            >
                                                                <img
                                                                    className="siteimg"
                                                                    src={logo11}
                                                                    alt={languageData.siteImgAltReview}
                                                                    title={languageData.siteImgTitleReview}
                                                                    loading="lazy"
                                                                />
                                                            </a>
                                                        </div>
                                                        <div className="score-div not-scored" data-site-id="38">
                                                            <span className="mikes-score star-icon-4">4.3</span>
                                                            <div className="score-wrap">
                                                                <a
                                                                    className="read-more underline"
                                                                    href="review/allcamsex"
                                                                    title={languageData.readMoreTitleReview}
                                                                >
                                                                    {languageData.readReviewText}
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <a
                                                                className="btn-default text-center no-underline"
                                                                href="review/allcamsex"
                                                                target="_blank"
                                                                rel="noopener"
                                                                title={languageData.btnDefaultTitleReview}
                                                            >
                                                                Get $10 worth of Free credits
                                                            </a>
                                                        </div>
                                                        <div>
                                                            <a
                                                                className="d-inline-block bold underline mr-2 visitSite orange-background-gradient"
                                                                target="_blank"
                                                                rel="noopener"
                                                                href="review/allcamsex"
                                                                title={languageData.visitSiteBtnTitleReview}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    mixpanel.track('Visit Site', {
                                                                        distinct_id: window.localStorage.getItem('userID'),
                                                                        'Site': 'AllCamSex'
                                                                    });
                                                                    setSelectedSite('allcamsex');
                                                                    if (isEmail) {
                                                                        setPromoModal(true);
                                                                    } else {
                                                                        navigate(`/review/allcamsex`)
                                                                    }
                                                                }}
                                                            >
                                                                {
                                                            isEmail ? languageData.getOffer : languageData.visitSiteText
                                                        }
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="review-expansion grid hideOnMobile grid-item-2" style={{ display: openedBlock !== 11 ? 'none' : 'block' }}>
                                                        <div className="review-bullets grid grid-item-3">
                                                            <div className="review-bullet">
                                                                {languageData.reviewBullet1Review}
                                                            </div>
                                                            <div className="review-bullet">
                                                                {languageData.reviewBullet2Review}
                                                            </div>
                                                            <div className="review-bullet">
                                                                {languageData.reviewBullet3Review}
                                                            </div>
                                                            <div className="review-bullet">
                                                                {languageData.reviewBullet4Review}
                                                            </div>
                                                            <div className="review-bullet">
                                                                {languageData.reviewBullet5Review}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>





                                            <div className={cn("review-container", {
                                                "expanded": openedBlock === 12,
                                            })}
                                                onClick={() => setOpenedBlock(openedBlock === 12 ? null : 12)}
                                                data-site-id="4">
                                                <div className="review-arrow-wrapper hideOnMobile">
                                                    <div className="review-arrow"></div>
                                                    <div className="review-arrow">
                                                        <div className="review-arrow-btn"></div>
                                                    </div>
                                                    <div className="review-arrow"></div>
                                                </div>
                                                <div className="review-wrapper">
                                                    <span className="review-number hideOnMobile review-number-11">
                                                        12
                                                    </span>
                                                    <div className="review-main">
                                                        <img
                                                            className="visit-site-gradient hideOnMobile"
                                                            src={visitGradient}
                                                            loading="lazy"
                                                        />
                                                        <div>
                                                            <a
                                                                className=""
                                                                target="_blank"
                                                                rel="noopener"
                                                                href="review/exposedwebcams"
                                                                title={languageData.visitSiteLinkTitleReview12}
                                                            >
                                                                <img
                                                                    className="siteimg"
                                                                    src={logo12}
                                                                    alt={languageData.siteImgAltReview12}
                                                                    title={languageData.siteImgTitleReview12}
                                                                    loading="lazy"
                                                                />
                                                            </a>
                                                        </div>
                                                        <div className="score-div not-scored" data-site-id="40">
                                                            <span className="mikes-score star-icon-4">3.8</span>
                                                            <div className="score-wrap">
                                                                <a
                                                                    className="read-more underline"
                                                                    href="review/exposedwebcams"
                                                                    title={languageData.readMoreTitleReview12}
                                                                >
                                                                    {languageData.readReviewText}
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <a
                                                                className="btn-default text-center no-underline"
                                                                href="review/exposedwebcams"
                                                                target="_blank"
                                                                rel="noopener"
                                                                title={languageData.btnDefaultTitleReview12}
                                                            >
                                                                Free chat
                                                            </a>
                                                        </div>
                                                        <div>
                                                            <a
                                                                className="d-inline-block bold underline mr-2 visitSite orange-background-gradient"
                                                                target="_blank"
                                                                rel="noopener"
                                                                href="review/exposedwebcams"
                                                                title={languageData.visitSiteBtnTitleReview12}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    mixpanel.track('Visit Site', {
                                                                        distinct_id: window.localStorage.getItem('userID'),
                                                                        'Site': 'ExposedWebcams'
                                                                    });
                                                                    setSelectedSite('exposedwebcams');
                                                                    if (isEmail) {
                                                                        setPromoModal(true);
                                                                    } else {
                                                                        navigate(`/review/exposedwebcams`)
                                                                    }
                                                                }}
                                                            >
                                                                {
                                                            isEmail ? languageData.getOffer : languageData.visitSiteText
                                                        }
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="review-expansion grid hideOnMobile grid-item-2" style={{ display: openedBlock !== 12 ? 'none' : 'block' }}>
                                                        <div className="review-bullets grid grid-item-3">
                                                            <div className="review-bullet">
                                                                {languageData.reviewBullet1Review12}
                                                            </div>
                                                            <div className="review-bullet">
                                                                {languageData.reviewBullet2Review12}
                                                            </div>
                                                            <div className="review-bullet">
                                                                {languageData.reviewBullet3Review12}
                                                            </div>
                                                            <div className="review-bullet">
                                                                {languageData.reviewBullet4Review12}
                                                            </div>
                                                            <div className="review-bullet">
                                                                {languageData.reviewBullet5Review12}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>







                                            <div className={cn("review-container", {
                                                "expanded": openedBlock === 13,
                                            })}
                                                onClick={() => setOpenedBlock(openedBlock === 13 ? null : 13)}
                                                data-site-id="4">
                                                <div className="review-arrow-wrapper hideOnMobile">
                                                    <div className="review-arrow"></div>
                                                    <div className="review-arrow">
                                                        <div className="review-arrow-btn"></div>
                                                    </div>
                                                    <div className="review-arrow"></div>
                                                </div>
                                                <div className="review-wrapper">
                                                    <span className="review-number hideOnMobile review-number-12">
                                                        13
                                                    </span>
                                                    <div className="review-main">
                                                        <img
                                                            className="visit-site-gradient hideOnMobile"
                                                            src={visitGradient}
                                                            loading="lazy"
                                                        />
                                                        <div>
                                                            <a
                                                                className=""
                                                                target="_blank"
                                                                rel="noopener"
                                                                href="review/cam4"
                                                                title={languageData.visitSiteLinkTitleReview13}
                                                            >
                                                                <img
                                                                    className="siteimg"
                                                                    src={logo13}
                                                                    alt={languageData.siteImgAltReview13}
                                                                    title={languageData.siteImgTitleReview13}
                                                                    loading="lazy"
                                                                />
                                                            </a>
                                                        </div>
                                                        <div className="score-div not-scored" data-site-id="3">
                                                            <span className="mikes-score star-icon-4">3.8</span>
                                                            <div className="score-wrap">
                                                                <a
                                                                    className="read-more underline"
                                                                    href="review/cam4"
                                                                    title={languageData.readMoreTitleReview13}
                                                                >
                                                                    {languageData.readReviewText}
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <a
                                                                className="btn-default text-center no-underline"
                                                                href="review/cam4"
                                                                target="_blank"
                                                                rel="noopener"
                                                                title={languageData.btnDefaultTitleReview13}
                                                            >
                                                                Free 2 day VIP access
                                                            </a>
                                                        </div>
                                                        <div>
                                                            <a
                                                                className="d-inline-block bold underline mr-2 visitSite orange-background-gradient"
                                                                target="_blank"
                                                                rel="noopener"
                                                                href="review/cam4"
                                                                title={languageData.visitSiteBtnTitleReview13}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    mixpanel.track('Visit Site', {
                                                                        distinct_id: window.localStorage.getItem('userID'),
                                                                        'Site': 'Cam4'
                                                                    });
                                                                    setSelectedSite('cam4');
                                                                    if (isEmail) {
                                                                        setPromoModal(true);
                                                                    } else {
                                                                        navigate(`/review/cam4`)
                                                                    }
                                                                }}
                                                            >
                                                                {
                                                            isEmail ? languageData.getOffer : languageData.visitSiteText
                                                        }
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="review-expansion grid hideOnMobile grid-item-2" style={{ display: openedBlock !== 13 ? 'none' : 'block' }}>
                                                        <div className="review-bullets grid grid-item-3">
                                                            <div className="review-bullet">
                                                                {languageData.reviewBullet1Review13}
                                                            </div>
                                                            <div className="review-bullet">
                                                                {languageData.reviewBullet2Review13}
                                                            </div>
                                                            <div className="review-bullet">
                                                                {languageData.reviewBullet3Review13}
                                                            </div>
                                                            <div className="review-bullet">
                                                                {languageData.reviewBullet4Review13}
                                                            </div>
                                                            <div className="review-bullet">
                                                                {languageData.reviewBullet5Review13}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>




                        



                                            <div className={cn("review-container", {
                                                "expanded": openedBlock === 14,
                                            })}
                                                onClick={() => setOpenedBlock(openedBlock === 14 ? null : 14)}
                                                data-site-id="4">
                                                <div className="review-arrow-wrapper hideOnMobile">
                                                    <div className="review-arrow"></div>
                                                    <div className="review-arrow">
                                                        <div className="review-arrow-btn"></div>
                                                    </div>
                                                    <div className="review-arrow"></div>
                                                </div>
                                                <div className="review-wrapper">
                                                    <span className="review-number hideOnMobile review-number-14">
                                                        14
                                                    </span>
                                                    <div className="review-main">
                                                        <img
                                                            className="visit-site-gradient hideOnMobile"
                                                            src={visitGradient}
                                                            loading="lazy"
                                                        />
                                                        <div>
                                                            <a
                                                                className=""
                                                                target="_blank"
                                                                rel="noopener"
                                                                href="review/livecamguru"
                                                                title={languageData.visitSiteLinkTitleReview15}
                                                            >
                                                                <img
                                                                    className="siteimg"
                                                                    src={logo15}
                                                                    alt={languageData.siteImgAltReview15}
                                                                    title={languageData.siteImgTitleReview15}
                                                                    loading="lazy"
                                                                />
                                                            </a>
                                                        </div>
                                                        <div className="score-div not-scored" data-site-id="29">
                                                            <span className="mikes-score star-icon-3">3.0</span>
                                                            <div className="score-wrap">
                                                                <a
                                                                    className="read-more underline"
                                                                    href="review/livecamguru"
                                                                    title={languageData.readMoreTitleReview15}
                                                                >
                                                                    {languageData.readReviewText}
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <a
                                                                className="btn-default text-center no-underline"
                                                                href="review/livecamguru"
                                                                target="_blank"
                                                                rel="noopener"
                                                                title={languageData.btnDefaultTitleReview15}
                                                            >
                                                                {languageData.btnDefaultTitleReview15}
                                                            </a>
                                                        </div>
                                                        <div>
                                                            <a
                                                                className="d-inline-block bold underline mr-2 visitSite orange-background-gradient"
                                                                target="_blank"
                                                                rel="noopener"
                                                                href="review/livecamguru"
                                                                title={languageData.visitSiteBtnTitleReview15}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    mixpanel.track('Visit Site', {
                                                                        distinct_id: window.localStorage.getItem('userID'),
                                                                        'Site': 'LiveCamGuru'
                                                                    });
                                                                    setSelectedSite('livecamguru');
                                                                    if (isEmail) {
                                                                        setPromoModal(true);
                                                                    } else {
                                                                        navigate(`/review/livecamguru`)
                                                                    }
                                                                }}
                                                            >
                                                                {languageData.visitSiteBtnTitleReview15}
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="review-expansion grid hideOnMobile grid-item-2" style={{ display: openedBlock !== 14 ? 'none' : 'block' }}>
                                                        <div className="review-bullets grid grid-item-3">
                                                            <div className="review-bullet">
                                                                {languageData.reviewBullet1Review15}
                                                            </div>
                                                            <div className="review-bullet">
                                                                {languageData.reviewBullet2Review15}
                                                            </div>
                                                            <div className="review-bullet">
                                                                {languageData.reviewBullet3Review15}
                                                            </div>
                                                            <div className="review-bullet">
                                                                {languageData.reviewBullet4Review15}
                                                            </div>
                                                            <div className="review-bullet">
                                                                {languageData.reviewBullet5Review15}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>
                            <FaqHome />
                        </div>
                    </div>
                    {
                        promoModal && <PromoModal open={promoModal} setOpen={setPromoModal} selectedSite={selectedSite} />
                    }
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default Main;
