import React, { useEffect } from 'react'
import styles from '../../styles/Review.js'
import Header from '../../components/Header.jsx'
import Footer from '../../components/Footer.jsx'
import thumbsUp from '../../images/thumbs-up.png'
import thumbsDown from '../../images/thumbs-down.png'
import logo10 from "../../images/logos/533BongaCams.png"
import RecommendedSites from '../../components/RecommendedSites.jsx'
import RedirectingModal from '../../components/RedirectingModal.jsx'
import ReviewSpoiler from '../../components/ReviewSpoiler.jsx'
import { useLanguage } from '../../Helpers/LanguageContext.jsx'

function BongaCams() {
     const [redirecting, setRedirecting] = React.useState(true)
    const link = `https://bongacams8.com/track?c=805820`
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const { languageData } = useLanguage()

    return (
        <body className="lang-en niche-women" data-is-mobile="False">
            <div className="main-wrapper">
                <Header />
                <div class="body-content">
                    {
                        redirecting === true && <RedirectingModal logoRedirect={logo10} link={link} setRedirecting={setRedirecting} />
                    }
                    <div class="container mt-10">
                        <div class="review-main-sticky">

                            <div class="review-main">



                                <div>
                                    <a class="" href={link} target="_blank"
                                        rel="noopener" title="Bonus tokens">
                                        <img class="siteimg" src={logo10} alt="BongaCams.com"
                                            title="BongaCams.com" />
                                    </a>
                                </div>
                                <div class="score-div not-scored" data-site-id="26">
                                    <span class="mikes-score star-icon-4">3.9</span>

                                </div>
                                <div>
                                    <a onClick={e => setRedirecting(true)} style={{cursor: "pointer"}} class=" d-inline-block text-center  no-underline visitSite orange-background-gradient"
                                         target="_blank"
                                        rel="noopener" title="Bonus tokens">
                                        <span>Bonus tokens</span>
                                        <span>visit site</span>
                                    </a>

                                </div>
                            </div>

                        </div>

                        <div class="review-body-and-banner">

                            <RecommendedSites />

                            <div class="review-body">
                                <section id="pros_cons" className='pros-and-cons'>
                                    <div className="pros">
                                        <h1>Pros</h1>
                                        <li>A lively video chat community.</li>
                                        <li>Decent prices with an excellent choice of models.</li>
                                        <li>No extra charges, hidden fees or confusing fine print.</li>
                                        <img className='thumbs' src={thumbsUp} alt="thumbs" />
                                    </div>
                                    <div className="cons">
                                        <h1>{languageData.consTitle}</h1>
                                        <li>The quick search tags (filtering options) are not listed in a vertical list or dropdown menu, making searching more difficult.</li>
                                        <li>The site is hard to navigate.</li>
                                        <li>Many features are revealed only after getting fully acquainted with the entire site.</li>
                                        <img className='thumbs' src={thumbsDown} alt="thumbs" />
                                    </div>
                                </section>
                                <ReviewSpoiler title={'Number of Web Cam Hosts'}>
                                    <section id="hosts">

                                        <p>Even at off-peak times there are around 1800 online chat rooms, which is very impressive.
                                            The site also encourages users to become models, but this calls for a bit of a wild side
                                            and “ready to go action”.</p>

                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Cost'}>
                                    <section id="cost">

                                        <p>I have always appreciated websites that tell you right off the bat how much the video
                                            chatting will cost you. BongaCams goes even further by supplying a detailed and accurate
                                            price list that any user can go through easily. Prices are more than reasonable and
                                            users will get a lot of chatting for the cost of a single cocktail in a bar. Chat per
                                            minute is at a fixed price, not a range, so there is no need to ask models about the
                                            price or wonder if you remembered to check the price before you invited her to private.
                                        </p>
                                        <p>
                                            1 token is between € 0.06 - 0.11, depending on the packages you select. Spy chat costs 7
                                            tokens per minute, group chat is 15 tokens per minute and private video chats go for 30
                                            tokens per minute. A full private chat is the highest priced being around 90 tokens per
                                            minute. You can tip up to 600 tokens and I am sure those models will show you just how
                                            flexible they can be when they are tipped 200 tokens – some actually have a price list
                                            on their profile pages with items like ’Flash boobs: 35 tokens. That’s a sweet deal,
                                            isn’t it?
                                        </p>
                                        <p><b>Credit on VideoChat.com</b></p>
                                        <p>The credit packages on BongaCams are great. You can purchase just a few Euros worth of
                                            tokens, if you want to look around first without spending too much. There are larger
                                            packages too and they are well worth your time and money.</p>
                                        <ul>
                                            <li>18 Tokens for 1.99 € (~0.11 € per token)</li>
                                            <li>60 Tokens for 4.99 € (~0.08 € per token)</li>
                                            <li>140 Tokens for 9.99 € (~0.07 € per token)</li>
                                            <li>370 Tokens for 24.99 € (~0.07 € per token)</li>
                                            <li>800 Tokens for 49.99 € (~0.06 € per token)</li>
                                            <li>1050 Tokens for 65.00 € (~0.06 € per token)</li>
                                            <li>1230 Tokens for 74.99 € (~0.06 € per token)</li>
                                            <li>8400 Tokens for 500.00 € (~0.06 € per token)</li>
                                            <li>16600 Tokens for 990.00 € (~0.06 € per token)</li>
                                            <li>42100 Tokens for 2,500.00 € (~0.06 € per token)</li>

                                        </ul>
                                        <p><b>Payment Methods</b></p>
                                        <p>You can either pay with Visa or MasterCard, with bitcoins, or use the popular SMS payment
                                            option.</p>
                                        <p><b>Payment Processors</b></p>
                                        <ul>
                                            <li>SegPay </li>
                                            <li>Epoch</li>
                                        </ul>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Top Promotions'}>
                                    <section id="promotions">

                                        <ul>
                                            <li>Get 10 free tokens just for signing up</li>
                                            <li>Get up to 5 bonus Tokens on Your First Purchase</li>
                                            <li>Cash Back – Every day the 5 top spenders win up to 200 tokens. </li>
                                            <li>Invite Your friends – If they spend time in paid chat rooms, you win tokens</li>
                                        </ul>

                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Types of Memberships and Benefits'}>
                                    <section id="member_info">

                                        <p>The Bonga Cams motto is: <b>No Monthly Charges, No Hidden Fees, No Shady Business,</b>
                                            and the site stands behind this motto. There are only 2 kinds of membership available on
                                            BongaCams and they are both free.</p>
                                        <p><strong>Basic Membership</strong></p>
                                        <p>The standard BongaCams membership is the first step you need to take in order to start
                                            chatting. You don’t need a credit card or even an email confirmation. Simply sign up and
                                            get going. There are, however, 2 drawbacks to this type of membership</p>
                                        <ol>
                                            <li>You can’t watch shows</li>
                                            <li>A pop-up, urging you to upgrade will often appear on your screen.</li>
                                        </ol>
                                        <p><strong>Cost to Join:</strong> FREE<br /><strong>Information Required: </strong>User
                                            name, email, Password.<br /><strong>Benefits: </strong></p>
                                        <ul>
                                            <li>Free chats</li>
                                            <li>Build your user profile</li>
                                            <li>View models&rsquo; profiles</li>
                                            <li>View models&rsquo; pictures</li>
                                            <li>Access all account settings</li>
                                        </ul>
                                        <p><b>Premium Membership</b></p>
                                        <p>When you get tired of the pop-up window reminding you to upgrade,or simply want to get
                                            closer to the models, it’s time for the upgrade! </p>
                                        <p><strong>Cost to Join:</strong> FREE, just buy tokens in any
                                            amount.<br /><strong>Information Required: </strong>Credit Card
                                            number.<br /><strong>Benefits: </strong></p>
                                        <ul>
                                            <li>Receive up to 5 Tokens bonus on your first purchase</li>
                                            <li>Unlimited free video chat room access</li>
                                            <li>Full screen view</li>
                                            <li>Watch all types of shows – for set prices</li>
                                            <li>Get alerts</li>
                                            <li>Manage a friends List (which is really just a favorite list)</li>
                                            <li>Send unlimited private messages</li>
                                        </ul>

                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Special Features'}>
                                    <section id="features">

                                        <p>BongaCams.com has got some pretty cool features. Here are just a few that are worthwhile
                                            knowing about:</p>
                                        <p><strong>User Features</strong></p>
                                        <ul>
                                            <li>Friend List – Just like a Favorite List.</li>
                                            <li>Money Back – Top daily spenders win some cash back (actually tokens, but you get the
                                                idea)</li>
                                            <li>Invite Your Friends to Join BongaCams and Get Tokens – IF they buy tokens and use
                                                them, of course.</li>
                                            <li>Receive Notifications – when your Friends go online</li>
                                            <li>Manage a Preferred Users list</li>
                                            <li>Send Tips</li>
                                            <li>Follow your own profile feeds and those of your friends</li>
                                            <li>Direct and control live private shows</li>
                                            <li>Choose Cam2cam for a more intimate experience</li>
                                            <li>The weekly BongaCams Queen contest – At the end of every week, the 3 top-earning
                                                performers receive a prize - $1000 for the winner, $200 for the first runner up and
                                                $100 for the second runner up. You can see who won each week, and previous weeks,
                                                this helps see who the most-popular and hopefully the best webcam models are.</li>
                                            <li>The weekly BongaCams Top 15 Contest - At the end of every week, the top 15 models
                                                with the highest number of BongaCams points win a prize. Users can see the lists and
                                                use them to choose better-rated models.
                                            </li>
                                        </ul>
                                        <p><strong>Video Chat Features</strong></p>
                                        <ul>
                                            <li>Cam to Cam</li>
                                            <li>HD Webcams</li>
                                            <li>Very clear audio</li>
                                            <li>Full screen mode</li>
                                        </ul>

                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Hosts Profiles'}>
                                    <section id="profiles">

                                        <p>The host profile pages on BongaCams can be overwhelming due to the massive amount of
                                            infomation, different font types, a pop-up messenger window, different activities where
                                            you can join the fun (control vibratoys, roll the dice, spin the wheel, become the
                                            model’s DJ by adding YouTube links to her playlist, etc.) Plus since BongaCams is a
                                            freemium site, you will run into very explicit activities even as a guest, which can be
                                            distracting – from looking at the prices, for example! </p>
                                        <p>What you will find on every model’s page are as follows:</p>
                                        <ul>
                                            <li>About the model</li>
                                            <li>Her likes and dislikes</li>
                                            <li>Models’ pictures</li>
                                            <li>Appearance and measurements</li>
                                            <li>The model’s friends
                                            </li>
                                        </ul>

                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Navigation'}>
                                    <section id="navigation">

                                        <p>Getting around BonaCams.com is amazingly easy – just follow the top navigation bars for
                                            searching by tags, categories and their online/offline status, plus your own controls:
                                            language, history, your profile, signing up and so on. On the bottom you will find again
                                            the language bar (this time as a list, not a dropdown menu), Support, Contests, and
                                            legal links. </p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Customer Support'}>
                                    <section id="customer-support">

                                        <p>You can contacting the BongaCams support by:</p>
                                        <ul>
                                            <li>Filling in the online help form</li>
                                            <li>Via email to support@bongacams.com</li>
                                            <li>Phone call: (+372) 51 57 177 (English Support)</li>
                                        </ul>
                                        <p><strong>FAQ</strong></p>
                                        <p>The FAQ page is very detail-oriented and extremely helpful. All the topics are very well
                                            covered. </p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Languages'}>
                                    <section id="language">

                                        <p>With 34 languages available, you are very welcome on the site, no matter where you come
                                            from. </p>

                                    </section>
                                </ReviewSpoiler>


                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
            <style>
                {styles}
            </style>


        </body >
    )
}

export default BongaCams
