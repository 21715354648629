import React from "react";
import FAQItem from "./FAQItem";
import { useLanguage } from "../Helpers/LanguageContext";

function FaqHome() {

  const { languageData } = useLanguage();

  return (
    <div className="full-width-container">
      <div className="top-container">
        <div className="faq-and-posts">
          <div className="faq-wrapper">
            <h1 className="gradient-text">{languageData.frequentlyAskedQuestions}</h1>
            <div className="faqs">
              {languageData.homepageFAQ && languageData.homepageFAQ.map((faq, index) => (
                <FAQItem
                  opened={index === 0}
                  question={faq.question}
                  answer={faq.answer}
                  key={index}
                />
              ))}
              <a
                className="read-more-faq gradient-text"
                href="faq/?lang=en&niche=women"
                title="FAQ"
              >
                {languageData.readMoreFAQ}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FaqHome;
