import React from 'react'
import '../styles/Main2.css'
import '../styles/AboutUs.css'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { useLanguage } from '../Helpers/LanguageContext'

function AboutUs() {
    const { languageData } = useLanguage()
    return (
        <div className="about lang-en niche-women" data-is-mobile="False">

            <Header />
            <div className="main-wrapper">

                <div className="body-content">

                    <div className="about-us-wrapper">
                        <div className="page-header">
                            <h1>{languageData?.MikeSays}</h1>
                        </div>
                        <div className="page-content">
                            <h1>{languageData?.TopCamSitesTitle}</h1>
                            <div className="text">
                                <p><b>{languageData?.MikeBestOffers}</b> </p>
                                <p><b>{languageData?.ReviewsTopWebcamSites}</b></p>
                                <p>{languageData?.AboutUsContent1}</p>
                                <p>{languageData?.AboutUsContent2}</p>
                                <p>{languageData?.AboutUsContent3}</p>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <Footer />

        </div>
    )
}

export default AboutUs
