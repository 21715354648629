import React, { useEffect } from 'react'
import styles from '../../styles/Review.js'
import Header from '../../components/Header.jsx'
import Footer from '../../components/Footer.jsx'
import thumbsUp from '../../images/thumbs-up.png'
import thumbsDown from '../../images/thumbs-down.png'
import logo5 from "../../images/logos/843Xlovecam.png"
import RecommendedSites from '../../components/RecommendedSites.jsx'
import RedirectingModal from '../../components/RedirectingModal.jsx'
import ReviewSpoiler from '../../components/ReviewSpoiler.jsx'
import { useLanguage } from '../../Helpers/LanguageContext.jsx'

function XLoveCam() {
     const [redirecting, setRedirecting] = React.useState(true)
    const link = `https://www.xlovecam.com/prm03/textlink?id_affilie=764&p=online&tri=10&trac=RonMike`

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const { languageData } = useLanguage()
    return (
        <body className="lang-en niche-women" data-is-mobile="False">
            <div className="main-wrapper">
                <Header />
                <div class="body-content">
                    {
                        redirecting === true && <RedirectingModal link={link} setRedirecting={setRedirecting} logoRedirect={logo5} />
                    }
                    <div class="container mt-10">
                        <div class="review-main-sticky">
                            <div class="review-main">
                                <div>
                                    <a class=""
                                        href={link}
                                        target="_blank" rel="noopener" title="Free membership">
                                        <img class="siteimg" src={logo5} alt="XLoveCam.com"
                                            title="XLoveCam.com" />
                                    </a>
                                </div>
                                <div class="score-div not-scored" data-site-id="14">
                                    <span class="mikes-score star-icon-4">4.3</span>

                                </div>
                                <div>
                                    <a onClick={e => setRedirecting(true)} style={{cursor: "pointer"}} class=" d-inline-block text-center  no-underline visitSite orange-background-gradient"
                                        
                                        target="_blank" rel="noopener" title="Free membership">
                                        <span>Free membership</span>
                                        <span>visit site</span>
                                    </a>

                                </div>
                            </div>

                        </div>

                        <div class="review-body-and-banner">
                            <RecommendedSites />


                            <div class="review-body">

                                <section id="pros_cons" className='pros-and-cons'>
                                    <div className="pros">
                                        <h1>Pros</h1>
                                        <li>Lots of features are available on the home page</li>
                                        <li>Reasonable model rates</li>
                                        <li>Choose 1 premium video for free upon sign up</li>
                                        <img className='thumbs' src={thumbsUp} alt="thumbs" />
                                    </div>
                                    <div className="cons">
                                        <h1>{languageData.consTitle}</h1>
                                        <li>Outdated UI design</li>
                                        <li>Significantly smaller model base than competition</li>
                                        <img className='thumbs' src={thumbsDown} alt="thumbs" />
                                    </div>
                                </section>
                                <ReviewSpoiler title={'Summary'}>
                                    <section id="summary">
                                        <p>XLoveCam is one of the most popular adult webcam sites out there that offers a wide range
                                            of services for adult users. Having started in 2006, the site enjoys an excellent online
                                            reputation and has around 80,00 beautiful performers registered on the platform.</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Number of XLoveCam Hosts'}>
                                    <section id="hosts">
                                        <p>As a European-based site, the representation of local models is a strong point of
                                            XloveCam.com. With performers speaking English, German, Spanish, French, Dutch, Italian,
                                            and Portuguese, the chances of you finding someone who speaks your language are high.
                                        </p>
                                        <p>There are an average of 400 models online at all times. In contrast, popular competitors
                                            like LiveJasmin have approximately three times as many models to choose from.</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Cost'}>
                                    <section id="Cost">

                                        <p>Show prices are directly set by the performers, and you can only pay through private
                                            chat. Choosing the public chat will cost you nothing as the chats are free, even for
                                            guests.</p>
                                        <p>Private chat is controlled by the performer. Users are given access to the cam2cam
                                            feature, but the model has the discretion of whether to use toys or not. There is also
                                            the option to tip the models, which is encouraged.</p>
                                        <p>The following credit card packages are available:</p>
                                        <p>$10 = 10 credits</p>
                                        <p>$25 = 25 credits</p>
                                        <p>$50 = 50 credits</p>
                                        <p>$100 = 100 credits</p>
                                        <p>As mentioned earlier, hosts will set their own price, and sometimes you get to chat for
                                            as little as $0.65 for a minute. Chat rates usually range from $1 to $3 per minute,
                                            which makes private chat much more affordable than other sites. In case you wish to tip
                                            a performer, then you will have to buy credits, but remember XLoveCam is free to join.
                                        </p>
                                        <p>In addition to that, they also offer a VIP subscription. VIP subscriptions are similar to
                                            a "fan club" type subscription and are available for models who have chosen to
                                            participate. With them you get benefits such as access to special VIP shows (at no
                                            additional cost), access to recorded videos, and more. The cost for VIP subscriptions
                                            vary per model, and are set by the models themselves. Essentially, it&rsquo;s an add-on
                                            purchase that can be managed separately.</p>
                                        <p>The Payment options on the website include Paypal, Credit Card, Paysafe card, Digital
                                            currency (crypto), Bank transfer.</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Top Promotions'}>
                                    <section id="promotions">

                                        <p>There are currently no promotions available through XLoveCam. However, you are not
                                            required to provide credit card information in order to register with their site. As
                                            soon as your registration is complete, you&rsquo;ll receive a VIP video from a model of
                                            your choice.</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Types of Memberships'}>
                                    <section id="memberships">

                                        <p>XLoveCam also doesn&rsquo;t categorize memberships - they keep things simple and straight
                                            to the point. All you need to do is register on the site and begin paying per minute for
                                            the chat. Additionally, you can choose a VIP subscription for which the rate will vary
                                            depending on the performer.</p>
                                        <p>If you would like to become a member, click on the sign-up tab and enter your username,
                                            password, and email address. Once that is done, click on the legal age box and then
                                            finally click the "Join now free" button.</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Special Features'}>
                                    <section id="features">

                                        <ul>
                                            <li>You get a free VIP video as part of your registration.</li>
                                            <li>Be notified when performers from your &lsquo;favorites&rsquo; list are online.</li>
                                            <li>Cam2cam feature provides a two-way experience.</li>
                                            <li>An array of HD quality cams.</li>
                                            <li>A few seconds peek behind the curtain of truly private shows, called Xclusive Sneak
                                                Peek (rather than a full-fledged, pay spy or voyeur mode)</li>
                                            <li>There are over 80,000 registered adult webcam models.</li>
                                            <li>An enormously diverse portfolio of models.</li>
                                            <li>Three types of chat options. One is a free video chat, which is absolutely free for
                                                everyone. Second is the private video chat, where the performer sets her own price
                                                per minute. And the last is the Xclusive video chat. Here no one will be able to
                                                enter your chat room. It will be only you and the host you have selected.</li>
                                            <li>Having a VIP subscription will upgrade your private chat to "Xclusive" chat free of
                                                charge.</li>
                                            <li>Tip via the "Bonus" feature from 1 to 10 credits.</li>
                                            <li>Controllable, interactive sex toys (used differently depending on model).</li>
                                            <li>All registered users have access to free email messages on the site.</li>
                                            <li>A quality mobile version.</li>
                                            <li>Free image galleries.</li>
                                        </ul>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Hosts Profile'}>
                                    <section id="Hostsprofile">

                                        <p>Clicking on a top performer will automatically take you to their live video stream,
                                            complete with comments from other users. Located above are tabs that provide more
                                            information on the model. For example, the model will provide pricing details for all
                                            the shows they are available for, such as private shows, Xclusive shows, and Sneak Peek
                                            rates. Additionally, you will see how they fall into different categories. All of the
                                            hosts will fall into one category or another; which will typically be their age group.
                                            Next, you will see the language they speak. Lastly, you can also find out when they last
                                            logged in and even when they signed up.</p>
                                        <p>If you&rsquo;re lucky, the host will have pre-recorded videos available on their profile.
                                            On top of that, can also find the schedule page where you will find dates and times the
                                            host will be available online in order to not miss the show.</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Navigation'}>
                                    <section id="Navigation">

                                        <p>Since the site’s main principle is to keep it simple and to the point, you won&rsquo;t
                                            get lost with confusing features. Hundreds of hosts are listed on here, with
                                            self-explanatory profiles, advanced search features, Help, Contact, languages, and a
                                            very important Dictionary that can answer questions like - what is Pretzel dip? Or
                                            Motorboating? You will find all the answers to the most mysterious industry jargon, as
                                            well as a list of hosts performing these kinky acts.</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Customer Support'}>
                                    <section id="support">

                                        <p>There is a dedicated FAQ page on XLoveCam that will answer almost all of your questions.
                                            XLoveCam also has a dedicated customer support team that is quick to respond, and always
                                            very helpful. To contact them simply leave your username, email, type in your message
                                            and someone will be in touch with you shortly. Alternatively, you can use their live
                                            chat feature or send an email, as they have a public email address on their contact
                                            page.</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Languages'}>
                                    <section id="Languages">

                                        <p>The hosts on XLoveCam speak many languages, including English, French, Spanish, German,
                                            Italian, Portuguese, and Dutch.</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Bottom Line'}>
                                    <section id="BottomLine">

                                        <p>For an affordable adult webcam experience, XLoveCam has been one of the industry leaders
                                            for well over a decade now. The site is free from bugs, and the video chat rooms run
                                            smoothly. At this price, you should not skip it. This is the perfect place to start with
                                            if you are new to the world of adult webcams.</p>
                                    </section>
                                </ReviewSpoiler>



                            </div>
                        </div>

                    </div>
                    <Footer />
                </div>
            </div>
            <style>
                {styles}
            </style>


        </body>
    )
}

export default XLoveCam
