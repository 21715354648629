import React, { useEffect } from 'react'
import styles from '../../styles/Review.js'
import Header from '../../components/Header.jsx'
import Footer from '../../components/Footer.jsx'
import bestModels from '../../images/best-variety-of-models.png'
import thumbsUp from '../../images/thumbs-up.png'
import thumbsDown from '../../images/thumbs-down.png'
import logo11 from "../../images/logos/745allcamsex.png"
import RecommendedSites from '../../components/RecommendedSites.jsx'
import RedirectingModal from '../../components/RedirectingModal.jsx'
import ReviewSpoiler from '../../components/ReviewSpoiler.jsx'
import { useLanguage } from '../../Helpers/LanguageContext'

function AllCamSex() {

     const [redirecting, setRedirecting] = React.useState(true)
    const link = `https://allcamsex.com/`

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const { languageData } = useLanguage()

    return (
        <body className="lang-en niche-women" data-is-mobile="False">
            <div className="main-wrapper">
                <Header />
                <div class="body-content">
                    {
                        redirecting === true && <RedirectingModal link={link} setRedirecting={setRedirecting} logoRedirect={logo11} />
                    }
                    <div class="container mt-10">
                        <div class="review-main-sticky">

                            <div class="review-main">
                                <img class="tag" title="Best Variety of Models"
                                    src={bestModels} />



                                <div>
                                    <a class="" href="https://allcamsex.com/" target="_blank" rel="noopener"
                                        title="Get $10 worth of Free credits">
                                        <img class="siteimg" src={logo11} alt="allcamsex.com"
                                            title="allcamsex.com" />
                                    </a>
                                </div>
                                <div class="score-div not-scored" data-site-id="38">
                                    <span class="mikes-score star-icon-4">3.8</span>

                                </div>
                                <div>
                                    <a onClick={e => setRedirecting(true)} style={{cursor: "pointer"}} class=" d-inline-block text-center  no-underline visitSite orange-background-gradient"
                                         target="_blank" rel="noopener"
                                        title="Get $10 worth of Free credits">
                                        <span>Get $10 worth of Free credits</span>
                                        <span>visit site</span>
                                    </a>

                                </div>
                            </div>

                        </div>

                        <div class="review-body-and-banner">
                            <RecommendedSites />


                            <div class="review-body">
                                <section id="pros_cons" className='pros-and-cons'>
                                    <div className="pros">
                                        <h1>Pros</h1>
                                        <li>A very professional and easy to use look and feel.</li>
                                        <li>User friendly interface easy to navigate through.</li>
                                        <li>A large number of sex cam models in various categories with easy search options.</li>
                                        <img className='thumbs' src={thumbsUp} alt="thumbs" />
                                    </div>
                                    <div className="cons">
                                        <h1>{languageData.consTitle}</h1>
                                        <li>The introduction & guide text are placed at the bottom of the home page.</li>
                                        <li>Looks unstructured and very hard to read.</li>
                                        <img className='thumbs' src={thumbsDown} alt="thumbs" />
                                    </div>
                                </section>
                                <ReviewSpoiler title={'Number of Hosts'}>
                                    <section id="hosts">
                                        
                                        <p>There are hundreds of models on Allcamsex at all times, and it is very easy to search
                                            among them, too. Use one or more of the many filters on the left panel on the main page,
                                            and enjoy!</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Cost'}>
                                    <section id="cost">
                                        
                                        <p>As is the case with most adult cam sites, you can join absolutely free of cost but then
                                            your involvement will be quite limited with the models. </p>
                                        <p>
                                            Once you buy your first credit package, you become a Bronze member with some special
                                            privileges. Here are the packages and their costs:</p>

                                        <p> 25 + 10 bonus credits: 25 EUR</p>

                                        <p> 50 + 10 bonus credits: 50 EUR</p>

                                        <p> 100 + 10 bonus credits: 100 EUR </p>

                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Top Promotions'}>
                                    <section id="promotions">
                                        
                                        <p>The models set their own prices which can range between 1.98 to 5.80 credits per minute.
                                            You can enjoy a few discounts, one coming directly from the models who can agree to
                                            lower their prices by 0.20 - 0.40 credits per minute; or by paying upfront for a 30 or a
                                            60 minute private chat and getting a 20% or a 30% discount, respectively.</p>
                                        <p>
                                            You can have bonuses as well, although you might not know about them until you get them:
                                            you simply need to spend enough to reach the bonus, and then you will be notified and
                                            use your bonus in private chats or at Vibe shows.
                                        </p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Membership'}>
                                    <section id="member_info">
                                        
                                        <p>Once you have signed up, you already get access to many perks:</p>
                                        <ul>
                                            <li>uninterrupted live chat sessions</li>
                                            <li>all the models’ uncensored video and image galleries</li>
                                            <li>free live chats, private chats and vibrator (or vibe) shows</li>
                                            <li>recorded shows</li>
                                            <li>exclusive promos</li>
                                            <li>you can add models to your favorite list and you can follow up their activities</li>
                                            <li>watch free porn star shows</li>
                                            <li>Rewards Program membership</li>

                                        </ul>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Special Features'}>
                                    <section id="special-features">
                                       
                                        <p>The site does not offer a wide range of special features, but the ones you will find here
                                            are equally entertaining.</p>

                                        <ul>
                                            <li>You can also see models using vibrators very intimately by using its two features
                                                Play the Toy’ and vibe show’.</li>
                                            <li>Grab 10 extra credits when you sign up and make a first purchase, that allows you to
                                                at least look around and have a taste</li>

                                        </ul>
                                    </section>
                                </ReviewSpoiler>

                                <ReviewSpoiler title={'Profiles'}>
                                    <section id="profiles">
                                        
                                        <p>
                                            The model profiles are clean and provide only the very necessary information so you can
                                            focus on the real action.
                                        </p>
                                        <ul>
                                            <li>You can message, gift, tip and start a cam2cam with the models. You can use emojis
                                                in the chat area, and take a sneak peek at their media, but to enjoy all of it, you
                                                will certainly need to spend some credits. Most photos are hidden on the models’
                                                pages.</li>
                                            <li>You can control the intensity of the models’ vibrators or smaller vibratoys on 4
                                                different levels: Intense, Power, Turbo and Monster vibe.</li>
                                            <li>You can read the models’ bios, add them to your favorites, start a private chat,
                                                attend a Candy Show (a themed group show with a preview and the real thing), and
                                                watch the models’ videos (pre-recorded private shows).</li>

                                        </ul>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Site Navigation'}>
                                    <section id="navigation">
                                        
                                        <p>Since the site is not wildly complicated, everything you need is either on the main page
                                            or under My profile once you have signed up. You can look up answers to your questions
                                            at the Customer Support Center, the same place where you can also connect with the
                                            Support. </p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Customer Service'}>
                                    <section id="customer_support">
                                        
                                        <p>You can contact them in the Customer Support Center and in the Contact option, but the
                                            latter will just send an email. </p>
                                    </section>
                                </ReviewSpoiler>



                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
            <style>
                {styles}
            </style>


        </body >
    )
}

export default AllCamSex
