import React, { useEffect, useState } from 'react'
import styles from '../../styles/Review.js'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import bestDeal from '../../images/best-deal.png'
import logo1 from '../../images/logos/975imlive_logo.png'
import hours from '../../images/72hours.png'
import list from '../../images/list.png'
import support from '../../images/support.png'
import thumbsUp from '../../images/thumbs-up.png'
import thumbsDown from '../../images/thumbs-down.png'
import RecommendedSites from '../../components/RecommendedSites.jsx'
import ReviewSpoiler from '../../components/ReviewSpoiler.jsx'
import { useLanguage } from '../../Helpers/LanguageContext'
import mixpanel from 'mixpanel-browser'
import RedirectingModal from '../../components/RedirectingModal.jsx'

function ImLive() {
    const { languageData } = useLanguage();
    const [redirecting, setRedirecting] = useState(true);
    const [distinct_id, setDistinct_id] = useState('');
    const link = `https://imlive.com/wmaster2.ashx?WID=126673921440&LinkID=701&QueryID=2021&from=freevideo6&promocode=${distinct_id}`

    useEffect(() => {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN)

        const distinct_id = window.localStorage.getItem('userID')
        setDistinct_id(distinct_id);

    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <body className="lang-en niche-women" data-is-mobile="False">
            <div className="main-wrapper">
                <Header />
                <div className="body-content">
                    {
                        redirecting === true && <RedirectingModal link={link} setRedirecting={setRedirecting} logoRedirect={logo1} />
                    }

                    <div className="container mt-10">
                        <div className="review-main-sticky">

                            <div className="review-main">
                                <img className="tag" title="Best Deal" src={bestDeal} />
                                <div>
                                    <a
                                        href={`https://imlive.com/wmaster2.ashx?WID=126673921440&LinkID=701&QueryID=2021&from=freevideo6&promocode=${distinct_id}`}
                                        target="_blank" rel="noopener" title="Get up to 100 free credits on your 1st purchase">
                                        <img className="siteimg" src={logo1} alt="imLive.com"
                                            title="imLive.com" />
                                    </a>
                                </div>
                                <div className="score-div not-scored" data-site-id="4">
                                    <span className="mikes-score star-icon-5">4.7</span>

                                </div>
                                <div>
                                    <a onClick={e => setRedirecting(true)} style={{cursor: "pointer"}} className=" d-inline-block text-center  no-underline visitSite orange-background-gradient"
                                        target="_blank" rel="noopener" title="Get up to 100 free credits on your 1st purchase">
                                        <span>{languageData.firstPurchaseCreditsText}</span>
                                        <span>{languageData.visitSiteText}</span>
                                    </a>

                                </div>
                            </div>

                        </div>

                        <div className="review-body-and-banner">
                            <RecommendedSites />


                            <div className="review-body">
                                <section id="pros_cons" className='pros-and-cons'>
                                    <div className="pros">
                                        <h1>{languageData.prosTitle}</h1>
                                        <li>{languageData.probablyLargest}</li>
                                        <li>{languageData.easyToNavigate}</li>
                                        <li>{languageData.supportedOnMobile}</li>
                                        <li>{languageData.wideSelectionOfHosts}</li>
                                        <li>{languageData.specialFeatures}</li>
                                        <li>{languageData.innovativeSharescreen}</li>
                                        <img className='thumbs' src={thumbsUp} alt="thumbs" />
                                    </div>
                                    <div className="cons">
                                        <h1>{languageData.consTitle}</h1>
                                        <li>{languageData.lotsOfContent}</li>
                                        <li>{languageData.modelsCanBusy}</li>
                                        <img className='thumbs' src={thumbsDown} alt="thumbs" />
                                    </div>
                                </section>

                                <ReviewSpoiler title={languageData.webcamHostsTitle}>
                                    <p>{languageData.webcamHostsContent}</p>
                                </ReviewSpoiler>

                                <ReviewSpoiler title={languageData.costsAndBillingTitle}>
                                    <section id="cost">
                                        <p>{languageData.costsAndBillingContent}</p>
                                        <p><strong>{languageData.averageCostsTitle}</strong></p>
                                        <ul>
                                            <li><strong>{languageData.privateChatCostsTitle}</strong> &ndash; {languageData.privateChatCostsContent}</li>
                                            <li><strong>{languageData.prePaidSessionsTitle}</strong> &ndash; {languageData.prePaidSessionsContent}</li>
                                            <li><strong>{languageData.cumShareWithTitle}</strong> &ndash; {languageData.cumShareWithContent}</li>
                                            <li><strong>{languageData.groupCamsTitle}</strong> &ndash; {languageData.groupCamsContent}</li>
                                            <li><strong>{languageData.multiViewerTitle}</strong> &ndash; {languageData.multiViewerContent}</li>
                                            <li><strong>{languageData.pornstarShowsTitle}</strong> &ndash; {languageData.pornstarShowsContent}</li>
                                            <li><strong>{languageData.candyShowsTitle}</strong> &ndash; {languageData.candyShowsContent}</li>
                                        </ul>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={languageData.promotionsTitle}>
                                    <section id="promotions">
                                        <p>{languageData.promotionsContent}</p>
                                        <p>{languageData.promotionsBonus}</p>
                                        <p>{languageData.promotionsStarterPack}</p>
                                    </section>
                                </ReviewSpoiler>

                                <ReviewSpoiler title={languageData.paymentMethodsTitle}>
                                    <section id="payment_method">
                                        <p>{languageData.paymentMethodsContent}</p>
                                    </section>
                                </ReviewSpoiler>

                                <ReviewSpoiler title={languageData.payingForVideosTitle}>
                                    <section id="paying_for_videos">
                                        <p>{languageData.payingForVideosContent}</p>
                                        <p><img className="img_center" src={hours} /></p>
                                    </section>
                                </ReviewSpoiler>





                                <ReviewSpoiler title={languageData.membershipsAndBenefitsTitle}>
                                    <section id="member_info">
                                        <p>{languageData.membershipsAndBenefitsContent1}</p>
                                        <p>{languageData.membershipsAndBenefitsContent2}</p>
                                    </section>
                                </ReviewSpoiler>

                                <ReviewSpoiler title={languageData.specialFeaturesTitle}>
                                    <section id="features">
                                        <ul >
                                            <li>{languageData.hostsAndCategories}</li>
                                            <li>{languageData.highQualityStreaming}</li>
                                            <li>{languageData.submitYourFantasy}</li>
                                            <li>{languageData.freeBonus}</li>
                                            <li>{languageData.saveFavoriteHosts}</li>
                                            <li>{languageData.imliveParties}</li>
                                            <li>{languageData.exclusiveOffers}</li>
                                            <li>{languageData.checkRecordedVideos}</li>
                                            <li>{languageData.cumShareWithMe}</li>
                                            <li>{languageData.multiViewer}</li>
                                            <li>{languageData.cam2Cam}</li>
                                            <li>{languageData.pornstarShows}</li>
                                            <li>{languageData.topHostArena}</li>
                                            <li>{languageData.monthlyVideoContests}</li>
                                            <li>{languageData.virtualGifts}</li>
                                            <li>{languageData.videoChatOptimization}</li>
                                        </ul>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={languageData.starEventsTitle}>
                                    <section id="star_events">
                                        <p>{languageData.starEventsContent1}</p>
                                        <p>{languageData.starEventsContent2}</p>
                                        <p>{languageData.starEventsContent3}</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={languageData.hostsProfilesTitle}>
                                    <section id="profiles">
                                        <p>{languageData.hostsProfilesContent1}</p>
                                        <p><strong>{languageData.hostsProfilesContent2}</strong></p>
                                        <ul>
                                            <li>{languageData.hostsProfilesListItem1}</li>
                                            <li>{languageData.hostsProfilesListItem2}</li>
                                            <li>{languageData.hostsProfilesListItem3}</li>
                                            <li>{languageData.hostsProfilesListItem4}</li>
                                            <li>{languageData.hostsProfilesListItem5}</li>
                                            <li>{languageData.hostsProfilesListItem6}</li>
                                            <li>{languageData.hostsProfilesListItem7}</li>
                                            <li>{languageData.hostsProfilesListItem8}</li>
                                            <li>{languageData.hostsProfilesListItem9}</li>
                                            <li>{languageData.hostsProfilesListItem10}</li>
                                        </ul>
                                        <p><img className="img_center" src={list} /></p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={languageData.navigationTitle}>
                                    <section id="navigation">
                                        <p>{languageData.navigationContent}</p>
                                        <ul>
                                            <li>{languageData.memberPage}</li>
                                            <li>{languageData.liveCams}</li>
                                            <li>{languageData.hostVideos}</li>
                                            <li>{languageData.topHostArena}</li>
                                        </ul>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={languageData.customerServiceTitle}>
                                    <section id="customer_service">
                                        <p>{languageData.customerServiceContent}</p>
                                        <p><img className="img_center" src={support} /></p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={languageData.languagesTitle}>
                                    <section id="languages">
                                        <p>{languageData.languagesContent}</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={languageData.conclusionTitle}>
                                    <section id="conclusion">
                                        <p>{languageData.conclusionContent}</p>
                                    </section>
                                </ReviewSpoiler>


                                
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>

            <style>
                {styles}
            </style>
        </body>
    )
}

export default ImLive
