import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'
import { useLanguage } from '../Helpers/LanguageContext';

function SiteMap() {
    const { languageData } = useLanguage();
    return (
        <body class=" lang-en niche-women" data-is-mobile="False">


            <div class="main-wrapper">
                <Header />
                <div class="body-content">




                    <div class="container mt-10">

                        <div>
                            <h1>{languageData.siteMapTitle}</h1>
                            <h2>{languageData.pagesTitle}</h2>
                            <p>
                                <Link to="/">{languageData.homeLink}</Link>
                            </p>
                            <p>
                                <Link to="/about-us">{languageData.mikeSaysLink}</Link>
                            </p>
                            <p>
                                <Link to="/prices-payment-methods">{languageData.paymentsLink}</Link>
                            </p>
                            <p>
                                <Link to="/how-to-save">{languageData.howToSaveLink}</Link>
                            </p>
                            <p>
                                <Link to="/FAQ">{languageData.FAQLink}</Link>
                            </p>
                            <p>
                                <Link to="/safe-and-security">{languageData.safeAndSecurityLink}</Link>
                            </p>
                            <p>
                                <Link to="/general-adult-cam-features">{languageData.generalAdultCamFeaturesLink}</Link>
                            </p>

                            <h2>{languageData.amateurModelSitesReviewsTitle}</h2>
                            <p><a title="imLive.com"
                                href="review/imlive">imLive.com</a>
                            </p>
                            
                            <p><a title="MyFreeCams.com"
                                href="review/myfreecams">MyFreeCams.com</a>
                            </p>
                            
                            <p><a title="Flirt4Free.com"
                                href="review/flirt4free">Flirt4Free.com</a>
                            </p>
                            <p><a title="XLoveCam.com"
                                href="review/xlovecam">XLoveCam.com</a>
                            </p>
                            <p><a title="CamContacts.com"
                                href="review/camcontacts">CamContacts.com</a>
                            </p>
                            <p><a title="allcamsex.com"
                                href="review/allcamsex">allcamsex.com</a></p>
                            <p><a title="Cams.com"
                                href="review/cams">Cams.com</a>
                            </p>
                            <p><a title="ExposedWebcams.com"
                                href="review/exposedwebcams">ExposedWebcams.com</a>
                            </p>
                            <p><a title="Cam4.com"
                                href="review/cam4">Cam4.com</a>
                            </p>
                            <p><a title="MyDirtyHobby.com"
                                href="review/mydirtyhobby">MyDirtyHobby.com</a>
                            </p>
                            <p><a title="HDCamChats.com"
                                href="review/hdcamchats">HDCamChats.com</a>
                            </p>
                            <p><a title="LiveCamGuru.com"
                                href="review/livecamguru">LiveCamGuru.com</a>
                            </p>
                            <p><a title="BongaCams.com"
                                href="review/bongacams">BongaCams.com</a>
                            </p>
                        </div>

                    </div>


                </div>

                <Footer />

            </div>





        </body>
    )
}

export default SiteMap
