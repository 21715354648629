import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useLanguage } from '../../Helpers/LanguageContext';

function Privacy() {
    const { languageData } = useLanguage();

    return (
        <div className="lang-en niche-women" data-is-mobile="False">
            <Header />
            <div className="main-wrapper">
                <div className="body-content">
                    <div className="container mt-10">
                        <div>
                            <h1>{languageData.privacyPolicyTitle}</h1>
                            <p>{languageData.privacyPolicyIntro}</p>
                            <h4>{languageData.collectedInformationTitle}</h4>
                            <p>{languageData.collectedInformationContent}</p>
                            <h4>{languageData.useOfCollectedInformationTitle}</h4>
                            <p>{languageData.useOfCollectedInformationContent}</p>
                            <p>{languageData.useOfCollectedInformationContent2}</p>
                            <p>{languageData.useOfCollectedInformationContent3}</p>
                            <p>{languageData.useOfCollectedInformationContent4}</p>
                            <h4>{languageData.contactTitle}</h4>
                            <p>{languageData.contactContent}</p>
                            <p>{languageData.contactEmail}: <a href="mailto:info@mikecamreview.com">info@mikecamreview.com</a></p>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Privacy;
