import React from 'react'
import bestDeal from '../images/best-deal.png'
import logo1 from '../images/logos/975imlive_logo.png'
import bestChat from '../images/best-free-chat.png'
import logo2 from '../images/logos/746Chaturbate_logo.png'
import bestModels from '../images/best-variety-of-models.png'
import logo3 from '../images/logos/855myfreecams.png'
import { useLanguage } from '../Helpers/LanguageContext'
import { Link } from 'react-router-dom'

function RecommendedSites() {
    const { languageData } = useLanguage()
    return (
        <div class="right-block hideOnMobile">
            <div class="sites-recomended">
                <div class="bold">{languageData.recommendedSite}</div>
                <Link className="d-block" rel="noopener" target="_blank" title="imLive.com" to={'/review/imlive'}>
                    <img className="tag " title="Best Deal" src={bestDeal} />
                    <img src={logo1} title="imLive.com" alt="imLive.com" />
                </Link>

                <Link className="d-block" rel="noopener" target="_blank" title="imLive.com" to={'/review/chaturbate'}>
                    <img className="tag " title="Best Free Chat" src={bestChat} />
                    <img src={logo2} title="Chaturbate.com " alt="Chaturbate.com " />
                </Link>

                <Link className="d-block" rel="noopener" target="_blank" title="imLive.com" to={'/review/myfreecams'}>
                    <img className="tag " title="Best Variety of Models" src={bestModels} />
                    <img src={logo3} title="MyFreeCams.com" alt="MyFreeCams.com" />
                </Link>
            </div>
        </div>
    )
}

export default RecommendedSites
