import './App.css';
import AboutUs from './pages/AboutUs';
import Main from './pages/Main';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Payment from './pages/Payment';
import HowToSave from './pages/HowToSave';
import FAQ from './pages/FAQ';
import Safety from './pages/Safety';
import GeneralAdult from './pages/GeneralAdult';
import SiteMap from './pages/SiteMap';
import Terms from './pages/Social/Terms';
import Privacy from './pages/Social/Privacy';
import Contact from './pages/Social/Contact';
import Cookie from './pages/Social/Cookie';
import Reviews from './pages/Reviews';
import { LanguageProvider } from './Helpers/LanguageContext';
import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';
import Redirect from './pages/Redirect';

function App() {
  const params = new URLSearchParams(window.location.search);
  useEffect(() => {
    const utm_source = params.get('utm_source');
    const utm_medium = params.get('utm_medium');
    const utm_campaign = params.get('utm_campaign');
    const utm_term = params.get('utm_term');
    const utm_content = params.get('utm_content');

    if (utm_source) {
      window.localStorage.setItem('utm_source', utm_source);
    }

    if (utm_medium) {
      window.localStorage.setItem('utm_medium', utm_medium);
    }

    if (utm_campaign) {
      window.localStorage.setItem('utm_campaign', utm_campaign);
    }

    if (utm_term) {
      window.localStorage.setItem('utm_term', utm_term);
    }

    if (utm_content) {
      window.localStorage.setItem('utm_content', utm_content);
    }

    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
      utm_campaign: params.get('utm_campaign') || window.localStorage.getItem('utm_campaign'),
      utm_source: params.get('utm_source') || window.localStorage.getItem('utm_source'),
      utm_medium: params.get('utm_medium') || window.localStorage.getItem('utm_medium'),
      utm_term: params.get('utm_term') || window.localStorage.getItem('utm_term'),
      utm_content: params.get('utm_content') || window.localStorage.getItem('utm_content'),
    });

    if (window.localStorage.getItem('userID') == null) {
      let randUserID = Math.floor(100000000 + Math.random() * 900000000);
      window.localStorage.setItem('userID', randUserID);
    }

    let _fbc = params.get('_fbc');
    let _fbp = params.get('_fbp');

    if (_fbc) {
      document.cookie = `_fbc=${_fbc}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
    }

    if (_fbp) {
      document.cookie = `_fbp=${_fbp}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
    }

  }, []);

  return (
    <LanguageProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/prices-payment-methods" element={<Payment />} />
          <Route path="/how-to-save" element={<HowToSave />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/safe-and-security" element={<Safety />} />
          <Route path="/general-adult-cam-features" element={<GeneralAdult />} />
          <Route path="/sitemap" element={<SiteMap />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/cookie" element={<Cookie />} />
          <Route path="/review/*" element={<Reviews />} />
          <Route path="/redirect/" element={<Redirect />} />
        </Routes>
      </BrowserRouter>
    </LanguageProvider>
  );
}

export default App;
