import React, { useEffect } from 'react'
import styles from '../../styles/Review.js'
import Header from '../../components/Header.jsx'
import Footer from '../../components/Footer.jsx'
import logo1 from '../../images/logos/975imlive_logo.png'
import bestModels from '../../images/best-variety-of-models.png'
import logo3 from '../../images/logos/855myfreecams.png'
import thumbsUp from '../../images/thumbs-up.png'
import thumbsDown from '../../images/thumbs-down.png'
import RecommendedSites from '../../components/RecommendedSites.jsx'
import ReviewSpoiler from '../../components/ReviewSpoiler.jsx'
import redirectingImg from '../../images/redirecting.gif'
import RedirectingModal from '../../components/RedirectingModal.jsx'
import { useLanguage } from '../../Helpers/LanguageContext.jsx'

function MyFreeCams() {
  

     const [redirecting, setRedirecting] = React.useState(true)
    const link = `https://t.amyfc.link/3995/779/0?bo=2779,2778,2777,2776,2775&source=RonMike&po=6533&aff_sub5=SF_006OG000004lmDN`

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const { languageData } = useLanguage()

    return (
        <body className="lang-en niche-women" data-is-mobile="False">
            <div className="main-wrapper">
                <Header />
                <div class="body-content">

                    {
                        redirecting === true && <RedirectingModal link={link} setRedirecting={setRedirecting} logoRedirect={logo3} />
                    }

                    <div class="container mt-10">
                        <div class="review-main-sticky">

                            <div class="review-main">
                                <img class="tag" title="Best Variety of Models"
                                    src={bestModels} />
                                <div>
                                    <a class="" href={link} target="_blank"
                                        rel="noopener" title={languageData.rewardLinkText1}>
                                        <img class="siteimg" src={logo3}
                                            alt="MyFreeCams.com" title="MyFreeCams.com" />
                                    </a>
                                </div>
                                <div class="score-div not-scored" data-site-id="6">
                                    <span class="mikes-score star-icon-4">4.5</span>

                                </div>
                                <div>
                                    <a onClick={e => setRedirecting(true)} style={{cursor: "pointer"}} class=" d-inline-block text-center  no-underline visitSite orange-background-gradient"
                                         target="_blank"
                                        rel="noopener" title={languageData.rewardLinkText1}>
                                        <span>{languageData.rewardLinkText1}</span>
                                        <span>{languageData.rewardLinkText2}</span>
                                    </a>

                                </div>
                            </div>

                        </div>

                        <div class="review-body-and-banner">
                            <RecommendedSites />

                            <div class="review-body">
                                <section id="pros_cons" className='pros-and-cons'>
                                    <div className="pros">
                                        <h1>{languageData.prosSectionTitle}</h1>
                                        <li>{languageData.prosItem1}</li>
                                        <li>{languageData.prosItem2}</li>
                                        <img className='thumbs' src={thumbsUp} alt="thumbs" />
                                    </div>
                                    <div className="cons">
                                        <h1>{languageData.consTitle}</h1>
                                        <li>{languageData.consItem1}</li>
                                        <li>{languageData.consItem2}</li>
                                        <img className='thumbs' src={thumbsDown} alt="thumbs" />
                                    </div>
                                </section>
                                <ReviewSpoiler title={languageData.spoilerTitle}>
                                    <section id={languageData.sectionId}>

                                        <p>{languageData.paragraphText}</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={languageData.sectionTitle}>
                                    <section id={languageData.sectionId}>

                                        <p>{languageData.introParagraph}</p>
                                        <p>{languageData.guestInfo}</p>
                                        <p>{languageData.tokenPricingInfo}</p>
                                        <p>{languageData.tippingEncouragement}</p>
                                        <p>&nbsp;</p>
                                        <p><strong>{languageData.tokenPurchaseHeader}</strong></p>
                                        <ul>
                                            <li>{languageData.tokenOption1}</li>
                                            <li>{languageData.tokenOption2}</li>
                                            <li>{languageData.tokenOption3}</li>
                                        </ul>
                                        <h2>{languageData.paymentOptionsHeader}</h2>
                                        <p>{languageData.paymentMethods}</p>
                                        <p>{languageData.securityNote}</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={languageData.promotionsSectionTitle}>
                                    <section id={languageData.promotionsSectionId}>
                                        <p>{languageData.promotionsParagraph}</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={languageData.membershipSectionTitle}>
                                    <section id={languageData.membershipSectionId}>
                                        <p>{languageData.basicMembershipInfo}</p>
                                        <p>{languageData.premiumMembershipInfo}</p>
                                        <p>{languageData.benefitsInfo}</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={languageData.sectionTitleUserFeatures}>
                                    <section id={languageData.sectionIdUserFeatures}>
                                        <ul >
                                            <li><strong>{languageData.loungeFeatureHeader}</strong></li>
                                        </ul>
                                        <p>{languageData.loungeFeature}</p>
                                        <ul >
                                            <li><strong>{languageData.soundAlertFeatureHeader}</strong></li>
                                        </ul>
                                        <p>{languageData.soundAlertFeature}</p>
                                        <ul >
                                            <li><strong>{languageData.modelsYouMayLikeFeatureHeader}</strong></li>
                                        </ul>
                                        <p>{languageData.modelsYouMayLikeFeature}</p>
                                        <ul >
                                            <li>{languageData.trendingRoomsFeature}</li>
                                            <li>{languageData.homepageSidebarFeature}</li>
                                        </ul>
                                        <h2>{languageData.videoChatFeaturesHeader}</h2>
                                        <p>{languageData.privateShowsArchiveFeature}</p>
                                        <p>{languageData.videoChatFeaturesDetail}</p>
                                        <p>&nbsp;</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={languageData.profilesSectionTitle}>
                                    <section id={languageData.profilesSectionId}>
                                        <p>{languageData.profilesParagraph}</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={languageData.navigationSectionTitle}>
                                    <section id={languageData.navigationSectionId}>
                                        <h2>{languageData.siteNavigationHeader}</h2>
                                        <p>{languageData.siteNavigationParagraph1}</p>
                                        <p>{languageData.siteNavigationParagraph2}</p>
                                        <h2>{languageData.searchOptionsHeader}</h2>
                                        <p>{languageData.searchOptionsParagraph}</p>
                                        <h2>{languageData.viewingOptionsHeader}</h2>
                                        <p>{languageData.viewingOptionsParagraph}</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={languageData.customerSupportSectionTitle}>
                                    <section id={languageData.customerSupportSectionId}>
                                        <p>{languageData.customerSupportParagraph}</p>
                                        <h2>{languageData.faqHeader}</h2>
                                        <p>{languageData.faqParagraph}</p>
                                        <ul>
                                            <li>{languageData.faqItem1}</li>
                                            <li>{languageData.faqItem2}</li>
                                            <li>{languageData.faqItem3}</li>
                                        </ul>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={languageData.languageSectionTitle}>
                                    <section id={languageData.languageSectionId}>
                                        <p>{languageData.languageParagraph}</p>
                                    </section>
                                </ReviewSpoiler>

                                <ReviewSpoiler title={languageData.bottomLineSectionTitle}>
                                    <section id={languageData.bottomLineSectionId}>
                                        <p>{languageData.bottomLineParagraph}</p>
                                    </section>
                                </ReviewSpoiler>

                                <section id="exposure-Metrics">
                                    <h2>{languageData.exposureMetricsHeader}</h2>
                                </section>
                                <div id="Reviews">
                                    <div id="disqus_thread"></div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <Footer />
                </div>
            </div>
            <style>
                {styles}
            </style>


        </body>
    )
}

export default MyFreeCams
