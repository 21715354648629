import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useLanguage } from '../../Helpers/LanguageContext';

function Terms() {
    const { languageData } = useLanguage();

    return (
        <div className="lang-en niche-women" data-is-mobile="False">
            <Header />
            <div className="main-wrapper">
                <div className="body-content">
                    <div className="container mt-10">
                        <div>
                            <h1>{languageData.termsTitle}</h1>
                            <p><strong>{languageData.generalAgeRequirementTitle}</strong></p>
                            <p>{languageData.generalAgeRequirementContent}</p>
                            <p>&nbsp;</p>
                            <p><strong>{languageData.thirdPartyContentTitle}</strong></p>
                            <p>{languageData.thirdPartyContentContent}</p>
                            <p>&nbsp;</p>
                            <p><strong>{languageData.rankingTitle}</strong></p>
                            <p>{languageData.rankingContent}</p>
                            <p>&nbsp;</p>
                            <p><strong>{languageData.contactTitle}</strong></p>
                            <p>{languageData.contactContent} <a href="mailto:affiliates@mikecamreviews.com">affiliates@mikecamreviews.com</a></p>
                            <p>&nbsp;</p>
                            <p><strong>{languageData.privacyTitle}</strong></p>
                            <p>{languageData.privacyContent} <a href="/privacy">{languageData.privacyContentLink}</a> {languageData.privacyContentEnd}</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Terms;
