import React from 'react'
import '../styles/Main2.css'
import { Routes, Route } from 'react-router-dom'
import ImLive from './Reviews/imLive.jsx'
import Chaturbate from './Reviews/Chaturbate.jsx'
import MyFreeCams from './Reviews/MyFreeCams.jsx'
import CamSoda from './Reviews/CamSoda.jsx'
import XLoveCam from './Reviews/xLoveCam.jsx'
import FlirtFree from './Reviews/FlirtFree.jsx'
import Cams from './Reviews/Cams.jsx'
import MyDirtyHobby from './Reviews/MyDirtyHobby.jsx'
import CamContacts from './Reviews/CamContacts.jsx'
import BongaCams from './Reviews/BongaCams.jsx'
import AllCamSex from './Reviews/AllCamSex.jsx'
import ExposedWebcams from './Reviews/ExposedWebcams.jsx'
import Cam4 from './Reviews/Сam4.jsx'
import HdCamChats from './Reviews/HdCamChats.jsx'
import LiveCamGuru from './Reviews/LiveCamGuru.jsx'
import StripChat from './Reviews/StripChat.jsx'

function Reviews() {
    return (
        <Routes>
            <Route path="/imlive" element={<ImLive />} />
            <Route path="/chaturbate" element={<Chaturbate />} />
            <Route path="/myfreecams" element={<MyFreeCams />} />
            <Route path="/camsoda" element={<CamSoda />} />
            <Route path="/xlovecam" element={<XLoveCam />} />
            <Route path="/flirt4free" element={<FlirtFree />} />
            <Route path="/cams" element={<Cams />} />
            <Route path="/mydirtyhobby" element={<MyDirtyHobby />} />
            <Route path="/camcontacts" element={<CamContacts />} />
            <Route path="/bongacams" element={<BongaCams />} />
            <Route path="/allcamsex" element={<AllCamSex />} />
            <Route path="/exposedwebcams" element={<ExposedWebcams />} />
            <Route path="/cam4" element={<Cam4 />} />
            <Route path="/hdcamchats" element={<HdCamChats />} />
            <Route path="/livecamguru" element={<LiveCamGuru />} />
            <Route path="/stripchat" element={<StripChat />} />
        </Routes>
    )
}

export default Reviews
