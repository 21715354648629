import React, { useEffect } from 'react'
import styles from '../../styles/Review.js'
import Header from '../../components/Header.jsx'
import Footer from '../../components/Footer.jsx'
import logo1 from '../../images/logos/975imlive_logo.png'
import bestChat from '../../images/best-free-chat.png'
import logo2 from '../../images/logos/746Chaturbate_logo.png'
import thumbsUp from '../../images/thumbs-up.png'
import thumbsDown from '../../images/thumbs-down.png'
import RecommendedSites from '../../components/RecommendedSites.jsx'
import ReviewSpoiler from '../../components/ReviewSpoiler.jsx'
import { useLanguage } from '../../Helpers/LanguageContext'
import redirectingImg from '../../images/redirecting.gif'
import RedirectingModal from '../../components/RedirectingModal.jsx'

function Chaturbate() {
    const { languageData } = useLanguage()

    const link = ` https://chaturbate.com/in/?tour=grq0&campaign=jLCS7&track=default&disable_sound=True`

     const [redirecting, setRedirecting] = React.useState(true)

     

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <body className="lang-en niche-women" data-is-mobile="False">
            <div className="main-wrapper">
                <Header />
                <div class="body-content">

                    {
                        redirecting === true && <RedirectingModal link={link} setRedirecting={setRedirecting} logoRedirect={logo2} />
                    }

                    <div class="container mt-10">
                        <div class="review-main-sticky">

                            <div class="review-main">
                                <img className="tag" title="Best Free Chat" src={bestChat} />
                                <div>
                                    <a class=""
                                        href={link}
                                        target="_blank" rel="noopener" title="Refer friends and Earn tokens">
                                        <img class="siteimg" src={logo2}
                                            alt="Chaturbate.com " title="Chaturbate.com " />
                                    </a>
                                </div>
                                <div class="score-div not-scored" data-site-id="1">
                                    <span class="mikes-score star-icon-5">4.6</span>

                                </div>
                                <div>
                                    <a onClick={e => setRedirecting(true)} style={{cursor: "pointer"}} class=" d-inline-block text-center  no-underline visitSite orange-background-gradient"
                                        
                                        target="_blank" rel="noopener" title="Refer friends and Earn tokens">
                                        <span>{languageData.referFriendsText}</span>
                                        <span>{languageData.visitSiteText}</span>
                                    </a>

                                </div>
                            </div>

                        </div>

                        <div class="review-body-and-banner">
                            <RecommendedSites />

                            <div class="review-body">

                                <section id="pros_cons" className='pros-and-cons'>
                                    <div className="pros">
                                        <h1>{languageData.prosTitle}</h1>
                                        <li>{languageData.prosContent1}</li>
                                        <li>{languageData.prosContent2}</li>
                                        <img className='thumbs' src={thumbsUp} alt="thumbs" />
                                    </div>
                                    <div className="cons">
                                        <h1>{languageData.consTitle}</h1>
                                        <li>{languageData.consContent1}</li>
                                        <li>{languageData.consContent2}</li>
                                        <li>{languageData.consContent3}</li>
                                        <img className='thumbs' src={thumbsDown} alt="thumbs" />
                                    </div>
                                </section>
                                <ReviewSpoiler title={languageData.chaturbateBlock1title}>
                                    <section id="hosts">
                                        <p>{languageData.chaturbateBlock1subtitle}</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={languageData.costsAndBillingTitle}>
                                    <section id="cost">
                                        <p>{languageData.costsAndBillingContent}</p>
                                        <ul>
                                            <li>{languageData.costEarningMethod1}</li>
                                            <li>{languageData.costEarningMethod2}</li>
                                            <li>{languageData.costEarningMethod3}</li>
                                            <li>{languageData.costEarningMethod4}</li>
                                        </ul>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={languageData.creditOnChaturbateTitle}>
                                    <section id="credit">
                                        <p>{languageData.creditOnChaturbateContent}</p>
                                        <ul>
                                            <li>{languageData.tokenPackage1}</li>
                                            <li>{languageData.tokenPackage2}</li>
                                            <li>{languageData.tokenPackage3}</li>
                                            <li>{languageData.tokenPackage4}</li>
                                            <li>{languageData.tokenPackage5}</li>
                                            <li>{languageData.tokenPackage6}</li>
                                            <li>{languageData.tokenPackage7}</li>
                                            <li>{languageData.tokenPackage8}</li>
                                            <li>{languageData.tokenPackage9}</li>
                                        </ul>
                                        <p>{languageData.additionalPackage1}</p>
                                        <p>{languageData.additionalPackage2}</p>
                                        <p>{languageData.additionalPackage3}</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={languageData.paymentMethodsTitle}>
                                    <section id="payment">
                                        <p>{languageData.paymentMethodsContent}</p>
                                        <ul>
                                            <li>{languageData.paymentOption1}</li>
                                            <li>{languageData.paymentOption2}</li>
                                            <li>{languageData.paymentOption3}</li>
                                            <li>{languageData.paymentOption4}</li>
                                            <li>{languageData.paymentOption5}</li>
                                            <li>{languageData.paymentOption6}</li>
                                        </ul>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={languageData.ccBillingOptionsTitle}>
                                    <section id="cc-billing">
                                        <p>{languageData.ccBillingOptionsContent}</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={languageData.topPromotionsTitle}>
                                    <section id="promotions">
                                        <ul >
                                            <li>{languageData.topPromotionsItem1}</li>
                                            <li>{languageData.topPromotionsItem2}</li>
                                            <li>{languageData.topPromotionsItem3}</li>
                                        </ul>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={languageData.membershipTitle}>
                                    <section id="member_info">
                                        <p>{languageData.guestTitle}</p>
                                        <p>{languageData.guestDescription}</p>
                                        <p><strong>{languageData.guestBenefitsTitle}</strong></p>
                                        <p>{languageData.guestCostToJoin}</p>
                                        <p>{languageData.guestInformationRequired}</p>
                                        <ul>
                                            <li>{languageData.guestBenefit1}</li>
                                            <li>{languageData.guestBenefit2}</li>
                                            <li>{languageData.guestBenefit3}</li>
                                            <li>{languageData.guestBenefit4}</li>
                                            <li>{languageData.guestBenefit5}</li>
                                            <li>{languageData.guestBenefit6}</li>
                                            <li>{languageData.guestBenefit7}</li>
                                            <li>{languageData.guestBenefit8}</li>
                                        </ul>
                                        <p>{languageData.supporterTitle}</p>
                                        <p>{languageData.supporterDescription}</p>
                                        <p>{languageData.supporterRate}</p>
                                        <p>{languageData.supporterInformationRequired}</p>
                                        <ul>
                                            <li>{languageData.supporterBenefit1}</li>
                                            <li>{languageData.supporterBenefit2}</li>
                                            <li>{languageData.supporterBenefit3}</li>
                                            <li>{languageData.supporterBenefit4}</li>
                                            <li>{languageData.supporterBenefit5}</li>
                                        </ul>
                                        <p>{languageData.fanClubTitle}</p>
                                        <p>{languageData.fanClubDescription}</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={languageData.featuresTitle}>
                                    <section id="features">
                                        <p>{languageData.featuresDescription}</p>
                                        <p><strong>{languageData.userFeaturesTitle}</strong></p>
                                        <ul>
                                            <li>{languageData.userFeature1}</li>
                                            <li>{languageData.userFeature2}</li>
                                            <li>{languageData.userFeature3}</li>
                                            <li>{languageData.userFeature4}</li>
                                            <li>{languageData.userFeature5}</li>
                                            <li>{languageData.userFeature6}</li>
                                        </ul>
                                        <ul>
                                            <li>{languageData.additionalFeature1}</li>
                                            <li>{languageData.additionalFeature2}</li>
                                            <li>{languageData.additionalFeature3}</li>
                                            <li>{languageData.additionalFeature4}</li>
                                            <li>{languageData.additionalFeature5}</li>
                                            <li>{languageData.additionalFeature6}</li>
                                            <li>{languageData.additionalFeature7}</li>
                                            <li>{languageData.additionalFeature8}</li>
                                            <li>{languageData.additionalFeature9}</li>
                                            <li>{languageData.additionalFeature10}</li>
                                            <li>{languageData.additionalFeature11}</li>
                                            <li>{languageData.additionalFeature12}</li>
                                        </ul>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={languageData.hostProfilesTitle}>
                                    <section id="profiles">
                                        <p>{languageData.hostProfilesDescription}</p>
                                        <p>{languageData.generalInfoTitle}</p>
                                        <ul>
                                            <li>{languageData.generalInfo1}</li>
                                            <li>{languageData.generalInfo2}</li>
                                            <li>{languageData.generalInfo3}</li>
                                            <li>{languageData.generalInfo4}</li>
                                            <li>{languageData.generalInfo5}</li>
                                            <li>{languageData.generalInfo6}</li>
                                            <li>{languageData.generalInfo7}</li>
                                            <li>{languageData.generalInfo8}</li>
                                            <li>{languageData.generalInfo9}</li>
                                            <li>{languageData.generalInfo10}</li>
                                        </ul>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={languageData.navigationTitle}>
                                    <section id="navigation">
                                        <p>{languageData.navigationDescription}</p>
                                        <ul>
                                            <li>{languageData.hostList}</li>
                                            <li>{languageData.galleryView}</li>
                                            <li>{languageData.tileView}</li>
                                        </ul>
                                        <h2>{languageData.searchOptionsTitle}</h2>
                                        <p>{languageData.searchOptionsDescription}</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={languageData.customerSupportTitle}>
                                    <section id="customer_support">
                                        <p>{languageData.customerSupportDescription}</p>
                                        <p><strong>{languageData.faqsTitle}</strong></p>
                                        <p>{languageData.faqsDescription}</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={languageData.languagesTitle}>
                                    <section id="languages">
                                        <p>{languageData.languagesDescription}</p>
                                        <ul>
                                            <li>{languageData.language1}</li>
                                            <li>{languageData.language2}</li>
                                            <li>{languageData.language3}</li>
                                            <li>{languageData.language4}</li>
                                            <li>{languageData.language5}</li>
                                            <li>{languageData.language6}</li>
                                            <li>{languageData.language7}</li>
                                            <li>{languageData.language8}</li>
                                            <li>{languageData.language9}</li>
                                        </ul>
                                    </section>
                                </ReviewSpoiler>

                                <section id="exposure-Metrics">
                                    <h2>{languageData.exposureMetricsHeader}</h2>

                                </section>
                                <div id="Reviews">
                                    <div id="disqus_thread"></div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <Footer />
                </div>
            </div>
            <style>
                {styles}
            </style>


        </body>
    )
}

export default Chaturbate
