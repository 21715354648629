import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useLanguage } from '../../Helpers/LanguageContext'

function Contact() {
    const { languageData } = useLanguage()
    return (
        <div className="lang-en niche-women" data-is-mobile="False">
            <Header />
            <div className="main-wrapper">
                <div className="body-content">
                    <div className="container mt-10">
                        <div>
                            <h1>{languageData?.ContactUsTitle}</h1>
                            <p>{languageData?.ContactUsDescription}</p>
                            <p>{languageData?.AboutUsLink}</p>
                            <h4>{languageData?.GeneralEnquiriesTitle}</h4>
                            <p>{languageData?.GeneralEnquiriesEmail}</p>
                            <h4>{languageData?.AffiliateProgramTitle}</h4>
                            <p>{languageData?.AffiliateProgramEmail}</p>
                            <p>{languageData?.CollectedInformation}</p>
                            <p>{languageData?.AgeRestriction}</p>
                            <h4>{languageData?.PrivacyPolicyContactTitle}</h4>
                            <p>{languageData?.PrivacyPolicyContactEmail} <a href="mailto:info@mikecamreview.com">info@mikecamreview.com</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Contact;
