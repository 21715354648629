import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import { useLanguage } from "../Helpers/LanguageContext";

function Footer() {

  const { languageData } = useLanguage();
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsScrolled(scrollTop > 100);
    };

    window.addEventListener("scroll", handleScroll);

    // Прибираємо слухач подій при розмонтуванні компонента
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="footer-content">
      <div className="footerLinks ">
        <p>{languageData.copyright}</p>
        <Link to="/terms" title="terms">
        {languageData.termsAndConditions}
        </Link>
        <Link to="/privacy" title="Privacy">
        {languageData.privacyPolicy}
        </Link>
        <Link to="/contact" title="Contact">
        {languageData.contact}
        </Link>
        <Link to="/cookie" title="Cookie Policy">
        {languageData.cookiePolicy}
        </Link>
        <Link to="/sitemap" title="site map">
        {languageData.siteMap}
        </Link>
      </div>
      <div
        className={cn("scroll-top opacity-0 duration-300", {
          "opacity-100": isScrolled,
        })}
        onClick={scrollToTop}
      ></div>
    </div>
  );
}

export default Footer;
