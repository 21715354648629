import React, { useEffect } from 'react'
import styles from '../../styles/Review.js'
import Header from '../../components/Header.jsx'
import Footer from '../../components/Footer.jsx'
import thumbsUp from '../../images/thumbs-up.png'
import thumbsDown from '../../images/thumbs-down.png'
import logo8 from "../../images/logos/096mydirtyhobby.png"
import RecommendedSites from '../../components/RecommendedSites.jsx'
import RedirectingModal from '../../components/RedirectingModal.jsx'
import ReviewSpoiler from '../../components/ReviewSpoiler.jsx'
import { useLanguage } from '../../Helpers/LanguageContext.jsx'

function MyDirtyHobby() {
     const [redirecting, setRedirecting] = React.useState(true)
    const link = `https://lp.mydirtyhobby.com/2/?video=4&lang=de&ats=eyJhIjoyNzMyMDIsImMiOjUxNzU5MjYwLCJuIjoyMSwicyI6MjQxLCJlIjo5NTQyLCJwIjoyfQ==&atc=RonMike`
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const { languageData } = useLanguage()
    return (
        <body className="lang-en niche-women" data-is-mobile="False">
            <div className="main-wrapper">
                <Header />
                <div class="body-content">
                    {
                        redirecting === true && <RedirectingModal logoRedirect={logo8} link={link} setRedirecting={setRedirecting} />
                    }
                    <div class="container mt-10">
                        <div class="review-main-sticky">
                            <div class="review-main">
                                <div>
                                    <a class=""
                                        href={link}
                                        target="_blank" rel="noopener" title="Free registration">
                                        <img class="siteimg" src={logo8}
                                            alt="MyDirtyHobby.com" title="MyDirtyHobby.com" />
                                    </a>
                                </div>
                                <div class="score-div not-scored" data-site-id="34">
                                    <span class="mikes-score star-icon-4">3.9</span>

                                </div>
                                <div>
                                    <a onClick={e => setRedirecting(true)} style={{cursor: "pointer"}} class=" d-inline-block text-center  no-underline visitSite orange-background-gradient"
                                        
                                        target="_blank" rel="noopener" title="Free registration">
                                        <span>Free registration</span>
                                        <span>visit site</span>
                                    </a>

                                </div>
                            </div>

                        </div>

                        <div class="review-body-and-banner">
                            <RecommendedSites />


                            <div class="review-body">
                                <section id="pros_cons" className='pros-and-cons'>
                                    <div className="pros">
                                        <h1>Pros</h1>
                                        <li>A plethora of excellent and exclusive content</li>
                                        <li>Top-quality, highly professional performers</li>
                                        <li>Site is easy to navigate and advanced search categories</li>
                                        <li>Content available in HD quality</li>
                                        <li>More than 400 performers are online at any given time</li>

                                        <img className='thumbs' src={thumbsUp} alt="thumbs" />
                                    </div>
                                    <div className="cons">
                                        <h1>{languageData.consTitle}</h1>
                                        <li>Registration required to even just view the site</li>
                                        <li>No access to free content</li>
                                        <img className='thumbs' src={thumbsDown} alt="thumbs" />
                                    </div>
                                </section>
                                <ReviewSpoiler title={'Summary'}>
                                    <section id="summary">

                                        <p>With the excessive amount of adult webcam sites available on the internet, we often feel
                                            confused about the authenticity of such websites. This is the reason why I have reviewed
                                            one of the most popular adult webcam sites- My Dirty Hobby. Just like many other webcam
                                            sites, My Dirty Hobby is an adult webcam site as well. However, My Dirty Hobby is a lot
                                            better than the ocean full webcam sites. It is loaded with top features and a vast
                                            number of hosts. This site welcomes a plethora of amateur performers as well as users on
                                            a daily basis. There are so many beautiful girls available on this site to chat with you
                                            and make you entertained. So, without wasting any more time, let me give you all the
                                            features about My Dirty Hobby hosts, profiles, cost, customer care service, different
                                            memberships, pros and cons, and more.</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Number of Hosts'}>
                                    <section id="summery">

                                        <p>My Dirty Hobby is the favourite sites for amateur and experienced performers. Once you
                                            land on their website, you will be bombarded with more than 200 online performers ready
                                            to please you. Overall, this site has more than 5000 registered hosts. The numbers are,
                                            and this will give you an option to choose the right girl to chat with and be
                                            entertained by. The home page will show you the pictured of models who are online. It
                                            will be under Livecam amateurs section. However, as a guest, you will only get to see
                                            the pictures and thumbnails of the models and won&rsquo;t be able to see a sample of the
                                            live video. Once you registered, you can right away browse through their plethora of
                                            hosts&rsquo; online live video.</p>
                                        <p>If you move the cursor a little left, you will see the current online option, where you
                                            will see all the available hosts.</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Cost'}>
                                    <section id="summery">

                                        <p>Registering on My Dirty Hobby is extremely easy. You will be asked to set a username for
                                            your account, set a password, and mention your email address, and your gender as well.
                                            Once you submit all the details, you will need to click on register. And yes,
                                            registering on My Dirty Hobby is absolutely free!</p>
                                        <p>To view exclusive pictures, you will have to pay as little as 99 tokens for each picture,
                                            although most of the member will charge you only 50 tokens. I am hopeful that the price
                                            will decrease with time as well. However, if you want to chat with someone on a private
                                            mode, then you will have to spend 200 tokens per minute. To cut a long story short, in
                                            order to check out exclusive videos and pictures, or chat with beautiful girls in
                                            private mode, you will have to spend tokens.</p>
                                        <p>Here&rsquo;s the cost of tokens:</p>
                                        <p>$15.00- 1200 tokens</p>
                                        <p>$25.00- 2000 tokens</p>
                                        <p>$50.00- 5200 tokens</p>
                                        <p>$75.00- 7800 tokens</p>
                                        <p>You can buy these token easily using your credit card and start talking to gorgeous
                                            girls. So, the bottom line is the more you spend, the more tokens you will receive. The
                                            best part about My Dirty Hobby is that once you buy a video, you get to watch it over
                                            and over again. It doesn&rsquo;t come with any limitations! Now, that&rsquo;s really
                                            great!</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Top Promotions'}>
                                    <section id="promotions">

                                        <p>This is the section that I was really interested in. All of us love to be a part of
                                            promotions and discounts. The moment you register on My Dirty Hobby, you will get 100
                                            free dirty cents! I mean, first this site doesn&rsquo;t charge you for registering, and
                                            on top of that, it gives you free 100 tokens! Wow! Plus, once you register, you can meet
                                            My Dirt Hobby&rsquo;s exclusive models and get premium access to exclusive content that
                                            too for free. Take a look at some of the VIP member tops promotions:</p>
                                        <p>The bonus days: VIP members can get an extra 10% token when purchased on Sundays and
                                            Fridays.</p>
                                        <p>You can get free access to many live shows of the performers.</p>
                                        <p>You will also earn a special badge to let everybody know that you are a VIP member.</p>
                                        <p>Types of membership:</p>
                                        <p>My Dirty Hobby has kept their membership account extremely simple. In order to view the
                                            photo gallery and live videos, every visitor must register to My Dirty Hobby. It is only
                                            when you register you get to get the real action going. So, while registering you create
                                            a basic account by entering your user name, password, and the email ID. However, you can
                                            upgrade your account to VIP membership and receive a plethora of benefits. As mentioned
                                            above, you get an extra 10% token when purchased on Sundays and Friday. Get free entry
                                            to exclusive content and live shows and also earn a special badge.</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Special Features'}>
                                    <section id="Specialfeatures">

                                        <p>If you love to do adult chat, then My Dirty Hobby will surely keep you entertained.
                                            Overall, this site gave me a pleasant experience. It was not difficult to navigate, and
                                            in just a few minutes I couldn&rsquo;t understand the nitty-gritty of this site with
                                            ease. This site is honestly GREAT! Here are some features that I really liked. Read them
                                            below:</p>
                                        <ul>
                                            <li>My Dirty Hobby has more than 4000 registered models, and daily, they have over 400
                                                live cam models available all the time.</li>
                                            <li>You can go to the advanced search filter to look for the right model by shrinking
                                                your results and get what you want.</li>
                                            <li>My Dirty Hobby is excellent in terms of navigation too.</li>
                                            <li>This site was launched way back in 2004 and thus is entirely trustworthy.</li>
                                            <li>You get 100 dirty cents for free once you register on their website.</li>
                                            <li>After registering, you will get the option to open chat and enable cam2cam as well
                                                as two-way audio that too for free!</li>
                                            <li>My Dirty Hobby has divided their video chat into three groups: SD, HD, and HQ. The
                                                video quality of all the three categories was good, but HD was simply the best.</li>
                                            <li>My Dirty Hobby was won the 2010 Venus Award for the best national amateur website.
                                            </li>
                                            <li>In 2011 they won the signed award for best amateur portal</li>
                                            <li>Their site is mobile optimised, which means you will get to experience the same rich
                                                site and its content on your smartphones too!</li>
                                            <li>My Dirty Hobby has tons of erotic videos and pictures for the users. These photos
                                                and videos will keep you thoroughly entertained.</li>
                                            <li>The pricing on My Dirty Hobby is fair as well. They ask you to buy tokens, and you
                                                can use it to either explore exclusive photos and videos or to chat with perfect
                                                looking girls.</li>
                                            <li>This is a site which has over 4000 registered performers and more than 400 online
                                                girls ready to talk all the time!</li>
                                            <li>There are a lot of performers to choose from; you can search for the categories of
                                                performers on the search bar easily.</li>
                                            <li>They upload latest videos frequently on their site.</li>
                                            <li>My Dirty Hobby is loaded with super good contents, and this is something which kept
                                                me really engaged. I could see good quality videos and buy high-resolution photos as
                                                well.</li>
                                            <li>Lastly, if you have your own content and wish to add to My Dirty Hobby, you can
                                                actually upload it on the community&rsquo;s content! Now, this is something which
                                                you will not get easily on any other adult webcam site.</li>
                                            <li>The design of the website is also good and not difficult to understand. You will
                                                surely enjoy using My Dirty Hobby.</li>
                                        </ul>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Hosts Profiles'}>
                                    <section id="AboutHostProfiles">

                                        <p>Regarding the host profile, you will be overloaded with them. My Dirty Hobby has a galore
                                            of performers, and once you click on a performer, you will straightaway go their page.
                                            The page will contain video details, for instance, when the video was added, who added
                                            it, what is the length of the video, what is the ratting, and the category description.
                                            You will also get the option to add it to your wish list as well.</p>
                                        <p>Below the video details, you can read the video description too.</p>
                                        <p>Just below the leading performer&rsquo;s video, you will see similar videos as well to
                                            give you easy access to the same performers. This made my search for models really easy.
                                        </p>
                                        <p>Down below, I could see some comments left by visitors and members.</p>
                                        <p>You will also get to see the host profile&rsquo;s information as well. The information
                                            included her first name or the nickname, gender, age, marital status, sexual
                                            orientation, Zip Code or country, and spoken language.</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Navigation and Search Options'}>
                                    <section id="Navigation">

                                        <p>So, My Dirty Hobby has an advanced search setting which makes it easy for a user like me
                                            to search for the accurate content. The moment I opened mydirtyhobby.com I could see the
                                            top performers on the home page. It gave me easy access to see them and know more about
                                            them. Once I clicked on a top performer, it took me to their timeline page. I could see
                                            her latest videos, browse through the videos, pictures, about me section, read her blogs
                                            and know about the performer bit more in details.</p>
                                        <p>Just below the hot performers&rsquo; gallery, you will see the exclusive promotion slide
                                            show. Then comes &ldquo;this week&rsquo;s top videos&rdquo;. You can select the video
                                            you want to watch, but remember you will have to log in to view. Lastly, the page will
                                            have the live performers&rsquo; list as well.</p>
                                        <p>Overall, navigating My Dirty Hobby is easy and fun.</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Customer Service'}>
                                    <section id="Customer Service">

                                        <p>Although My Dirty Hobby doesn&rsquo;t have any direct contact number, if you do find an
                                            issue with your account or face trouble navigating their site, you can go to their FAQ
                                            section and click on the blue link to submit your query. They are responsive and will
                                            reply to your question soon.</p>
                                        <p>The FAQ section</p>
                                        <p>I did have a few questions about this site and wanted to check if they have a dedicated
                                            FAQ page. Well, yes they do! They have answered all the common questions beforehand and
                                            also give you an option to submit your own question. This is how their FAQ section looks
                                            like:</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Languages'}>
                                    <section id="Languages">

                                        <p>My Dirty Hobby is actually a German site; however, their page is in English. Still, if
                                            you wish to change the site&rsquo;s language, then you can pick German, English, French,
                                            Spanish, and Italian.</p>
                                    </section>
                                </ReviewSpoiler>
                                <ReviewSpoiler title={'Bottom Line'}>

                                    <section id="BottomLine">

                                        <p>If you like to do adult web chatting, then you must try My Dirty Hobby once for sure. I
                                            have said enough about this site, and now it is time for you to start exploring all
                                            these benefits on your own. Trust me; My Dirty Hobby won&rsquo;t let you down!</p>
                                    </section>
                                </ReviewSpoiler>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
            <style>
                {styles}
            </style>


        </body >
    )
}

export default MyDirtyHobby
