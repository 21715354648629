import React from 'react'
import cn from 'classnames'
import '../../src/styles/ToggleStyles.css'

function ReviewSpoiler({ children, title }) {

    const [opened, setOpened] = React.useState(false)

    return (
        <section id="hosts" onClick={e => setOpened(!opened)}>
            <h2
                className={cn(`toggler`, {
                    'opened': opened
                })}
            >{title}</h2>
            <div className={cn({
                'block': opened,
                'hidden': !opened
            })}>
                {children}
            </div>
        </section>
    )
}

export default ReviewSpoiler